import { useEffect } from "react";
import {
  getClasseurs,
  getFolderClasseursTemplateCode,
  getVarFiche,
} from "../../../../utils/folders";
import { useClientFileStore } from "../../../../store/Crm/ClientFile";
import { errorFetch } from "../../../../utils/toaster";
import axios from "axios";
import {
  getImplicitGroups,
  getVarFileDossier,
  postPersonnesFile,
} from "../../../../utils/customerFile";
import { useLoadingStore } from "../../../../store/utils/loading";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";
import RenderClientFile from "./RenderClientFile";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { getParticipantsFields } from "../../../../utils/participants";

const CreateClientFileExpertComptable = () => {
  const {
    filesModel,
    setFilesModel,
    selectedFile,
    setSelectedFile,
    fileNumber,
    setFileNumber,
    numberOfFileDos,
    setNumberOfFileDos,
    setVarFiches,
    pentId,
    setPentId,
    cp,
    setCp,
    city,
    setCity,
    setFields,
    setVarFilesDossier,
    setImplicitesGroupes,
    selectedGroup,
    data,
    setData,
    localCity,
    setLocalCity,
    localCp,
    setLocalCp,
    selectedValues,
    inputValues,
  } = useClientFileStore();
  const { setFicRef, setPENT_ID, setPDOS_ID, updateExplorer, setUpdateExplorer } = useExplorer();
  const { isLoading, setIsLoading } = useLoadingStore();
  const { setChoiceTab } = useUtilsCrmStore();

  useEffect(() => {
    getClasseurs()
      .then((response) => {
        const filteredClasseurs = response.data.filter(
          (classeur: any) => !classeur.PDOS_NOM.includes("{*}")
        );
        setFilesModel(filteredClasseurs);
      })
      .catch((err) => {
        errorFetch();
        console.log(err);
      });

    getVarFileDossier()
      .then((response) => {
        setVarFilesDossier(response.data);
      })
      .catch((err) => {
        errorFetch();
        console.log(err);
      });

    getImplicitGroups()
      .then((response) => {
        const formattedData = response.data.map((group: any) => ({
          value: group.DOS_CODETRAVAIL + " - " + group.DOS_LIBTRAVAIL,
          label: group.DOS_LIBTRAVAIL,
        }));
        setImplicitesGroupes(formattedData);
      })
      .catch((err) => {
        setIsLoading(false);
        errorFetch();
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setFileNumber(0);
    if (selectedFile === "") {
      setIsLoading(false);
      return;
    } else {
      getFolderClasseursTemplateCode(selectedFile)
        .then((res) => {
          setNumberOfFileDos(res.data);
          setFileNumber(res.data);
        })
        .catch((err) => {
          console.log(err);
        });

      setIsLoading(true);
      if (pentId !== 0 && selectedFile !== 0) {
        getVarFiche(selectedFile, pentId)
          .then((response) => {
            setVarFiches(response.data);
          })
          .catch((err) => {
            errorFetch();
            console.log(err);
          });

        // Récupère les types de champs
        getParticipantsFields(1, pentId, selectedFile)
          .then((response) => {
            setFields(response.data);
            setIsLoading(false);
          })
          .catch((err) => {
            errorFetch();
            console.log(err);
          });
      }
    }
  }, [selectedFile]);

  // Appel API pour récupérer les villes par code postal
  useEffect(() => {
    if (cp === "") {
      setCity("");
      setLocalCity("");
      return;
    }
    axios
      .get(`https://geo.api.gouv.fr/communes?codePostal=${localCp}`)
      .then((res) => {
        if (res.data.length > 0) {
          setCity(res.data[0].nom); // Prend la première ville
          setLocalCity(res.data[0].nom); // Synchronise l'input local
        }
      })
      .catch((err) => console.error(err));
  }, [cp]);

  // Appel API pour récupérer le code postal par ville
  useEffect(() => {
    if (city === "") {
      setCp("");
      setLocalCp("");
      return;
    }
    axios
      .get(`https://geo.api.gouv.fr/communes?nom=${localCity}`)
      .then((res) => {
        if (res.data.length > 1) {
          // Parcourt la liste pour trouver le nom exact, en ignorant la casse
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].nom.toLowerCase() === city.toLowerCase()) {
              // Comparaison insensible à la casse
              setCp(res.data[i].codesPostaux[0]);
              setLocalCp(res.data[i].codesPostaux[0]); // Synchronise l'input local
              return; // Arrête la boucle une fois trouvé
            }
          }
        } else {
          setCp(res.data[0].codesPostaux[0]);
          setLocalCp(res.data[0].codesPostaux[0]); // Synchronise l'input local
        }
      })
      .catch((err) => console.error(err));
  }, [city]);

  const handleSubmit = () => {
    let DOS_CODETRAVAIL;
    let DOS_LIBTRAVAIL;

    if (selectedGroup !== "")
      [DOS_CODETRAVAIL, DOS_LIBTRAVAIL] = selectedGroup.split(" - ");

    const dataSend = {
      ...data,
      DOS_VARCODE: selectedValues,
      "Adresse principale (CP)": localCp,
      "Adresse principale (Ville)": localCity,
      FIC_VAR: inputValues,
      DOS_CODE: fileNumber.toString(),
      PDOS_ID: selectedFile,
      DOS_CODETRAVAIL,
      DOS_LIBTRAVAIL,
      PENT_ID: pentId,
      PFIC_ID: 1,
    };

    postPersonnesFile(dataSend)
      .then((res) => {
        setData({});
        setLocalCp("");
        setLocalCity("");
        setFileNumber(0);
        setSelectedFile(0);
        setPentId(0);
        setChoiceTab("Fiche client");
        setPENT_ID(res.data.PENT_ID);
        setFicRef(res.data.FIC_REF_CLIENT);
        setPDOS_ID(res.data.PDOS_ID);
        setUpdateExplorer(!updateExplorer);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        errorFetch();
      });
  };

  return (
    <div className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-md overflow-y-scroll text-sm mt-5">
      <h2 className="my-5 text-xl font-bold">Création d'un dossier client</h2>
      <div className="flex flex-col justify-start items-start w-full">
        <div className="grid grid-cols-5  w-11/12 m-auto gap-4">
          <div className="col-start-1 col-end-2 w-full flex justify-start items-center">
            <p>Modèle de dossier </p>
          </div>
          <div className="col-start-2 col-end-3 w-full">
            <select
              name="modelFile"
              id="modelFile"
              value={selectedFile}
              className="border-[1px] rounded-md w-full p-2"
              onChange={(e) => {
                const selectedPDOS_ID = Number(e.target.value);

                // Trouve l'objet correspondant à PDOS_ID
                const selectedModel = filesModel.find(
                  (model: any) => model.PDOS_ID === selectedPDOS_ID
                );

                // Met à jour selectedFile et dataFirstPage
                setSelectedFile(selectedPDOS_ID);
                // Si le modèle est trouvé, met à jour PENT_ID
                if (selectedModel) {
                  setPentId(selectedModel.PENT_ID);
                }
              }}
            >
              <option value="" className="text-slate-300 "></option>
              {filesModel?.map((model: any, i: any) => (
                <option key={i} value={model.PDOS_ID}>
                  {model?.PDOS_NOM}
                </option>
              ))}
            </select>
          </div>

          <div className="col-start-4 col-end-5 w-full flex justify-start items-center">
            <p>Numéro de dossier </p>
          </div>
          <div className="col-start-5 col-end-6 w-full">
            <input
              type="text"
              name="fileNumber"
              maxLength={numberOfFileDos || 8}
              value={fileNumber || ""} // Garde une valeur par défaut
              id="fileNumber"
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  // Autorise uniquement les chiffres
                  setFileNumber(Number(value));
                }
              }}
              className="border-[1px] rounded-md w-full p-2"
            />
          </div>

          <hr className="col-start-2 col-end-5 my-2" />
        </div>
        {!isLoading ? (
          selectedFile != "" ? (
            <div className="w-full flex flex-col justify-start items-center">
              {/* Affiche tous les inputs */}
              <RenderClientFile />
              <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
                <div className="col-start-4 col-end-5 w-2/3 mb-5">
                  <button
                    className="w-full mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                    onClick={() => {
                      setData({});
                      setLocalCp("");
                      setLocalCity("");
                      setChoiceTab("");
                      setSelectedFile("");
                    }}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-start-5 col-end-6 w-2/3 mb-5">
                  <button
                    className="w-full mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                    onClick={() => handleSubmit()}
                  >
                    Créer
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-full w-full col-start-1 col-end-6 flex justify-center items-center">
              <p>Aucun model sélectionné.</p>
            </div>
          )
        ) : (
          <div className="h-full w-full col-start-1 col-end-6 flex justify-center items-center">
            <CircleLoarder />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateClientFileExpertComptable;
