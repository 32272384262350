import { useEffect, useState } from "react";
import { getAllUsers } from "../../../utils/user";
import { allUsers } from "../../../interface/data/user";
import { selectUserProps } from "../../../interface/layout";
import { useUserContext } from "../../../context/UserContext";
import { useRightTimeContext } from "../../../context/RightTimeContext";
/**
 * @component
 * @name SelectUser
 * @author Succi Iris
 * @date 2023
 * @description Select pour choisir un utilisateur
 */
const SelectUser: React.FC<selectUserProps> = ({ setUser }) => {
  //Context
  const { id } = useUserContext();

  //State
  const [users, setUsers] = useState<allUsers[]>([]);
  const [selectedUser, setSelectedUser] = useState<string>(id);
  const { visuLimit } = useRightTimeContext();
  useEffect(() => {
    getAllUsers()
      .then((res) => {
        const sortedUsers = res.data.sort((a: any, b: any) =>
          a.identifiant.localeCompare(b.identifiant)
        );
        setUsers(sortedUsers);
        setSelectedUser(id);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newIdentifiant = e.target.value;
    setUser(newIdentifiant);
    setSelectedUser(newIdentifiant);
  };

  return (
    <select
      className="lg:w-72 w-56 lg:h-9 h-6  border-[1px] mr-4 rounded-md"
      value={selectedUser}
      onChange={handleChange}
      disabled={visuLimit === 1}
    >
      {users.map((user) => (
        <option key={user.identifiant} value={user.identifiant}>
          {user.identifiant} - {user.firstname} {user.lastname}
        </option>
      ))}
    </select>
  );
};

export default SelectUser;
