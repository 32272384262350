import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateCommentProps } from "../../../interface/modales";
import { getCurrentWeekDays } from "../../../utils/TimeManager/timeUtils";
import { updateTimeOrComment } from "../../../utils/TimeManager/time";
import CircleLoarder from "../loader/CircleLoarder";
import { errorSendNewTime, successUpdateComment } from "../../../utils/toaster";
import { useDateStore } from "../../../store/TimeManagement/dateStore";
import { useCurrentLangContext } from "../../../context/LangContext";

/**
 * @component
 * @name UpdateComment
 * @author Succi Iris
 * @date 2023
 * @description Un composant qui permet de modifier un commentaire dun temps. 
 */
const UpdateComment: React.FC<updateCommentProps> = ({
  closeModal,
  commentsToUpdate,
  setRenderData,
}) => {
  //Translation
  const { t } = useTranslation();

  //Context
  const { lang } = useCurrentLangContext();

  //Store
  const { startDate } = useDateStore();

  //State
  const [dayNComment, setDayNComment] = useState<any>([]);
  const [originalComments, setOriginalComments] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Get current week days
  const days7 = getCurrentWeekDays(startDate, lang);

  useEffect(() => {
    let daysWithComments = dayNComment;
    if (JSON.stringify(days7) !== JSON.stringify(dayNComment)) {
      daysWithComments = days7
        .map((day, index) => {
          const comment = commentsToUpdate[index];
          if (comment?.ANA_REF !== 0) {
            return {
              ...day,
              comment: comment?.ANA_COMPLEMENTLIB,
              ana_ref: comment?.ANA_REF,
              validated: comment?.validated,
            };
          } else {
            return null;
          }
        })
        .filter(Boolean);
    }
    setDayNComment(daysWithComments);
    setOriginalComments(daysWithComments);
  }, []);

  const handleComment = () => {
    setIsLoading(true);
    const changedComments = dayNComment.filter((day: any, index: number) => {
      return day.comment !== originalComments[index].comment;
    });

    // Formatage des données pour l'envoi au serveur
    const formattedData = changedComments.map((comment: any) => {
      return {
        ANA_REF: comment.ana_ref,
        ANA_COMPLEMENTLIB: comment.comment,
      };
    });

    updateTimeOrComment(formattedData)
      .then(() => {
        setRenderData((prev) => !prev);
        closeModal();
        successUpdateComment();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        errorSendNewTime();
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-2/4 my-6 mx-auto max-w-3xl min-w-[55%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl text-center">
                {t("Modifier un commentaire")}{" "}
              </h3>
            </div>
            {/*body*/}
            {isLoading ? (
              <div className="w-full h-full flex justify-center items-center bg-white my-32">
                <CircleLoarder />
              </div>
            ) : (
              <div className="w-10/12 m-auto font-light p-5 flex flex-col justify-center items-center">
                {dayNComment.map((day: any) => {
                  return (
                    <div className="grid grid-cols-5 w-full" key={day.ana_ref}>
                      <div className="flex justify-start items-start col-start-1 col-end-2">
                        <p className="pt-2 text-xl">{day.dayOfWeek} :</p>
                      </div>
                      <div className="flex justify-center items-center col-start-2 col-end-6">
                        {day.validated === false ? (
                          <textarea
                            className=" border border-slate-200 rounded-md p-2 mt-2 w-full font-thin h-20 text-md overflow-y-scroll"
                            placeholder="Commentaire"
                            value={day.comment}
                            maxLength={255}
                            onChange={(e) => {
                              const newComment = e.target.value;
                              const newDayNComment = dayNComment.map(
                                (currentDay: any) => {
                                  if (currentDay.ana_ref === day.ana_ref) {
                                    return {
                                      ...currentDay,
                                      comment: newComment,
                                    };
                                  }
                                  return currentDay;
                                }
                              );
                              setDayNComment(newDayNComment);
                            }}
                          />
                        ) : (
                          <textarea
                            className=" border border-slate-200 rounded-md p-2 mt-2 w-full font-thin h-20 text-md overflow-y-scroll text-gray-500"
                            placeholder="Commentaire"
                            value={day.comment}
                            maxLength={255}
                            disabled
                            style={{ backgroundColor: "lightgray" }}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {/*footer*/}
            <div className="flex flex-col items-center p-4 border-t border-solid border-slate-200 rounded-b">
              <div className="flex items-end justify-end ">
                <button
                  className="text-bc-orange background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Annuler")}
                </button>
                <button
                  className="text-bc-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleComment}
                >
                  {t("Valider")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default UpdateComment;
