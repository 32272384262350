import { useEffect } from "react";
import Footer from "../components/Layout/Footer";
import { useLocation } from "react-router-dom";
import { useIntervenantStore } from "../store/Crm/Intervenant";
import { useExplorer } from "../store/Crm/Explorer";
import { useUtilsCrmStore } from "../store/Crm/utilsCrm";
import { useClientFileStore } from "../store/Crm/ClientFile";
import Menu from "../components/Home/Menu";
import Dashboard from "../components/Home/Dashboard/Dashboard";
import Header from "../components/Layout/Header";
import useModal from "../hooks/useModal";

/**
 * @page
 * @name Home
 * @description Home page avec les cartes de redirection et les alertes
 * @author Iris Succi
 * @date 2023
 */
const Home = () => {
  //Hook
  const location = useLocation();
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  const pathname = location.pathname.split("/")[2];
  const { resetIntervenant } = useIntervenantStore();
  const { resetExplorer } = useExplorer();
  const { resetUtilsCrm } = useUtilsCrmStore();
  const { resetClientFile } = useClientFileStore();

  useEffect(() => {
    if (pathname === "home") {
      resetExplorer();
      resetIntervenant();
      resetClientFile();
      resetUtilsCrm();
    }
  }, [location]);

  return (
    <div className="w-screen h-screen overflow-hidden flex flex-col justify-start items-center bg-white">
      <Header closeModal={closeModal} openModal={openModal} />
      {isModalOpen ? modalContent : null}
      {/* MODAL */}
      <div className="flex justify-start items-start w-11/12 h-screen m-auto bg-white mt-10">
        <Menu />
        <Dashboard />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
