import { useEffect, useState } from "react";
import { getCSV } from "../../utils/TimeManager/time";
import download from "../../assets/icons/download.svg";
import useOutsideClick from "../../hooks/useClickOutside";
import { exportButtonProps } from "../../interface/buttons";
import { filterStore } from "../../store/TimeManagement/filterStore";
import { useListStore } from "../../store/TimeManagement/listStore";
import { useUserContext } from "../../context/UserContext";
import { useLoadingStore } from "../../store/utils/loading";
import "../../css/animations.css";

interface DataInterface {
  identifiant: string;
  DOS_REF?: number;
  MIS_REF?: number;
  AOR_REF?: number;
  ANA_CODELIB?: string;
  startDate: Date;
  endDate: Date;
  classeurRef?: number;
  choice?: string;
  fields: string;
  timeCondition?: string;
  ANA_TEMPS?: number;
}

/**
 * @component
 * @name ExportButton
 * @author Iris Succi
 * @date 2023
 * @description Un composant qui restitue un bouton pour exporter des documents.
 */

const ExportButton: React.FC<exportButtonProps> = ({
  isOpenExport,
  setIsOpenExport,
}) => {
  //Hooks
  const { ref, isVisible, setIsVisible } = useOutsideClick(false, () =>
    setIsOpenExport(false)
  );
  const { selectedItems } = useListStore();
  const { id } = useUserContext();
  const { isLoading, setIsLoading } = useLoadingStore();

  const {
    selectedFile,
    selectedMission,
    selectedOrigin,
    selectedLibelle,
    startDateFilter,
    endDateFilter,
    selectedClasseur,
    selectedUsersActeurs,
    selectedAllUsers,
    choice,
    selectedMisRef,
    selectedOriginObject,
    selectedLibelleObject,
    selectedOperator,
    selectedTime,
  } = filterStore();

  const [data, setData] = useState<DataInterface | null>(null);

  useEffect(() => {
    const newData: DataInterface = {
      identifiant:
        selectedUsersActeurs.length === 0 && selectedAllUsers.length === 0
          ? id
          : selectedUsersActeurs.length > 0
          ? selectedUsersActeurs.join(",")
          : selectedAllUsers.join(","),

      startDate: startDateFilter,
      endDate: endDateFilter,
      choice: choice,
      fields: selectedItems.join(","),
    };

    if (choice !== "") {
      newData.choice = choice;
    }

    if (selectedFile !== 0) {
      newData.DOS_REF = selectedFile;
    }

    if (selectedMisRef !== 0) {
      newData.MIS_REF = selectedMisRef;
    }

    if (
      selectedOriginObject?.value !== undefined &&
      selectedOriginObject?.value !== null &&
      selectedOriginObject?.value !== 0
    ) {
      newData.AOR_REF = selectedOriginObject.value;
    }

    if (
      selectedLibelleObject?.value !== undefined &&
      selectedLibelleObject?.value !== null &&
      selectedLibelleObject?.value !== 0
    ) {
      newData.ANA_CODELIB = selectedLibelleObject.value;
    }

    if (selectedClasseur !== 0) {
      newData.classeurRef = selectedClasseur;
    }

    if (selectedOperator !== "") {
      newData.timeCondition = selectedOperator;
    }

    if (selectedTime !== 0) {
      newData.ANA_TEMPS = selectedTime;
    }

    setData(newData);
  }, [
    selectedFile,
    selectedMission,
    selectedOrigin,
    selectedLibelle,
    startDateFilter,
    endDateFilter,
    selectedClasseur,
    choice,
    selectedUsersActeurs,
    selectedAllUsers,
    selectedItems,
  ]);

  const toggleMenu = () => {
    setIsVisible(!isVisible);
    setIsOpenExport(!isVisible);
  };

  // This function is used to download the table list in pdf, excel or csv format
  const handleDownload = () => {
    setIsLoading(true);
    if (data !== null && data.choice === "") {
      data.choice = "csv";
    }
    const downloadPromise = getCSV(data);

    downloadPromise
      .then(() => toggleMenu())
      .catch((err) => {
        console.error("Download failed", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="relative" ref={ref}>
      {isLoading ? (
        <div className="w-12 h-8 bg-bc-orange border-[1px] flex justify-center items-center mr-8 px-4 rounded text-white">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-4 w-4"></div>
        </div>
      ) : (
        <button
          className="w-12 h-8 bg-bc-orange border-[1px] flex justify-center items-center mr-8 px-4 rounded text-white hover:bg-bc-lightOrange"
          onClick={toggleMenu}
        >
          <img src={download} alt="icon download" className="h-4 w-4" />
        </button>
      )}

      {isOpenExport && (
        <div className="absolute top-10 w-44 right-8 h-auto border-[1px] bg-slate-200 rounded-md z-50">
          <div className="flex flex-col justify-start items-start ">
            <button
              onClick={() => {
                setIsOpenExport(false);
                handleDownload();
              }}
              className="p-2 text-sm w-full hover:bg-slate-50 rounded-b-md"
            >
              Csv
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportButton;
