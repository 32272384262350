import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useRightInvoiceContext } from "../../../context/RightInvoiceContext";
import { useUserContext } from "../../../context/UserContext";
import { amountsStore } from "../../../store/Invoice/amountsStore";
import { createBillableItemsStore } from "../../../store/Invoice/createBillableItemsStore";
import { useCreateFee } from "../../../store/Invoice/createFeeStore";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { useListStore } from "../../../store/TimeManagement/listStore";
import chevron from "../../../assets/icons/chevron-right.svg";
import Amounts from "../../Invoice/modal/Create/Amounts";
import { getBillableFormDatas, postFeeWithTime } from "../../../utils/Invoice/fees";
import { getAllUsersWithInactive } from "../../../utils/user";
import { successValidateTime } from "../../../utils/toaster";
import { useLoadingStore } from "../../../store/utils/loading";
/**
 * @component
 * @name ManualValidationTime
 * @author Succi Iris
 * @date 2023
 * @description Un composant qui permet de valider manuellement un temps.
 */
const ManualValidationTime = ({ setRenderData }: any) => {
  const { id } = useUserContext();
  const { setIsLoading } = useLoadingStore();
  const {
    missions,
    setMissions,
    selectedMission,
    setSelectedMission,
    origins,
    setOrigins,
    selectedOrigin,
    setSelectedOrigin,
    libelles,
    setLibelles,
    selectedLibelle,
    setSelectedLibelle,
    subscriptions,
    setSubscriptions,
    selectedSubscription,
    setSelectedSubscription,
    comment,
    setComment,
    actors,
    setActors,
    selectedActor,
    setSelectedActor,
    resetFee,
  } = useCreateFee();
  const {createManualFee, setCreateManualFee, selectedTimeForValidated } = useListStore();
    const {
    selectedFile,
    setSelectedFile,
  } = createBillableItemsStore();
  const {
    animationClass,
    setAnimationClass,
    displayDiv,
    setDisplayDiv,
  } = useCreateInvoiceStore();
    const { withoutMissionRight } = useRightInvoiceContext();
    const {
        quantity, 
        setQuantity,
        setUnitPrice,
        ht,
        setHt,
        ttc,
        unitPrice,
        selectedTvaRate,
        tva,
        resetAmounts,
    } = amountsStore();

      // States
    const [compteur, setCompteur] = useState(230);
    const textAreaRef = useRef(null);

  /**
   * @function
   * @author Succi Iris
   * @date 2023
   * @description Pour afficher ou cacher le formulaire de création d'un élément facturable
   */
        useEffect(() => {
            if (createManualFee === true) {
                setDisplayDiv(true);
              setAnimationClass("slideInTableFromRight 0.3s forwards");
            } else {
              setAnimationClass("slideOutTableToRight 0.3s forwards");
              setTimeout(() => {
                setDisplayDiv(false);
              }, 300);
            }
          }, [createManualFee]);

    useEffect(() => {
        setSelectedFile(selectedTimeForValidated[0].DOS_REF);
        const htCalc = selectedTimeForValidated.reduce(
          (acc: any, curr: any) => acc + curr.AA_ANA_EMTHT,
          0
        );
        setHt(htCalc);
    }, [selectedTimeForValidated]);

      // Recupère les données pour les honoraires et les débours internes
    const fetchBillableFormDatas = () => {
      if (selectedFile !== 0) {
        getBillableFormDatas(selectedFile, 2)
          .then((res) => {
            // MISSIONS
            const formattedMissions = res.data.Missions.map((mission: any) => ({
              label:
                new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
                " - " +
                mission.MIS_LIBELLE,
              value: mission.MIS_REF,
              origins: mission.Origines,
              abonnementId: mission.ABN_REF,
              abonnementLibelle: mission.ABN_LIBELLES,
            }));
            setMissions(formattedMissions);
            // ORIGINS
            const formattedOrigins = res?.data?.Origines.map((origin: any) => ({
              value: origin.AOR_REF,
              label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
              libelles: origin.Libelles.map((libelle: any) => ({
                value: libelle.ALI_CODE,
                label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
                ePrixUnitaire: libelle.ALI_EPRIX_UNITAIRE,
                quantite: libelle.ALI_QUANTITE,
              })),
            }));
            setOrigins(formattedOrigins);

            if (origins.length === 1) {
              setSelectedOrigin(formattedOrigins[0].value);
              setLibelles(formattedOrigins[0].libelles);
              setSelectedLibelle(formattedOrigins[0].libelles[0].value);
            }
            // SUBSCRIPTIONS
            const formattedSubscriptions = res.data.Abonnements.map(
              (subscription: any) => ({
                label: subscription.ABN_LIBELLES,
                value: subscription.ABN_REF,
              })
            );
            setSubscriptions(formattedSubscriptions);
          })
          .catch((err) => console.log(err));
      }
  };

  useEffect(() => {
    fetchBillableFormDatas();
    getAllUsersWithInactive()
      .then((res) => {
        setSelectedActor(id);
        const formattedFiles = res.data
          .filter((actor: any) => actor.actif === 1)
          .map((actor: any) => ({
            label: actor.firstname + " " + actor.lastname,
            value: actor.identifiant,
          }));
        setActors(
          formattedFiles.sort((a: any, b: any) =>
            a.label.localeCompare(b.label)
          )
        );
      })
      .catch((err) => console.log(err));

    // Reset form if file item changes
    setSelectedMission(0);
    setSelectedOrigin("");
    setSelectedLibelle("");
    setComment("");
    setSelectedSubscription(0);
  }, [createManualFee, selectedFile]);

  useEffect(() => {
    if (
      selectedMission &&
      selectedMission !== 0
    ) {
      const selectedMissionData = missions?.find(
        (mission) => mission.value === selectedMission
      );
      // SET ORIGINS if mission has origins
      if (selectedMissionData?.origins?.length > 0) {
        const formattedOrigins = selectedMissionData?.origins?.map(
          (origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
            libelles: origin?.Libelle?.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
              ePrixUnitaire: libelle.ALI_EPRIX_UNITAIRE,
              quantite: libelle.ALI_QUANTITE,
            })),
          })
        );

        setOrigins(formattedOrigins);
        setSelectedOrigin(formattedOrigins[0].value);
        setLibelles(formattedOrigins[0].libelles);
        setSelectedLibelle(formattedOrigins[0].libelles[0].value);
        setQuantity(formattedOrigins[0].libelles[0].quantite);
        setUnitPrice(formattedOrigins[0].libelles[0].ePrixUnitaire);
      } else {
        setLibelles([]);
        setSelectedLibelle("");
        fetchBillableFormDatas();
      }
      // SET SUBSCRIPTIONS if mission has abonnement
      if (selectedMissionData?.abonnementId !== null) {
        const formattedSubscriptions = [
          {
            label: selectedMissionData.ABN_LIBELLES,
            value: selectedMissionData.ABN_REF,
          },
        ];
        setSubscriptions(formattedSubscriptions);
      }
    } 
  }, [selectedMission]);

    const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOrigin = event.target.value;
        setSelectedOrigin(selectedOrigin);
        const selectedOriginObj = origins?.find(
        (origin) => origin.value.toString() === selectedOrigin
        );

        if (selectedOriginObj) {
        setLibelles(selectedOriginObj.libelles);
        } else {
        setLibelles([]);
        }
    };

      // This is for block commentary input when compteur is 0
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        let nouveauTexte = e.target.value;
        if (nouveauTexte.length > 230) {
        nouveauTexte = comment;
        }
        const nouveauCompteur = 230 - nouveauTexte.length;
        setComment(nouveauTexte);
        setCompteur(nouveauCompteur);
    };

    const handlePostBillableItem = () => {
      setIsLoading(true);
      const data = {
        "DOS_REF": selectedFile,
        "FIC_REF": 0,
        "ANA_DATEREALISATION": new Date(),
        "ANA_REDACTEUR": selectedActor,
        "AOR_REF": selectedOrigin,
        "ANA_CODELIB": selectedLibelle,
        "ANA_EMTHT": ht,
        "TVA_CLE": selectedTvaRate,
        "ANA_EMTTVA": tva,
        "ANA_EMTTTC": ttc,
        "MIS_REF": selectedMission,
        "ANA_NOTES": comment,
        "ANA_EPRIXUNITAIRE":  unitPrice === null ? 0 : unitPrice,
        "ANA_QUANTITE": quantity === null ? 0 : quantity,
        "AOR_PROCESSUS":2,
        "ANA_DP":null,
        "PFIC_ID_DEBEXT_CL": null,
        "FIC_CLE_DEBEXT_CL": null,
        "FIC_REF_DEBEXT_CL": null,
        "ABN_REF_DEST": selectedSubscription,
        "ANA_EMTNS": 0,
        "ANA_PIECE": null,
        "ANA_LIBELLE_CPT": null,
        "timesANA_REF": selectedTimeForValidated.map((time) => time.AA_REF),
      }
      try {
        postFeeWithTime(data)
          .then(() => {
            setDisplayDiv(false);
            resetAmounts();
            resetFee();
            setIsLoading(false);
            successValidateTime();
            setRenderData((prev: any) => !prev);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    }

    return (
    <>
    {displayDiv ? (
      <div
        className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10 flex justify-start items-center`}
        style={{
          animation: animationClass,
        }}
      >
        <button
          className="h-full w-10 bg-gray-600 flex justify-center items-center"
          style={{
            animation: animationClass,
          }}
          onClick={() => setCreateManualFee(!createManualFee)}
        >
          <img src={chevron} alt="fleche" />
        </button>
        <div className="flex flex-col justify-start items-center w-full h-full mx-4 relative">
            <div className="flex flex-col justify-start items-center w-full h-[80%] mx-4">
              <h2 className="font-bold text-xl text-center 3xl:py-5 py-2 w-full">
                Création d'un élément facturable 
              </h2>
              <div className="3xl:mt-10 mt-2 flex justify-start items-center w-full bg-white rounded-md 2xl:h-20 h-16 px-4">
                <div className="w-1/2 flex justify-start items-center ">
                  <p className="mr-10">Dossier:</p>
                  <p>{selectedTimeForValidated[0].DOS_TITRE}</p>
                </div>
              </div>
              <div className="w-full flex flex-col justify-start items-center">
                <div className="w-full grid grid-cols-12 2xl:gap-5 gap-2 mt-8 ">
                <p className="col-start-1 col-end-3">
                    <label htmlFor="mission">Mission:</label>
                </p>
                <select
                    name="mission"
                    id="mission"
                    className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedMission}
                    disabled={withoutMissionRight === 1}
                    onChange={(event) => {
                    setSelectedMission(Number(event.target.value));
                    }}
                >
                    <option value={""}></option>
                    {missions?.map((mission, i) => (
                    <option key={i} value={mission?.value}>
                        {mission?.label}
                    </option>
                    ))}
                </select>
                <p className="col-start-7 col-end-9">
                    <label htmlFor="mission">Abonnement:</label>
                </p>
                <select
                    name="abonnement"
                    id="abonnement"
                    className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedSubscription}
                    disabled={subscriptions.length === 0}
                    onChange={(event) => {
                    setSelectedSubscription(Number(event.target.value));
                    }}
                >
                    <option value={""}></option>
                    {subscriptions?.map((subscription, i) => (
                    <option key={i} value={subscription?.value}>
                        {subscription?.label}
                    </option>
                    ))}
                </select>
                <p className="col-start-1 col-end-3">
                    <label htmlFor="mission">Origine:</label>
                </p>
                <select
                    name="origins"
                    id="origins"
                    className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedOrigin}
                    onChange={handleOriginChange}
                    disabled={origins?.length === 1}
                >
                    <option value={""}></option>
                    {origins?.map((origin, i) => (
                    <option key={i} value={origin?.value}>
                        {origin?.label}
                    </option>
                    ))}
                </select>
                <p className="col-start-7 col-end-9">
                    <label htmlFor="mission">Libellé:</label>
                </p>
                <select
                    name="libelle"
                    id="libelle"
                    className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedLibelle}
                    onChange={(event) => {
                    const selectedValue = event.target.value;
                    setSelectedLibelle(selectedValue);

                    const selectedLibelleObject = libelles.find(
                        (libelle) => libelle.value === selectedValue
                    );
                    if (selectedLibelleObject) {
                        setQuantity(selectedLibelleObject.quantite);
                        setUnitPrice(selectedLibelleObject.ePrixUnitaire);
                    }
                    }}
                >
                    <option value={""}></option>
                    {libelles?.map((libelle, i) => (
                    <option key={i} value={libelle?.value}>
                        {libelle?.label}
                    </option>
                    ))}
                </select>
                <p className="col-start-1 col-end-3">
                    <label htmlFor="mission">Commentaire:</label>
                </p>
                <div className="col-start-3 col-end-7 mt-1 flex flex-col">
                    <textarea
                    name="Comm"
                    id="Comm"
                    value={comment}
                    ref={textAreaRef}
                    onChange={handleChange}
                    className="w-full border-[1px] rounded border-gray-300 h-20 py-1 z-20 "
                    />
                    <div className="w-full flex justify-end items-end pt-1 pr-2">
                    <p className="text-gray-400 text-xs">
                        Il vous reste {compteur} caractère(s)
                    </p>
                    </div>
                </div>
                <p className="col-start-7 col-end-9">
                    <label htmlFor="mission">Acteur:</label>
                </p>
                <select
                    name="actor"
                    id="actor"
                    className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedActor}
                    onChange={(event) => {
                    setSelectedActor(event.target.value);
                    }}
                >
                    <option value={""}></option>
                    {actors?.map((actor, i) => (
                    <option key={i} value={actor?.value}>
                        {actor?.label}
                    </option>
                    ))}
                </select>
                </div>
                <div className="w-full mt-5">
                <Amounts />
                </div>
            </div>
            </div>
          <div className={`absolute flex bottom-2 right-0`}>
            <button
              className="w-44 h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md mr-4"
              onClick={() => {
                setSelectedFile(0);
                setDisplayDiv(false);
                resetAmounts();
                resetFee();
              }}
            >
              Annuler{" "}
            </button>
            <button
              className="w-44 h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
              onClick={() => {
                handlePostBillableItem()
              }}
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    ) : null}
  </>
    )
}

export default ManualValidationTime