import { getDossiers } from "../../../../utils/folders";
import { useSearchBarStore } from "../../../../store/utils/searchBar";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { useEffect, useState } from "react";
import DossiersTree from "./DossiersTree";

/**
 * @component
 * @name ClasseursTree
 * @description Composant qui affiche un classeur et ses dossiers associés.
 * @author Iris Succi
 * @date 2023
 */
const ClasseursTree = ({ classeur, isOpen, onToggle }: any) => {
  const { search } = useSearchBarStore();
  const { dossiers, setDossiers, setPDOS_ID, updateExplorer} = useExplorer();

  const [selectedDossier, setSelectedDossier] = useState<string | null>(null);

  // Fonction pour ouvrir le classeur et récupérer les dossiers associés uniquement lors du clic
  const handleOpen = () => {
    if (!isOpen) {
      setDossiers([]); // Réinitialise les dossiers avant de charger les nouveaux
      setPDOS_ID(classeur.PDOS_ID); // Définit l'ID du classeur sélectionné
      getDossiers(classeur.PDOS_ID)
        .then((res) => {
          const sortedDossiers = res?.data.sort((a: any, b: any) => {
            return a.FIC_TITRE_CLIENT.localeCompare(b.FIC_TITRE_CLIENT);
          });
          setDossiers(sortedDossiers);
        })
        .catch((err) => console.log(err));
    }
    onToggle(); // Change l'état d'ouverture/fermeture du classeur
  };

  const filteredLocalDossiers = dossiers.filter(
    (dossier: any) =>
      dossier.FIC_TITRE_CLIENT.toLowerCase().includes(search.toLowerCase()) ||
      dossier.DOS_CODE.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    if (isOpen) {
      getDossiers(classeur.PDOS_ID)
        .then((res) => {
          const sortedDossiers = res?.data.sort((a: any, b: any) => {
            return a.FIC_TITRE_CLIENT.localeCompare(b.FIC_TITRE_CLIENT);
          });
          setDossiers(sortedDossiers);
        })
        .catch((err) => console.log(err));
    }
  }, [updateExplorer]); // L'effet s'exécute lorsque updateExplorer change
  
  return (
    <div className="w-full relative ml-4">
      <div className="flex justify-start items-center cursor-pointer">
        <div
          onClick={handleOpen} // Appelle la fonction lors du clic
          className="font-light w-full flex justify-start items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill={classeur?.CLASSEUR_COLOR}
            stroke={classeur?.CLASSEUR_COLOR}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-folder"
          >
            <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
          </svg>

          <p className="pl-1">{classeur?.PDOS_NOM}</p>
        </div>
      </div>
      {isOpen &&
        filteredLocalDossiers &&
        filteredLocalDossiers.length > 0 &&
        filteredLocalDossiers?.map((dossier: any) => (
          <div className="relative" key={dossier.DOS_REF}>
            <div className="absolute" />
            <DossiersTree
              dossier={dossier}
              isSelected={selectedDossier === dossier.DOS_REF}
              onSelect={() => setSelectedDossier(dossier.DOS_REF)}
            />
          </div>
        ))}
    </div>
  );
};

export default ClasseursTree;
