/* eslint-disable react-hooks/exhaustive-deps */
import { editTableTime } from "../../../interface/tables";
import {
  dateFormatted,
  getCurrentWeekDays,
} from "../../../utils/TimeManager/timeUtils";
import plus from "../../../assets/icons/plus.svg";
import AddComment from "../modals/AddComment";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getAllDossiers } from "../../../utils/folders";
import Select from "react-select";
import {
  getMissions,
  getOrigins,
  getTauxHoraire,
} from "../../../utils/TimeManager/timeEntry";
import { useUserContext } from "../../../context/UserContext";
import trash from "../../../assets/icons/trash-2.svg";
import CircleLoarder from "../loader/CircleLoarder";
import { deleteTimeOneByOne } from "../../../utils/TimeManager/time";
import UpdateComment from "../modals/UpdateComment";
import { useRightTimeContext } from "../../../context/RightTimeContext";
import {
  errorDeleteValidatedTime,
  errorSameData,
} from "../../../utils/toaster";
import { useDateStore } from "../../../store/TimeManagement/dateStore";
import { useLoadingStore } from "../../../store/utils/loading";
import { useDataStore } from "../../../store/TimeManagement/dataStore";
import { useListStore } from "../../../store/TimeManagement/listStore";
import { useAddRowEditStore } from "../../../store/TimeManagement/addRowEdit";
import useTooltip from "../../../hooks/useTooltip";
import { useCurrentLangContext } from "../../../context/LangContext";
import { editStore } from "../../../store/TimeManagement/editStore";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";

/**
 * @component
 * @name EditView
 * @author Succi Iris
 * @date 2023
 * @description
 * Le composant `EditView` permet d'afficher les temps à éditer dans un tableau pour une semaine complete.
 */
const EditView: React.FC<editTableTime> = ({
  openModal,
  closeModal,
  user,
  setRenderData,
}) => {
  //Translation
  const { t } = useTranslation();

  // Context
  const { id } = useUserContext();
  const { deleteTime } = useRightTimeContext();

  //Hook
  const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();
  const { lang } = useCurrentLangContext();
  const [, setIsTyping] = useUnsavedChangesWarning();

  // Store
  const { startDate } = useDateStore();
  const { isLoading, setIsLoading } = useLoadingStore();
  const { dataWeekSummary, setDataWeekSummary, updateItem, updatedItems } =
    useDataStore();
  const { newLines, setNewLines } = useAddRowEditStore();
  const { dataListTime, setDataListTime } = useListStore();
  const {
    fileOptions,
    missions,
    origins,
    libelles,
    tauxHoraires,
    dayTimes,
    selectedFile,
    selectedMission,
    selectedOrigin,
    selectedLibelle,
    selectedTauxHoraire,
    updateData,
    commentsForSend,
    timeId,
    setFileOptions,
    setMissions,
    setOrigins,
    setLibelles,
    setTauxHoraires,
    setDayTimes,
    setSelectedFile,
    setSelectedMission,
    setSelectedOrigin,
    setSelectedLibelle,
    setSelectedTauxHoraire,
    setUpdateData,
    setCommentsForSend,
  } = editStore();

  // Fonction pour obtenir les jours de la semaine actuelle
  const days7 = getCurrentWeekDays(startDate, lang);

  // State pour le taux horaire
  const [isDisableTaux, setIsDisableTaux] = useState<boolean>(false);

  const handleInputChange = (colIndex: number, value: string) => {
    setDayTimes((prevTimes) => ({
      ...prevTimes,
      [colIndex]: { time: value, date: days7[colIndex].fullDate },
    }));
  };

  useEffect(() => {
    setDayTimes({});
  }, [newLines]);

  // Cette fonction ajoute un objet à la liste des temps à envoyer
  const addObject = (time: string, date: Date) => {
    const dateKey = new Date(date).toISOString().split("T")[0];
    const commentForDate = commentsForSend[dateKey];
    const comment = commentForDate || "";

    const data = {
      DOS_REF: selectedFile,
      MIS_REF: selectedMission,
      AOR_REF: selectedOrigin,
      ANA_CODELIB: selectedLibelle,
      ANA_TEMPS: time,
      ANA_DATEREALISATION: dateFormatted(date),
      ANA_OPERATEUR: id,
      ANA_REDACTEUR: user,
      ANA_EPRIXUNITAIRE: selectedTauxHoraire ? selectedTauxHoraire : 0,
      ANA_COMPLEMENTLIB: comment.length <= 255 ? comment : "",
      ANA_NOTES: comment.length > 255 ? comment : "",
    };

    setDataListTime(data);
  };

  useEffect(() => {}, [dataListTime]);

  useEffect(() => {
    for (const dayTime of Object.values(dayTimes)) {
      addObject(dayTime.time, new Date(dayTime.date));
    }
    // Réinitialise updateData après l'ajout
    setIsTyping(false);
    setCommentsForSend({});
    setUpdateData(false);
  }, [dayTimes, updateData]);

  // Vérifie si les données sont dupliquées avant de les envoyer
  useEffect(() => {
    const isDuplicate = dataWeekSummary.some(
      (item: any) =>
        item.DOS_REF === selectedFile &&
        item.MIS_REF === selectedMission &&
        item.AOR_REF == selectedOrigin &&
        item.ANA_CODELIB == selectedLibelle
    );

    if (isDuplicate) {
      errorSameData();
      setSelectedFile(0);
      setSelectedMission(0);
      setSelectedOrigin("");
      setSelectedLibelle("");
      setNewLines([]);
    }
  }, [selectedLibelle]);

  // Update un temps
  const handleItemTimeChange = (
    itemIndex: number,
    timeIndex: number,
    currentDate: Date,
    newValue: string
  ) => {
    // Créer une copie de dataWeekSummary
    const newDataWeekSummary = JSON.parse(JSON.stringify(dataWeekSummary));
    // Remplace la valeur du temps
    newDataWeekSummary[itemIndex].Times[timeIndex].time = newValue;

    newDataWeekSummary[itemIndex].Times[timeIndex].updateTime = currentDate;

    // Mets à jour dataWeekSummary
    setDataWeekSummary(newDataWeekSummary);
    // Compare l'objet modifié à l'objet original
    if (
      newValue && // vérifie si la nouvelle valeur n'est pas vide
      JSON.stringify(newDataWeekSummary[itemIndex]) !==
        JSON.stringify(dataWeekSummary[itemIndex])
    ) {
      // Update updatedItems
      updateItem(itemIndex, newDataWeekSummary[itemIndex]);
    }
  };

  //Sert à réinitialiser la mission sélectionnée lorsque le dossier sélectionné est modifié
  useEffect(() => {
    setSelectedMission(0);
    setSelectedOrigin("");
    setSelectedLibelle("");
    setSelectedFile(0);
  }, [newLines]);

  // Call tous les dossiers
  useEffect(() => {
    if (newLines?.length > 0) {
      getAllDossiers()
        .then((res) => {
          const sortedData = res.data.sort((a: any, b: any) =>
            a.FIC_TITRE_CLIENT.localeCompare(b.FIC_TITRE_CLIENT)
          );
          const formattedFiles = sortedData.map((file: any) => ({
            value: file.DOS_REF,
            label: file.FIC_TITRE_CLIENT,
          }));
          setFileOptions(formattedFiles);

          const dosRef = JSON.parse(localStorage.getItem("dosRef") || "0");
          setSelectedFile(dosRef);
        })
        .catch((err) => console.log(err));
    }
  }, [newLines]);

  // Call missions by file
  useEffect(() => {
    if (selectedFile !== 0) {
      getMissions(id, selectedFile)
        .then((res) => {
          const formattedMissions = res.data.map((mission: any) => {
            let label = mission.MIS_LIBELLE;

            // Retire tout ce qui est entre parenthèses, y compris les parenthèses elles-mêmes
            label = label.replace(/\s*\(.*?\)\s*/g, "");

            if (mission.MIS_REF !== null && mission.MIS_DEBUT) {
              label =
                new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
                " - " +
                label; // Utilise la variable 'label' après avoir retiré le contenu entre parenthèses
            }

            return {
              value: mission.MIS_REF,
              label: label,
            };
          });

          setMissions(formattedMissions);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedFile, newLines]);

  // Call de l origin
  useEffect(() => {
    if (selectedMission !== 0) {
      getOrigins(selectedFile, selectedMission)
        .then((res) => {
          const formattedOrigins = res.data.map((origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_LIBELLE,
            libelles: origin.Libelles.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_LIBELLE,
            })),
          }));

          setOrigins(formattedOrigins);
        })
        .catch((err) => console.log(err));
    } else {
      getOrigins(selectedFile, null)
        .then((res) => {
          const formattedOrigins = res.data.map((origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_LIBELLE,
            libelles: origin.Libelles.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_LIBELLE,
            })),
          }));

          setOrigins(formattedOrigins);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedFile, selectedMission, newLines]);

  // Call taux horaire par user, file, mission, origin
  useEffect(() => {
    if (selectedOrigin !== "") {
      getTauxHoraire(selectedFile, selectedMission, selectedOrigin, user)
        .then((res) => {
          setTauxHoraires(res.data);
          setSelectedTauxHoraire(res.data[0]);
          if (res.data === null) {
            setIsDisableTaux(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedOrigin, newLines]);

  const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrigin = event.target.value;
    setSelectedOrigin(selectedOrigin);
    const selectedOriginObj = origins.find(
      (origin) => origin.value.toString() === selectedOrigin
    );

    if (selectedOriginObj) {
      setLibelles(selectedOriginObj.libelles);
    } else {
      setLibelles([]);
    }
  };

  // Supprime un temps
  const handleDeleteTime = async (i: number) => {
    setIsLoading(true);
    const currentItem = dataWeekSummary[i];

    // Vérifie si au moins un des temps est validé
    const isAnyTimeValidated = currentItem.Times.some(
      (time: any) => time.validated
    );

    // Si au moins un des temps est validé, envoie une alerte et ne fait rien d'autre
    if (isAnyTimeValidated) {
      errorDeleteValidatedTime();
      setIsLoading(false);
      return;
    }

    const anaRefs = currentItem.Times.filter(
      (time: any) => time.ANA_REF !== 0
    ).map((time: any) => ({ ANA_REF: time.ANA_REF })); // Crée un objet pour chaque ANA_REF

    deleteTimeOneByOne(anaRefs) // Envoie le tableau d'objets à deleteTimeOneByOne
      .then(() => {
        setIsLoading(false);
        setRenderData((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setRenderData((prev) => !prev);
      });
  };

  // Verifie si l'utilisateur est en train de taper
  useEffect(() => {
    if (Object.keys(dayTimes).length > 0 && updatedItems.length > 0) {
      setIsTyping(true);
    }
  }, [dayTimes]);

  // Reset les champs quand le dossier est changé
  useEffect(() => {
    setSelectedLibelle("");
    setSelectedMission(0);
    setMissions([]);
    setSelectedOrigin("");
    setOrigins([]);
    setSelectedTauxHoraire(0);
    setTauxHoraires([]);
    setLibelles([]);
    setDayTimes({});
  }, [selectedFile]);

  return (
    <div className="h-full overflow-y-scroll overflow-x-scroll lg:overflow-x-hidden w-[98%] m-auto border-[1px] rounded-md">
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
          <CircleLoarder />
        </div>
      ) : (
        <table className="w-full h-auto ">
          <thead>
            <tr className="w-full h-12 border-[1px] bg-[#cecece] sticky top-0 text-bc-darkGrey z-30 text-sm">
              <th className="w-8"></th>
              <th className="text-start pl-1">{t("Dossier")}</th>
              <th className="text-start">{t("Mission")}</th>
              <th className="text-start">{t("Origine")}</th>
              <th className="text-start">{t("Libellé")}</th>
              <th className="text-start pr-2">{t("Tx horaire")}</th>
              <th className="text-center border-r-[1px] border-black pr-2">
                {t("Comm.")}
              </th>
              {days7.map((day, colIndex) => (
                <th
                  key={colIndex}
                  className={`sticky top-0 bg-[#cecece] h-10 text-center text-bc-darkGrey`}
                >
                  {day.dayOfWeek.slice(0, 3)} {day.dayOfMonth}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="h-auto">
            {/* This is for new time */}
            {newLines.map((_item, i) => {
              const rowColor = i % 2 === 0 ? "bg-bc-lightGrey" : "bg-white";
              return (
                <tr
                  key={i}
                  className={`w-full h-10 border-b-[1px] ${rowColor}`}
                >
                  <td className="pt-2">
                    <button onClick={() => setNewLines([])}>
                      <img src={trash} alt="poubelle" className="w-5 h-5" />
                    </button>
                  </td>
                  <td className="text-start p-1 w-[15%]">
                    <div className="flex flex-col">
                      <Select
                        options={fileOptions}
                        isClearable
                        className="w-[90%] h-9 z-20 "
                        isSearchable
                        value={fileOptions.find(
                          (option) => option.value === selectedFile
                        )}
                        onChange={(selectedOption: any | null) => {
                          setSelectedFile(selectedOption?.value || 0);
                        }}
                      />
                    </div>
                  </td>
                  <td className="text-start w-[15%]">
                    <select
                      name="mission"
                      id="mission"
                      className="w-[90%] border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                      value={selectedMission}
                      onChange={(event) => {
                        setSelectedMission(Number(event.target.value));
                      }}
                    >
                      <option value={""}></option>
                      {missions?.map((mission, i) => (
                        <option key={i} value={mission?.value}>
                          {mission?.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="text-start w-[15%]">
                    <select
                      name="Origine"
                      id="Origine"
                      className="w-[90%] border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                      value={selectedOrigin}
                      onChange={handleOriginChange}
                    >
                      <option value={""}></option>
                      {origins?.map((origin, i) => (
                        <option key={i} value={origin.value}>
                          {origin.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="text-start w-[15%]">
                    <select
                      name="Libelle"
                      id="Libelle"
                      className="w-[90%] border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                      value={selectedLibelle}
                      onChange={(event) =>
                        setSelectedLibelle(event.target.value)
                      }
                    >
                      <option value={""}></option>
                      {libelles?.map((libelle, i) => (
                        <option key={i} value={libelle.value}>
                          {libelle.label}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="text-center w-[5%]">
                    {tauxHoraires !== "Not supposed to select rates" &&
                    Array.isArray(tauxHoraires) ? (
                      <select
                        name="Taux horaire"
                        id="Taux horaire"
                        className={
                          isDisableTaux
                            ? "bg-gray-200 w-[90%] border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                            : "w-[90%] border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                        }
                        value={selectedTauxHoraire}
                        onChange={(event) =>
                          setSelectedTauxHoraire(Number(event.target.value))
                        }
                        disabled={isDisableTaux}
                      >
                        {tauxHoraires?.map((tauxHoraire, i) => (
                          <option key={i} value={tauxHoraire}>
                            {tauxHoraire}
                          </option>
                        ))}
                      </select>
                    ) : null}
                  </td>
                  <td className="flex items-center justify-center">
                    {Object.keys(dayTimes).length !== 0 ? (
                      <button
                        className="w-full block"
                        onClick={() =>
                          openModal(
                            <AddComment
                              closeModal={closeModal}
                              setCommentsForSend={setCommentsForSend}
                            />
                          )
                        }
                      >
                        <img
                          src={plus}
                          alt="more"
                          className="block pt-2 ml-2"
                        />
                      </button>
                    ) : null}
                  </td>
                  {days7.map((_day, colIndex) => (
                    <td
                      key={colIndex}
                      className={`text-center h-full ${
                        colIndex === 0
                          ? "border-l-[1px] border-black"
                          : "border-[1px]"
                      } `}
                    >
                      <input
                        type="number"
                        className={`w-14 h-10 text-center ${rowColor}`}
                        placeholder="0"
                        min="0"
                        onBlur={(e) =>
                          handleInputChange(colIndex, e.target.value)
                        }
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
            {/* This is for display times to update*/}
            {dataWeekSummary
              .slice()
              .sort((a: any, b: any) => {
                const aIsHighlighted =
                  timeId !== 0 &&
                  a.Times.some((time: any) => timeId === time.ANA_REF);
                const bIsHighlighted =
                  timeId !== 0 &&
                  b.Times.some((time: any) => timeId === time.ANA_REF);

                if (aIsHighlighted && !bIsHighlighted) {
                  return -1;
                }

                if (!aIsHighlighted && bIsHighlighted) {
                  return 1;
                }

                return 0;
              })
              .map((item: any, i: number) => {
                const rowColor = i % 2 === 0 ? "bg-bc-lightGrey" : "bg-white";
                const isHighlighted =
                  timeId !== 0 &&
                  item.Times.some((time: any) => timeId === time.ANA_REF);
                return (
                  <tr
                    key={i}
                    className={`w-full h-10 border-b-[1px] ${
                      isHighlighted ? "bg-orange-100" : rowColor
                    } text-sm`}
                  >
                    <td className="w-8 pt-1">
                      {deleteTime === 0 ? (
                        <button onClick={() => handleDeleteTime(i)}>
                          <img src={trash} alt="poubelle" className="w-5 h-5" />
                        </button>
                      ) : null}
                    </td>
                    <td className="text-start pl-1">{item.DOS_TITRE}</td>
                    <td className="text-start">{item.MIS_LIBELLE}</td>
                    <td className="text-start">{item.AOR_LIBELLE}</td>
                    <td className="text-start">{item.ANA_LIBELLELIB}</td>
                    <td className="text-center">{item.ANA_EPRIXUNITAIRE}</td>
                    <td className="w-10 h-full">
                      <button
                        className="w-10 h-full flex justify-center items-center"
                        onClick={() => {
                          openModal(
                            <UpdateComment
                              closeModal={closeModal}
                              commentsToUpdate={item.Times}
                              setRenderData={setRenderData}
                            />
                          );
                        }}
                      >
                        <img src={plus} alt="more" className="block" />
                      </button>
                    </td>

                    {item.Times.map((time: any, j: any) => (
                      <td
                        key={j}
                        className={`text-center h-full ${
                          j === 0
                            ? "border-l-[1px] border-black"
                            : "border-[1px]"
                        } `}
                      >
                        {time.validated ? (
                          <div className="w-full h-10 bg-green-Array flex border-0 justify-center items-center">
                            <p className="text-slate-400 mr-4">{time.time}</p>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center relative">
                            {time.ANA_COMPLEMENTLIB !== "" ? (
                              <div
                                className="absolute top-0 left-0 rounded-full bg-[#f7e0a5] w-2 h-2 "
                                onMouseEnter={(e) =>
                                  handleMouseEnter(time?.ANA_COMPLEMENTLIB, e)
                                }
                                onMouseLeave={handleMouseLeave}
                              ></div>
                            ) : null}
                            <input
                              type="number"
                              min="0"
                              value={time.time === 0 ? "" : time.time}
                              className={`w-14 h-auto text-center ${
                                isHighlighted ? "bg-orange-100" : rowColor
                              }`}
                              placeholder={time.time === 0 ? "" : time.time}
                              onChange={(e) => {
                                setIsTyping(true);
                                handleItemTimeChange(
                                  i,
                                  j,
                                  days7[j].fullDate,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        )}
                        {tooltipState.content && (
                          <div
                            className="tooltip bg-bc-darkGrey text-white p-2 rounded-md text-sm absolute z-50 w-auto max-w-[200px] h-auto"
                            style={{
                              top: tooltipState.position.y,
                              left: tooltipState.position.x,
                            }}
                          >
                            {tooltipState.content}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EditView;
