import React, { useEffect } from "react";
import { usePagination } from "../../../hooks/usePagination";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import CreateCashInFlow from "../modal/Create/CreateCashInFlow";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import { useLoadingStore } from "../../../store/utils/loading";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import {
  dateFormattedFr,
  dateFormattedWithNullAlorsEnfaiteCestPasUnNullMaisUnStringVideDeso,
} from "../../../utils/TimeManager/timeUtils";
import { formatNumber } from "../../../utils/functionService";

/**
 * @Component CashInFlowTable
 * @author Succi Iris
 * @date 2023
 * @description
 * Ce composant affiche un tableau des flux de trésorerie, permettant aux utilisateurs de les sélectionner et de les valider. Il gère également la pagination et la fonctionnalité de recherche.
 */
const CashInFlowTable = () => {
  const { cashInFlows, cashInFlowFilters, setCashInFlowFilters } =
    dataInvoicesFeelsStore();
  const { search } = useSearchBarStore();
  const { isLoading } = useLoadingStore();
  const { displayDiv } = useCreateInvoiceStore();
  const { selectedIdsInvoices, setSelectedIdsInvoices, setIsPointedOut } =
    useCreateInvoiceStore();
  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(cashInFlowFilters, 30);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, itemId]);
    } else {
      setSelectedIdsInvoices((prev) => prev.filter((id) => id !== itemId));
    }

    if (selectedIdsInvoices.length === 0) {
      setIsPointedOut(false);
    } else {
      setIsPointedOut(true);
    }
  };

  useEffect(() => {
    let data =
      cashInFlows.length > 0 ? [...cashInFlows] : [...cashInFlowFilters];

    if (search !== "") {
      data = data.filter(
        (item) =>
          (item["FIC_TITRE_ETAT"] &&
            item["FIC_TITRE_ETAT"]
              .toLowerCase()
              .includes(search.toLowerCase())) ||
          dateFormattedFr(item["ATR_DATE_ENCAISSEMENT"])
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item["ATR_EMTTTC"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      setCashInFlowFilters(data);
    }

    // Trie par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.ATR_DATE_ENCAISSEMENT).getTime();
      const dateB = new Date(b.ATR_DATE_ENCAISSEMENT).getTime();
      return dateB - dateA; // Trie du plus récent au plus ancien
    });
    setCashInFlowFilters(data);
  }, [search, cashInFlows]);

  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : cashInFlows.length > 0 ? (
          <div className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll">
            <table className="w-full">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0 text-sm h-10">
                <tr className="w-[2%] border-r-[1px] flex justify-start items-center ">
                  {" "}
                </tr>
                <tr className="w-[10%] border-r-[1px] p-2">Dossier</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Date</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Montant</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Solde</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Libellé cpt</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Bq émission</tr>
                <tr className="w-[15%] border-r-[1px] p-2">Bq encaissement</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Mode paiement</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Encaissement</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Pièce</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Bordereau</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Type de piece</tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs">
                {filteredData?.map((cashInFlow, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex ${
                      cashInFlow?.Pointé === 0 && !cashInFlow?.impaye
                        ? "bg-bc-orange-pale"
                        : cashInFlow?.impaye === true
                        ? "bg-bc-red-pale"
                        : cashInFlow?.Pointé === 1 &&
                          cashInFlow.ATR_ESOLDE === 0 &&
                          !cashInFlow.impaye
                        ? "bg-bc-green-pale"
                        : ""
                    }`}
                  >
                    <td className="w-[2%] border-r-[1px] flex justify-start items-start pt-2">
                      {cashInFlow?.impaye === true ? (
                        <></>
                      ) : (
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="mx-1"
                          value={cashInFlow.ATR_REF}
                          checked={selectedIdsInvoices.includes(
                            cashInFlow.ATR_REF
                          )}
                          onChange={(e) => {
                            handleCheckboxChange(e, cashInFlow.ATR_REF);
                            if (cashInFlow.Pointé === 1) {
                              setIsPointedOut(true);
                            }
                          }}
                        />
                      )}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cashInFlow?.FIC_TITRE_ETAT}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {dateFormattedFr(cashInFlow?.ATR_DATE_ENCAISSEMENT)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {formatNumber(cashInFlow?.ATR_EMTTTC)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {formatNumber(cashInFlow?.ATR_ESOLDE)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cashInFlow?.ATR_DEBITEUR}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cashInFlow?.ATR_BANQUE_EMISSION}
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {cashInFlow?.ABA_LIBELLE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cashInFlow?.AMR_LIBELLE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2"></td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cashInFlow?.ATR_PIECE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {dateFormattedWithNullAlorsEnfaiteCestPasUnNullMaisUnStringVideDeso(
                        cashInFlow?.ATR_DATE_BORDEREAU
                      )}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {cashInFlow?.Type_piece}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center w-full mt-5 italic">Aucun encaissement</p>
        )}
        {displayDiv ? <CreateCashInFlow /> : null}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default CashInFlowTable;
