import { useEffect } from "react";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import {
  getPrepaymentById,
  postCheckPrepayment,
} from "../../../../utils/Invoice/prepayments";
import { usePointAcompteStore } from "../../../../store/Invoice/pointAcompte";
import { getInvoicesCheckablesForPrepayment } from "../../../../utils/customerFile";
import { dateFormattedFr } from "../../../../utils/TimeManager/timeUtils";

/**
 * @component
 * @name PointAcompte
 * @author Succi Iris
 * @date 2023
 * @description Pointer un acompte
 * @returns {JSX.Element} - The rendered component.
 */
const PointAcompte = () => {
  const { displayPointOutAcompteModal, setDisplayPointOutAcompteModal } =
    useCreateInvoiceStore();
  const {
    animationClass,
    setAnimationClass,
    setSelectedIdsInvoices,
    selectedIdPrepayment,
    selectedDosRef,
  } = useCreateInvoiceStore();
  const { poitOutAcompte, setPointOutAcompte } = useCreateFeesOrInvoices();
  const {
    prepayment,
    setPrepayment,
    invoicesCheckables,
    setInvoicesCheckables,
    setSelectedIdsInvoicesForPoint,
    selectedInvoicesToAllocate,
    setSelectedInvoicesToAllocate,
    montantRestant,
    setMontantRestant,
  } = usePointAcompteStore();

  /**
   * @function
   * @description Gestion de l'affichage du modal de pointer un acompte
   */
  useEffect(() => {
    if (poitOutAcompte === true) {
      setDisplayPointOutAcompteModal(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayPointOutAcompteModal(false);
      }, 300);
    }
  }, [poitOutAcompte]);

  /**
   * @description Récupération des informations de l'acompte et des factures
   */
  useEffect(() => {
    getPrepaymentById(selectedIdPrepayment)
      .then((res: any) => {
        setPrepayment(res.data[0]);
      })
      .catch((err: any) => console.log(err));

    getInvoicesCheckablesForPrepayment(selectedDosRef)
      .then((res: any) => {
        setInvoicesCheckables(res.data);
      })
      .catch((err: any) => console.log(err));
  }, [selectedIdPrepayment, selectedDosRef]);

  /**
   * @function
   * @name handleReset
   * @description Reset state
   */
  const handleReset = () => {
    setSelectedIdsInvoices((_prev) => []);
    setPointOutAcompte(false);
    setSelectedIdsInvoicesForPoint((_prev) => []);
    setDisplayPointOutAcompteModal(false);
    setSelectedInvoicesToAllocate([]);
  };

  /**
   * @function
   * @name handleCheck
   * @description Pointer un acompte
   * @author Succi Iris
   * @date 2023
   */
  const handleCheck = () => {
    postCheckPrepayment(selectedIdPrepayment)
      .then(() => handleReset())
      .catch((err: any) => console.log(err));
  };

  const handleInvoiceSelection = (invoice: any) => {
    const montantARegler = Math.min(
      invoice.INITIAL - invoice.Réglé,
      montantRestant
    );
    const updatedInvoices = selectedInvoicesToAllocate.slice();
    const foundIndex = updatedInvoices.findIndex(
      (inv) => inv.ANA_REF === invoice.ANA_REF
    );

    if (foundIndex !== -1) {
      // la logique de désélection
      const montantDejaRegle = updatedInvoices[foundIndex].Réglé;
      updatedInvoices.splice(foundIndex, 1);
      setMontantRestant(montantRestant + montantDejaRegle);
    } else {
      // Si la facture n'est pas sélectionnée, l'ajoute avec le montant réglé mis à jour
      updatedInvoices.push({
        ...invoice,
        Réglé: invoice.Réglé + montantARegler,
        Solde: invoice.INITIAL - (invoice.Réglé + montantARegler),
        Saisie: montantARegler,
      });
      setMontantRestant(montantRestant - montantARegler);
    }
    setSelectedInvoicesToAllocate(updatedInvoices);
  };

  const isInvoiceSelected = (invoiceId: any) => {
    return selectedInvoicesToAllocate.some(
      (invoice) => invoice.ANA_REF === invoiceId
    );
  };

  return (
    <>
      {displayPointOutAcompteModal ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-center h-full w-full">
            <button
              className="h-full w-10 bg-gray-600 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => {
                setSelectedIdsInvoices((_prev) => []);
                setPointOutAcompte(false);
              }}
            >
              {/* <img src={chevron} alt="fleche" /> */}
            </button>
            <div className="flex flex-col justify-start items-start w-11/12 h-[90%] m-auto">
              <div className="flex justify-center items-center w-full">
                <h3 className="font-bold text-xl mb-8">Pointer un acompte</h3>
              </div>
              <div className="w-11/12 m-auto grid grid-cols-6 gap-4">
                <p className="col-start-1 col-end-3">
                  Dossier :{" "}
                  <span className="font-bold">{prepayment?.DOS_TITRE}</span>
                </p>
                <p className="col-start-4 col-end-7">
                  Client :{" "}
                  <span className="font-bold">
                    {prepayment?.FIC_TITRE_CLIENT}
                  </span>
                </p>

                {prepayment?.ABA_LIBELLE !== "" ? (
                  <p className="col-start-1 col-end-3">
                    Libellé :{" "}
                    <span className="font-bold">{prepayment?.ABA_LIBELLE}</span>
                  </p>
                ) : null}
                <p className="col-start-4 col-end-7">
                  Date d'encaissement :{" "}
                  <span className="font-bold">
                    {dateFormattedFr(prepayment?.ANA_DATEECHEANCE)}
                  </span>
                </p>
                <p className="col-start-1 col-end-3">
                  Moyen de paiment :{" "}
                  <span className="font-bold">{prepayment?.AMR_LIBELLE}</span>
                </p>
                <p className="col-start-4 col-end-7">
                  Montant TTC :{" "}
                  <span className="font-bold">{prepayment?.ANA_EMTTTC} €</span>
                </p>
                <p className="col-start-1 col-end-3">
                  Pièce :{" "}
                  <span className="font-bold">{prepayment?.ATR_PIECE}</span>
                </p>
                <p className="col-start-4 col-end-7">
                  Banque emission :{" "}
                  <span className="font-bold">
                    {prepayment?.ATR_BANQUE_EMISSION}
                  </span>
                </p>
              </div>
              <hr className="w-1/2 my-8 m-auto" />
              <table className="w-full border-[1px] bg-white h-3/6 overflow-y-scroll">
                <thead className="border-b-[1px] border-bc-grey flex w-full text-sm font-bold sticky top-0 bg-slate-100">
                  <tr className="w-[2%] border-r-[1px] flex justify-center items-center"></tr>
                  <tr className="w-[10%] border-r-[1px] p-2">N</tr>
                  <tr className="w-[10%] border-r-[1px] p-2">Date</tr>
                  <tr className="w-[10%] border-r-[1px] p-2">Initial</tr>
                  <tr className="w-[10%] border-r-[1px] p-2">Réglé</tr>
                  <tr className="w-[10%] border-r-[1px] p-2">Solde</tr>
                  <tr className="w-[10%] border-r-[1px] p-2">Saisie</tr>
                  <tr className="w-[10%] border-r-[1px] p-2">P</tr>
                  <tr className="w-[18%] border-r-[1px] p-2">Titre dos</tr>
                  <tr className="w-[10%] border-r-[1px] p-2">Libellé</tr>
                </thead>
                <tbody className="flex flex-col w-full text-xs ">
                  {invoicesCheckables?.map((invoice: any) => {
                    const selectedInvoice = selectedInvoicesToAllocate.find(
                      (inv) => {
                        return inv.ANA_REF === invoice.ANA_REF;
                      }
                    );
                    return (
                      <tr
                        key={invoice.id}
                        className="border-b border-bc-grey w-full flex"
                      >
                        <td className="w-[2%] border-r-[1px] flex justify-center items-center">
                          <input
                            type="checkbox"
                            className="w-4 h-4"
                            onChange={() => handleInvoiceSelection(invoice)}
                            checked={isInvoiceSelected(invoice.ANA_REF)}
                          />
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {invoice.ANA_FACTURE}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {dateFormattedFr(invoice.ANA_DATEREALISATION)}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {invoice.INITIAL}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {selectedInvoice
                            ? selectedInvoice?.Réglé
                            : invoice?.Réglé}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {selectedInvoice
                            ? selectedInvoice?.Solde
                            : invoice?.ANA_ESOLDE}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {selectedInvoice
                            ? selectedInvoice?.Saisie
                            : invoice?.Saisie}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {invoice.P}
                        </td>
                        <td className="w-[18%] border-r-[1px] p-2">
                          {invoice.DOS_TITRE}
                        </td>
                        <td className="w-[10%] border-r-[1px] p-2">
                          {invoice.ANA_LIBELLELIB}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="w-11/12 m-auto flex justify-end items-center mt-4">
                <button
                  className="w-40 h-8 mr-4 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md"
                  onClick={handleReset}
                >
                  Annuler
                </button>
                <button
                  className="w-40  h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                  onClick={handleCheck}
                >
                  Pointer
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PointAcompte;
