import { useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/icons/logo-bc.png";
import profile from "../../assets/icons/deconnexion.png";
import IncrementDate from "../Utils/dateManagement/IncrementDate";
import Datepicker from "../Utils/dateManagement/DatePicker";
import TodayButton from "../Utils/dateManagement/TodayButton";
import EditButton from "../TimeManager/buttons/EditButton";
import ValidationOrAnnulationButtons from "../TimeManager/buttons/ValidationOrAnnulationButtons";
import SelectLang from "./SelectLang";
import Logout from "../Utils/Logout";
import SelectUser from "../TimeManager/selects/SelectUser";
import { useUserContext } from "../../context/UserContext";
import { useRightTimeContext } from "../../context/RightTimeContext";
import { useViewStore } from "../../store/TimeManagement/viewStore";
import ValidateTime from "../TimeManager/buttons/ValidateTime";

/**
 * @page
 * @name Header
 * @description
 * Le header est la barre qui se situe en haut de l'application. Il contient le logo, les boutons de navigation, les boutons de validation et d'édition des temps, le bouton de déconnexion, la selection du user et le bouton de sélection de la langue.
 * @author Iris Succi
 * @date 2023
 */
const Header: React.FC<any> = ({
  openModal = () => {},
  closeModal = () => {},
  user = "",
  setUser = () => {},
  setValidatedSendTime = () => {},
  setRenderData = () => {},
}) => {
  // Context
  const { id } = useUserContext();
  const { visuLimit, timeEntry, validatedTime } = useRightTimeContext();
  const { alias } = useParams();
  // Hook
  const navigate = useNavigate();
  const location = window.location.pathname;

  // Store
  const { view, editMode } = useViewStore();

  return (
    <header className="w-full m-auto flex h-20 justify-between items-center border-b-[1px] border-slate-200 pt-2 bg-bc-lightGrey">
      <button
        className=" border-red-50 ml-1 pb-2"
        onClick={() => navigate(`/${alias}/home`)}
      >
        <img src={logo} alt="logo" className="w-10 h-10" />
      </button>
      <div className="flex">
        {location.includes("/timemanagement") ? (
          <div className="flex">
            <IncrementDate />
            <Datepicker />
            <TodayButton />
            {view === "edit" && editMode ? (
              <ValidationOrAnnulationButtons
                setValidatedSendTime={setValidatedSendTime}
                user={user}
                setRenderData={setRenderData}
                openModal={openModal}
                closeModal={closeModal}
              />
            ) : (
              <>
                {timeEntry === 1 ? <EditButton /> : null}
                {validatedTime === 1 && view === "list" ? (
                  <ValidateTime setRenderData={setRenderData} />
                ) : null}
              </>
            )}
          </div>
        ) : null}
      </div>
      <div className="flex justify-center items-center pb-2">
        {location === "/timemanagement" ||
        (location === "/costs" && visuLimit === 0) ? (
          <div className="lg:mr-4 flex ">
            <SelectUser setUser={setUser} />
          </div>
        ) : null}
        <div className="lg:mr-4 xl:flex hidden">
          <SelectLang />
        </div>
        <p className="text-bc-orange bold xl:flex hidden">{id}</p>
        <button
          onClick={() => {
            openModal(<Logout closeModal={closeModal} />);
          }}
        >
          <img
            src={profile}
            alt="profile"
            className="w-5 h-5 lg:ml-5 lg:mr-10 mr-2 rotate-180 "
          />
        </button>
      </div>
    </header>
  );
};

export default Header;
