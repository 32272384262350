import { useIntervenantStore } from "../../../store/Crm/Intervenant";
import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";

/**
 * @component
 * @name CreateIntervenant
 * @description CreateIntervenant est un composant qui permet d'afficher la modal pour créer une fiche.
 * @author Iris Succi
 * @date 2023
 */
const CreateIntervenant = () => {
  const { setChoiceTab } = useUtilsCrmStore();
  const { setChoiceCreate } = useIntervenantStore();

  const handleCreate = () => {
    setChoiceCreate("CreateIntervenant");
    setChoiceTab("Intervenant");
  };

  return (
    <button
      className="px-2 my-2 w-44 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer"
      onClick={handleCreate}
    >
      Créer un participant
    </button>
  );
};

export default CreateIntervenant;
