const QuickWarning2 = () => {
  return (
    <div className="w-full relative ">
    <h2 className="w-full border-2 bg-violet-400 border-violet-400 rounded-md text-white text-center absolute -top-3">
      QUICK WARNING
    </h2>
    <div className="w-full border-2 border-md border-bc-orange rounded-b-md ">
      <div className="w-8/12 h-20 flex justify-between items-center m-auto mt-5">
        <p className="text-black">Buro.Secure : 4</p>
        <p className="text-black">Buro.Sign : 5</p>
      </div>
    </div>
  </div>
  );
};

export default QuickWarning2;
