import { useEffect } from "react";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import { useLoadingStore } from "../../../../store/utils/loading";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import chevron from "../../../../assets/icons/chevron-right.svg";
import Select from "react-select";
import { getAllDossiers } from "../../../../utils/folders";
import { getClientsByDOS } from "../../../../utils/customerFile";
import {
  getCollectingBanks,
  getPaymentMethod,
} from "../../../../utils/Invoice/prepayments";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import {
  allocateCashInFlow,
  getCashInFlow,
  postCashInFlow,
  unallocateCashInFlow,
  updateCashInFlow,
} from "../../../../utils/Invoice/cashInFlow";
import {
  errorFetch,
  errorSendNewTime,
  successCashInFlow,
  successUpdateData,
} from "../../../../utils/toaster";
import { useCreateCashInFlowStore } from "../../../../store/Invoice/createCashInFlowStore";
import PointCashInFlow from "../Pointer/PointCashInFlow";

/**
 * @component
 * @name CreateCashInFlow
 * @description Création d'un encaissement
 * @author IrisSucci
 * @date 2023
 */
const CreateCashInFlow = () => {
  // Store
  const { createCashInFlow, setCreateCashInFlow } = useCreateFeesOrInvoices();
  const { setIsLoading } = useLoadingStore();
  const { setChoiceTab, sendData, setSendData } = useTabsStore();
  const {
    clientsFile,
    setClientsFile,
    selectedFile,
    setSelectedFile,
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
    modeCashInFlow,
    dataCashInFlow,
    setDataCashInFlow,
    banqueEncaissement,
    setBanqueEncaissement,
    selectedBanqueEncaissement,
    setSelectedBanqueEncaissement,
    startDate,
    setStartDate,
    paymentMethod,
    setPaymentMethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    montant,
    setMontant,
    emissionBanque,
    setEmissionBanque,
    piece,
    setPiece,
    libelleCpt,
    setLibelleCpt,
    cashInFlowData,
    setCashInFlowData,
    selectedInvoicesToAllocate,
    setSelectedInvoicesToAllocate,
    setInvoicesToAllocate,
    invoicesAllocated,
    setMontantRestant,
    setInvoiceAlreadyDue,
    setModeCashInFlow,
    setInvoicesAllocated,
  } = useCreateCashInFlowStore();
  const {
    animationClass,
    setAnimationClass,
    displayDiv,
    setDisplayDiv,
    selectedIdsInvoices,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();

  /**
   * @function
   * @name useEffect
   * @description Gere l'affichage du formulaire de création
   */
  useEffect(() => {
    if (createCashInFlow === true) {
      setDisplayDiv(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDiv(false);
      }, 300);
    }
  }, [createCashInFlow]);

  // Recuperation des dossiers
  useEffect(() => {
    getAllDossiers()
      .then((res) => {
        const sortedData = res.data.sort((a: any, b: any) =>
          a.FIC_TITRE_CLIENT.localeCompare(b.FIC_TITRE_CLIENT)
        );
        const formattedFiles = sortedData.map((file: any) => ({
          value: file.DOS_REF,
          label: file.FIC_TITRE_CLIENT,
        }));
        setClientsFile(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, []);

  /**
   * @description Call api pour recuperer les clients par dossier, les banques d'encaissement et les modes de paiement
   */
  useEffect(() => {
    if (selectedFile !== 0) {
      getClientsByDOS(selectedFile)
        .then((res) => {
          const formattedFiles = res.data.map((file: any) => ({
            value: file.FIC_REF,
            label: file?.DOS_CODE + " - " + file?.FIC_TITRE_ETAT,
          }));
          setClients(formattedFiles);

          setSelectedClient(formattedFiles[0]?.value || 0);
          setDataCashInFlow("FIC_REF", formattedFiles[0]?.value || 0);
        })
        .catch((err) => console.log(err));
    }

    getCollectingBanks()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.ABA_REF,
          label: file.ABA_LIBELLE,
        }));
        setBanqueEncaissement(formattedFiles);
      })
      .catch((err) => console.log(err));

    getPaymentMethod()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.AMR_REF,
          label: file.AMR_LIBELLE,
        }));
        setPaymentMethod(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, [displayDiv, selectedFile]);

  /**
   * @function
   * @name handleCreateCashInFlow
   * @description Créer un encaissement
   * @author Iris Succi
   * @date 2024
   */
  const handleCreateCashInFlow = async () => {
    setIsLoading(true);

    const data = {
      DOS_REF: selectedFile,
      ABA_REF: selectedBanqueEncaissement,
      ATR_EMTTTC: montant,
      ATR_DATE_ENCAISSEMENT: startDate,
      ATR_MODE_REGLEMENT: selectedPaymentMethod,
      FIC_REF: selectedClient,
      ATR_PIECE: piece,
      ATR_BANQUE_EMISSION: emissionBanque,
      LIBELLE_CPT: libelleCpt,
    };

    try {
      setDisplayDiv(false);
      const response = await postCashInFlow(data);
      const atrRef = response.data;

      if (selectedInvoicesToAllocate.length > 0) {
        // Extraction des ANA_REF
        const anaRefs = selectedInvoicesToAllocate.map(
          (invoice) => invoice.ANA_REF
        );

        // Création du payload pour allocateCashInFlow
        const allocateData = { ANA_REFs: anaRefs };
        // Appel de allocateCashInFlow
        await allocateCashInFlow(atrRef, allocateData)
          .then(() => {
            setSendData((prev) => !prev);
            setIsLoading(false);
            setInvoiceAlreadyDue([]);
          })
          .catch((error) => {
            console.error(
              "Erreur lors de l'allocation des factures à l'encaissement",
              error
            );
            errorFetch();
            setIsLoading(false);
          });
      }

      console.log(response);
      reinitializeFields();
      setCreateCashInFlow(false);
      successCashInFlow();
      setIsLoading(false);
      setSendData((prev) => !prev);
      setInvoicesAllocated([]);
      setInvoicesToAllocate([]);
      setSelectedInvoicesToAllocate([]);
      setChoiceTab("Encaissement");
    } catch {
      console.log("error");
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /************************ EDIT MODE ***********************/
  useEffect(() => {
    if (modeCashInFlow === "edit") {
      try {
        getCashInFlow(selectedIdsInvoices[0])
          .then((res) => {
            const data = res.data;
            setCashInFlowData(data);
            setInvoiceAlreadyDue(data.Pointé);
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
    }
  }, [clientsFile, sendData]);

  useEffect(() => {
    if (modeCashInFlow === "edit") {
      try {
        if (clientsFile.length > 0) {
          const selectedFileObj = clientsFile.find(
            (file) => file.value === cashInFlowData?.DOS_REF
          );
          setSelectedFile(selectedFileObj?.value || 0);
        }
        setEmissionBanque(
          cashInFlowData?.ATR_BANQUE_EMISSION !== null
            ? cashInFlowData?.ATR_BANQUE_EMISSION
            : ""
        );

        setLibelleCpt(
          cashInFlowData?.LIBELLE_CPT !== null
            ? cashInFlowData?.LIBELLE_CPT
            : ""
        );

        const selectedBanqueEncaissementObj = banqueEncaissement.find(
          (banque) => banque.value === cashInFlowData.ABA_REF
        );
        setSelectedBanqueEncaissement(
          selectedBanqueEncaissementObj?.value || 0
        );

        const selectedPaymentMethodObj = paymentMethod?.find(
          (cashInFlow: any) =>
            cashInFlow.value === cashInFlowData.ANA_MODE_REGLEMENT
        );
        setSelectedPaymentMethod(selectedPaymentMethodObj?.value || 0);

        setPiece(
          cashInFlowData?.ATR_PIECE !== null ? cashInFlowData?.ATR_PIECE : ""
        );

        setMontant(
          cashInFlowData.ATR_EMTTTC !== null ? cashInFlowData.ATR_EMTTTC : 0
        );

        setStartDate(
          cashInFlowData.ATR_DATE_ENCAISSEMENT !== null
            ? new Date(cashInFlowData.ATR_DATE_ENCAISSEMENT)
            : new Date()
        );
      } catch (err) {
        console.log(err);
      }
    }
  }, [modeCashInFlow, cashInFlowData]);

  /**
   * @function
   * @name handleUpdateCashInFlow
   * @description Modifie un encaissement
   * @author Iris Succi
   * @date 2024
   */
  const handleUpdateCashInFlow = async () => {
    const cashInFlowDataUpdated = {
      DOS_REF: selectedFile,
      ABA_REF: selectedBanqueEncaissement,
      ATR_EMTTTC: montant,
      ATR_DATE_ENCAISSEMENT: startDate,
      ATR_MODE_REGLEMENT: selectedPaymentMethod,
      FIC_REF: selectedClient,
      ATR_PIECE: piece,
      ATR_BANQUE_EMISSION: emissionBanque,
      LIBELLE_CPT: libelleCpt,
    };
    try {
      if (cashInFlowData?.ATR_REF) {
        const atrRef = cashInFlowData?.ATR_REF;
        const response = await updateCashInFlow(atrRef, cashInFlowDataUpdated);

        if (selectedInvoicesToAllocate.length > 0) {
          // Extraction des ANA_REF
          const anaRefs = selectedInvoicesToAllocate.map(
            (invoice) => invoice.ANA_REF
          );

          // Création du payload pour allocateCashInFlow
          const allocateData = { ANA_REFs: anaRefs };
          // Appel de allocateCashInFlow
          await allocateCashInFlow(atrRef, allocateData);
        }

        console.log(response);
        reinitializeFields();
        setSendData((prev) => !prev);
        setChoiceTab("Encaissement");
        setCreateCashInFlow(false);
        setModeCashInFlow("create");
        setSelectedIdsInvoices((_prev) => []);
        successUpdateData();
      }
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name handleUnallocateCashInFlow
   * @description Dépointe un encaissement d'une facture déjà pointée
   * @author Iris Succi
   * @date 2024
   */
  const handleUnallocateCashInFlow = async () => {
    setIsLoading(true);
    if (cashInFlowData?.ATR_REF) {
      unallocateCashInFlow(cashInFlowData?.ATR_REF)
        .then(() => {
          setSendData((prev) => !prev);
          setIsLoading(false);
          setInvoiceAlreadyDue([]);
        })
        .catch((error) => {
          console.error("Erreur lors de la désallocation de la facture", error);
          errorFetch();
          setIsLoading(false);
        });
    }
  };

  /**
   * @function
   * @name reinitializeFields
   * @description Reinitialise les champs du formulaire de création d'un encaissement
   * @author Iris Succi
   * @date 2024
   */
  const reinitializeFields = () => {
    setSelectedFile(0);
    setSelectedClient(0);
    setSelectedBanqueEncaissement(0);
    setSelectedPaymentMethod(0);
    setMontant(0);
    setEmissionBanque("");
    setPiece("");
    setLibelleCpt("");
    setSelectedInvoicesToAllocate([]);
    setMontantRestant(0);
    setInvoicesToAllocate([]);
    // Reset dataCashInFlow
    setDataCashInFlow("DOS_REF", 0);
    setDataCashInFlow("FIC_REF", 0);
    setDataCashInFlow("ABA_REF", 0);
    setDataCashInFlow("ANA_MODE_REGLEMENT", 0);
    setDataCashInFlow("ATR_EMTTTC", 0);
    setDataCashInFlow("ATR_DATE_ENCAISSEMENT", new Date());
    setDataCashInFlow("ATR_PIECE", "");
    setDataCashInFlow("ATR_BANQUE_EMISSION", "");
    setDataCashInFlow("LIBELLE_CPT", "");

    setInvoiceAlreadyDue([]);
    setInvoicesToAllocate([]);
    setSelectedInvoicesToAllocate([]);
    setPaymentMethod([]);
    setBanqueEncaissement([]);
    setClients([]);
    setClientsFile([]);
    setInvoicesAllocated([]);
    setStartDate(new Date());
    setInvoicesToAllocate([]);
    setSelectedInvoicesToAllocate([]);
  };

  return (
    <>
      {displayDiv ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10 overflow-y-scroll`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-center h-full w-full">
            <button
              className="h-full w-10 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => setCreateCashInFlow(false)}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-start items-start w-[80%] h-[90%] m-auto mr-10">
              <div className="flex justify-center items-center w-full">
                <p className="font-bold text-lg mb-2 mr-20">
                  {modeCashInFlow === "create"
                    ? "Création d'un encaissement"
                    : "Modifier un encaissement"}
                </p>
              </div>
              <div className="flex flex-col justify-center items-start w-full h-full m-auto">
                <div className="grid grid-cols-7 w-full m-auto gap-2">
                  <div className="col-start-1 col-end-2 mt-1">Dossier :</div>
                  <div className="flex col-start-2 col-end-4">
                    {modeCashInFlow === "edit" ? (
                      <Select
                        options={clientsFile}
                        isClearable
                        className="w-full h-9 z-40"
                        isSearchable
                        value={
                          modeCashInFlow === "edit"
                            ? clientsFile.find(
                                (option) => option.value === selectedFile
                              )
                            : null
                        }
                        onChange={(selectedOption) => {
                          setSelectedFile(selectedOption?.value || 0);
                          setDataCashInFlow(
                            "DOS_REF",
                            selectedOption?.value || 0
                          );
                        }}
                      />
                    ) : (
                      <Select
                        options={clientsFile}
                        isClearable
                        className="w-full h-9 z-50"
                        isSearchable
                        onChange={(selectedOption) => {
                          setSelectedFile(selectedOption?.value || 0);
                          setDataCashInFlow(
                            "DOS_REF",
                            selectedOption?.value || 0
                          );
                        }}
                      />
                    )}
                  </div>
                  <div className="col-start-5 col-end-6 mt-1">Client :</div>
                  <div className="flex flex-col col-start-6 col-end-8">
                    {modeCashInFlow === "edit" ? (
                      <Select
                        options={clients}
                        isClearable
                        className="w-full h-9 z-50"
                        isSearchable
                        value={
                          modeCashInFlow === "edit"
                            ? clients.find(
                                (option) => option.value === selectedClient
                              )
                            : null
                        }
                        onChange={(selectedOption) => {
                          setSelectedClient(selectedOption?.value || 0);
                          setDataCashInFlow(
                            "FIC_REF",
                            selectedOption?.value || 0
                          );
                        }}
                      />
                    ) : (
                      <Select
                        options={clients}
                        isClearable
                        className="w-full h-9 z-50"
                        isSearchable
                        value={
                          dataCashInFlow.FIC_REF !== null
                            ? clients.find(
                                (option) =>
                                  option.value === dataCashInFlow.FIC_REF
                              )
                            : null
                        }
                        onChange={(selectedClient: any | null) => {
                          setSelectedClient(selectedClient?.value || 0);
                          setDataCashInFlow(
                            "FIC_REF",
                            selectedClient?.value || 0
                          );
                        }}
                      />
                    )}
                  </div>
                  <div className="col-start-1 col-end-2 ">
                    Banque encaissement:
                  </div>
                  <div className="flex flex-col col-start-2 col-end-4">
                    <select
                      name="banqueEncaissement"
                      id="banqueEncaissement"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                      value={selectedBanqueEncaissement}
                      onChange={(event) => {
                        setSelectedBanqueEncaissement(
                          parseInt(event.target.value)
                        );
                      }}
                    >
                      <option value=""></option>
                      {banqueEncaissement?.map((banque, i) => (
                        <option key={i} value={banque?.value}>
                          {banque?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-start-5 col-end-6 mt-1">
                    Date encaissement :
                  </div>
                  <div className="flex flex-col col-start-6 col-end-8 z-40">
                    <Datepicker
                      startDate={startDate}
                      setStartDate={setStartDate}
                    />
                  </div>
                  <div className="col-start-1 col-end-2">
                    Mode de paiement :
                  </div>
                  <div className="flex col-start-2 col-end-4">
                    <select
                      name="paymentMethod"
                      id="paymentMethod"
                      className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20"
                      value={selectedPaymentMethod}
                      onChange={(event) => {
                        const selected = paymentMethod.find(
                          (payment) =>
                            payment.value === parseInt(event.target.value)
                        );
                        setSelectedPaymentMethod(selected?.value || 0);
                        setDataCashInFlow(
                          "ANA_MODE_REGLEMENT",
                          selected?.value || 0
                        );
                      }}
                    >
                      <option value=""></option>
                      {paymentMethod?.map((payment, i) => (
                        <option key={i} value={payment?.value}>
                          {payment?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-start-5 col-end-6 mt-1">Montant :</div>
                  <div className="flex flex-col col-start-6 col-end-8 ">
                    <input
                      type="number"
                      name="ATR_EMTTTC"
                      id="ATR_EMTTTC"
                      value={montant}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        setMontant(value);
                        setMontantRestant(value);
                        setDataCashInFlow("ATR_EMTTTC", value);
                      }}
                      className="col-start-6 col-end-8 border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                    />
                  </div>

                  <div className="col-start-1 col-end-2 mt-1">
                    Banque émission:
                  </div>
                  <input
                    type="text"
                    name="banqueemision"
                    id="banqueemision"
                    value={emissionBanque}
                    onChange={(e) => {
                      setEmissionBanque(e.target.value);
                      setDataCashInFlow(
                        "ATR_BANQUE_EMISSION",
                        parseInt(e.target.value)
                      );
                    }}
                    /* disabled={selectedPaymentMethod !== 0} */
                    className="col-start-2 col-end-4 border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                  />

                  <div className="col-start-5 col-end-6 mt-1">Pièce : </div>
                  <input
                    type="text"
                    name="Pièce"
                    id="Pièce"
                    value={piece}
                    onChange={(e) => {
                      setPiece(e.target.value);
                      setDataCashInFlow("ATR_PIECE", parseInt(e.target.value));
                    }}
                    className="col-start-6 col-end-8 border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                  />
                  <div className="col-start-1 col-end-2 mt-1">
                    Libellé cpt :
                  </div>
                  <input
                    type="text"
                    name="libellecpt"
                    id="libellecpt"
                    value={libelleCpt}
                    onChange={(e) => {
                      setLibelleCpt(e.target.value);
                      setDataCashInFlow(
                        "LIBELLE_CPT",
                        parseInt(e.target.value)
                      );
                    }}
                    disabled={selectedPaymentMethod !== 0}
                    className="col-start-2 col-end-4 border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                  />
                </div>

                <PointCashInFlow />

                <div className="w-full flex justify-end">
                  {modeCashInFlow === "edit" && invoicesAllocated.length > 0 ? (
                    <button
                      className="w-40 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white  mt-2"
                      onClick={handleUnallocateCashInFlow}
                    >
                      Dépointer
                    </button>
                  ) : null}
                </div>

                <div className="w-full flex justify-end items-end mt-5">
                  <button
                    className="w-40 h-8 border-[1px] rounded-md bg-rose-600 text-white hover:border-rose-500 hover:bg-rose-500 mr-2"
                    onClick={() => {
                      setSelectedIdsInvoices((_prev) => []);
                      setDisplayDiv(false);
                      reinitializeFields();
                    }}
                  >
                    Annuler
                  </button>
                  <button
                    className="w-40 h-8 border-[1px] rounded-md bg-bc-green text-white hover:border-bc-lightGreen hover:bg-bc-lightGreen"
                    onClick={() => {
                      if (modeCashInFlow === "create") handleCreateCashInFlow();
                      else handleUpdateCashInFlow();
                    }}
                  >
                    Valider
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateCashInFlow;
