import { useEffect, useRef } from "react";
import { useLoadingStore } from "../../../store/utils/loading";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";
import { usePagination } from "../../../hooks/usePagination";
import { formatNumber } from "../../../utils/functionService";

/**
 * @name RelaunchTable
 * @description
 * Tableau des relances
 * @date 13/02/2024
 * @author Iris Succi
 */
const RelaunchTable = () => {
  const { isLoading } = useLoadingStore();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const { displayDiv, selectedIdsInvoices, setSelectedIdsInvoices } =
    useCreateInvoiceStore();
  const { search } = useSearchBarStore();
  const { relaunchs, relaunchsFilter, setRelaunchsFilter } =
    dataInvoicesFeelsStore();

  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(relaunchsFilter, 30);

  useEffect(() => {
    if (displayDiv && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [displayDiv]);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, itemId]);
    } else {
      setSelectedIdsInvoices((prev) => prev.filter((id) => id !== itemId));
    }
  };

  useEffect(() => {
    let data = [...relaunchs];
    
    if (search !== "") {
      data = data.filter(
        (item) =>
          item["DOS_TITRE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["FIC_TITRE_CLIENT"]
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          dateFormattedFr(item["ANA_DATESAISIE"])
            ?.toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trie par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.ANA_DATESAISIE).getTime();
      const dateB = new Date(b.ANA_DATESAISIE).getTime();
      return dateA - dateB; // Trie du plus récent au plus ancien
    });

    setRelaunchsFilter(data);
  }, [search, relaunchs]);


  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full  overflow-x-hidden">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : relaunchs.length > 0 ? (
          <div
            ref={scrollDivRef}
            className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll"
          >
            <table className="w-full">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0">
                <tr className="w-[2%] border-r-[1px] flex justify-center items-center"></tr>
                <tr className="w-[10%] border-r-[1px] p-2">N° facture</tr>
                <tr className="w-[15%] border-r-[1px] p-2">Dossier</tr>
                <tr className="w-[15%] border-r-[1px] p-2">Client</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Date création</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Date relance</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Solde</tr>
                <tr className="w-[10%] p-2">Jours</tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs">
                {filteredData?.map((invoice, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex ${
                      index % 2 === 0 ? "bg-grey-Array" : ""
                    }`}
                  >
                    <td className="w-[2%] border-r-[1px] flex justify-center items-start pt-2">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        value={invoice.id}
                        checked={selectedIdsInvoices.includes(invoice.ANA_REF)}
                        onChange={(e) =>
                          handleCheckboxChange(e, invoice.ANA_REF)
                        }
                      />
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.ANA_FACTURE}
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {invoice?.DOS_CODE} - {invoice?.DOS_TITRE}
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {invoice?.FIC_TITRE_CLIENT}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {dateFormattedFr(invoice?.ANA_DATESAISIE)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.ANA_DATERELANCE === null
                        ? ""
                        : dateFormattedFr(invoice?.ANA_DATERELANCE)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {formatNumber(invoice?.ANA_ESOLDE)}
                    </td>
                    <td className="w-[10%]  p-2">{invoice?.deltaDays} jours</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center w-full mt-5 italic">Aucune relance</p>
        )}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default RelaunchTable;
