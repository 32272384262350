import { create } from "zustand";


type SousFiche = {
    choiceTypeSousFiche: any;
    setChoiceTypeSousFiche: (choiceTypeSousFiche: any) => void;

    typesSousFiches: any;
    setTypesSousFiches: (typesSousFiches: any) => void;

    fields: any;
    setFields: (fields: any) => void;

    data: any;
    setData: (data: any) => void;

    cp: string;
    setCp: (cp: string) => void;

    city: string;
    setCity: (city: string) => void;

    localCp: string;
    setLocalCp: (localCp: string) => void;

    localCity: string;
    setLocalCity: (localCity: string) => void;
};

export const useSousFicheStore = create<SousFiche>((set) => ({
    choiceTypeSousFiche: null,
    setChoiceTypeSousFiche: (choiceTypeSousFiche) => set({ choiceTypeSousFiche }),

    typesSousFiches: null,
    setTypesSousFiches: (typesSousFiches) => set({ typesSousFiches }),

    fields: null,
    setFields: (fields) => set({ fields }),

    data: {},
    setData: (data) => set({ data }),

    cp: '',
    setCp: (cp) => set({ cp }),

    city: '',
    setCity: (city) => set({ city }),

    localCp: '',
    setLocalCp: (localCp) => set({ localCp }),

    localCity: '',
    setLocalCity: (localCity) => set({ localCity }),

}));