import { useIntervenantStore } from "../../../store/Crm/Intervenant";
import { useSousFicheStore } from "../../../store/Crm/SousFiche";
import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";

/**
 * @component
 * @name CreateSousFiche
 * @description CreateSousFiche est un composant qui permet d'afficher la modal pour créer une sous fiche.
 * @author Iris Succi
 * @date 2025
 */
const CreateSousFiche = () => {
  const { setChoiceTab } = useUtilsCrmStore();
  const { setChoiceCreate} = useIntervenantStore();
  const { setData } = useSousFicheStore();
  
  const handleCreate = () => {
    setChoiceCreate("CreateSousFiche");
    setChoiceTab("SousFiche");
    setData({});
  };

  return (
    <button
      className="px-2 my-2 w-44 h-8 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md cursor-pointer"
      onClick={handleCreate}
    >
      Créer une sous fiche
    </button>
  );
};

export default CreateSousFiche;
