import { useEffect } from "react";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import chevron from "../../../../assets/icons/chevron-right.svg";
import Select from "react-select";
import { createAcompteStore } from "../../../../store/Invoice/createAcompte";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import { getAllDossiers } from "../../../../utils/folders";
import { getTVA } from "../../../../utils/Invoice/fees";
import { getMissionByDosRef } from "../../../../utils/missions";
import { getClientsByDOS } from "../../../../utils/customerFile";
import { getAcompteOrigins } from "../../../../utils/Invoice/origin";
import {
  getCollectingBanks,
  getPaymentMethod,
  getPrepaymentById,
  postAcompte,
  updatePrepayment,
} from "../../../../utils/Invoice/prepayments";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import {
  errorEntry,
  errorSendNewTime,
  successAcompte,
  successUpdateData,
} from "../../../../utils/toaster";
import { useLoadingStore } from "../../../../store/utils/loading";
import {
  calculateHtFromTtcWithoutQuantity,
  calculateTVAWithoutQuantity,
} from "../../../../utils/functionService";
import { usePointAcompteStore } from "../../../../store/Invoice/pointAcompte";

/**
 * @component
 * @name CreateAcompte
 * @description Composant pour créer un acompte
 * @author Iris Succi
 * @date 2023
 */
const CreateAcompte = () => {
  const {
    animationClass,
    setAnimationClass,
    displayDiv,
    setDisplayDiv,
    selectedIdsInvoices,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();
  const { createAcompte, setCreateAcompte } = useCreateFeesOrInvoices();
  const { setChoiceTab, setSendData } = useTabsStore();
  const { setIsLoading } = useLoadingStore();
  const {
    clientsFile,
    setClientsFile,
    missions,
    setMissions,
    clients,
    setClients,
    selectedClient,
    selectedFile,
    setSelectedFile,
    selectedMission,
    setSelectedMission,
    setSelectedClient,
    banqueEncaissement,
    setBanqueEncaissement,
    selectedBanqueEncaissement,
    setSelectedBanqueEncaissement,
    origins,
    setOrigins,
    selectedOrigin,
    setSelectedOrigin,
    ns,
    setNs,
    startDate,
    setStartDate,
    ht,
    setHt,
    tvaRate,
    setTvaRate,
    tva,
    setTva,
    ttc,
    setTtc,
    selectedTvaRate,
    setSelectedTvaRate,
    paymentMethod,
    setPaymentMethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    emissionBanque,
    setEmissionBanque,
    libellecpt,
    setLibellecpt,
    piece,
    setPiece,
    dataAcompte,
    setDataAcompte,
    modeAcompte,
    setModeAcompte,
    prepaymentData,
    setPrepaymentData,
  } = createAcompteStore();

  const {
    setMontantRestant,
    setInvoicesCheckables,
    setSelectedInvoicesToAllocate,
  } = usePointAcompteStore();

  /******************* EDIT MODE  *****************/
  useEffect(() => {
    if (modeAcompte === "edit") {
      try {
        // call api qui permet de récupérer les données de l'acompte
        getPrepaymentById(selectedIdsInvoices[0])
          .then((res) => {
            setPrepaymentData(res.data[0]);
            setMontantRestant(res.data[0].ANA_EMTTTC);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.error("Erreur:", error);
      }
    }
  }, [modeAcompte]);

  useEffect(() => {
    // incremente les données dans les champs lorsqu'on est en mode edit
    if (modeAcompte === "edit") {
      const selectedFileObj = clientsFile?.find(
        (file) => file?.value === prepaymentData?.DOS_REF
      );
      setSelectedFile(selectedFileObj?.value || 0);

      const selectedClientObj = clients?.find(
        (client) => client?.value === prepaymentData?.FIC_REF
      );
      setSelectedClient(selectedClientObj?.value || 0);

      const selectedOriginObj = origins?.find(
        (origin) => origin?.value === prepaymentData?.AOR_REF
      );
      setSelectedOrigin(selectedOriginObj?.value || 0);

      const selectedBanqueEncaissementObj = banqueEncaissement.find(
        (banque) => banque?.value === prepaymentData?.ABA_REF
      );
      setSelectedBanqueEncaissement(selectedBanqueEncaissementObj?.value || 0);

      const selectedTvaRateObj = tvaRate?.find(
        (tva) => tva?.value === prepaymentData?.TVA_CLE
      );
      setSelectedTvaRate(selectedTvaRateObj?.value || 2);

      const selectedPaymentMethodObj = paymentMethod?.find(
        (payment) => payment?.value === prepaymentData?.ANA_MODE_REGLEMENT
      );
      setSelectedPaymentMethod(selectedPaymentMethodObj?.value || 0);

      setNs(prepaymentData.ANA_EMTNS !== null ? prepaymentData.ANA_EMTNS : 0);

      setHt(prepaymentData?.ANA_EMTHT !== null ? prepaymentData?.ANA_EMTHT : 0);
      setTva(
        prepaymentData?.ANA_EMTTVA !== null ? prepaymentData?.ANA_EMTTVA : 0
      );
      setTtc(
        prepaymentData?.ANA_EMTTTC !== null ? prepaymentData?.ANA_EMTTTC : 0
      );
      setEmissionBanque(
        prepaymentData?.ATR_BANQUE_EMISSION !== null
          ? prepaymentData?.ATR_BANQUE_EMISSION
          : ""
      );
      setLibellecpt(
        prepaymentData?.ATR_DEBITEUR !== null
          ? prepaymentData?.ATR_DEBITEUR
          : ""
      );
      setPiece(
        prepaymentData?.ATR_PIECE !== null ? prepaymentData?.ATR_PIECE : ""
      );
    }
  }, [prepaymentData, clientsFile]);

  useEffect(() => {
    if (modeAcompte === "edit") {
      if (selectedFile !== 0 && missions?.length !== 0) {
        const selectedMissionObj = missions?.find(
          (mission) => mission?.value === prepaymentData?.MIS_REF
        );
        setSelectedMission(selectedMissionObj?.value || 0);
      }
    }
  }, [missions]);

  /**
   * @function
   * @name handleUpdatePrepayment
   * @author Succi Iris
   * @date 2023
   * @description Modifie un acompte
   */
  const handleUpdatePrepayment = async () => {
    const updateData = {
      DOS_REF: selectedFile,
      MIS_REF: selectedMission,
      AOR_REF: selectedOrigin,
      ANA_EMTHT: ht,
      ANA_EMTTVA: tva,
      ABA_REF: selectedBanqueEncaissement,
      ANA_MODE_REGLEMENT: selectedPaymentMethod,
      ANA_EMTTTC: ttc,
      FIC_REF: selectedClient,
      ANA_DATEREALISATION: startDate,
      TVA_CLE: selectedTvaRate,
      ATR_PIECE: piece,
      ATR_BANQUE_EMISSION: emissionBanque,
      ATR_DEBITEUR: libellecpt,
    };

    if (
      selectedFile === 0 ||
      selectedOrigin === 0 ||
      selectedBanqueEncaissement === 0 ||
      selectedTvaRate === 0
    ) {
      return errorEntry();
    }
    setIsLoading(true);

    try {
      setDisplayDiv(false);
      const response = await updatePrepayment(
        selectedIdsInvoices[0],
        updateData
      );
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setCreateAcompte(false);
      setIsLoading(false);
      setChoiceTab("Acompte");
      setSelectedIdsInvoices((_prev) => []);
      successUpdateData();
      reinitializeFields();
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @description Gère l'affichage du composant en fonction de la valeur de createAcompte
   * @author Succi Iris
   * @date 2023
   */
  useEffect(() => {
    if (createAcompte === true) {
      setDisplayDiv(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDiv(false);
      }, 300);
    }
  }, [createAcompte]);

  /**
   * @function
   * @author Succi Iris
   * @date 2023
   * @description Call api pour récupérer les dossiers et les formater pour les utiliser dans un select
   */
  useEffect(() => {
    getAllDossiers()
      .then((res) => {
        const sortedData = res.data.sort((a: any, b: any) =>
          a.FIC_TITRE_CLIENT.localeCompare(b.FIC_TITRE_CLIENT)
        );
        const formattedFiles = sortedData.map((file: any) => ({
          value: file.DOS_REF,
          label: file.DOS_CODE + " - " + file.FIC_TITRE_CLIENT,
        }));
        setClientsFile(formattedFiles);
      })
      .catch((err) => console.log(err));
    
      getTVA()
      .then((res) => {
        const formattedTVA = res.data.map((tva: any) => ({
          value: tva.TVA_CLE,
          label: tva.TVA_VALEUR,
        }));
        setTvaRate(formattedTVA);
      })
      .catch((err) => console.log(err))

    getCollectingBanks()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.ABA_REF,
          label: file.ABA_LIBELLE,
        }));
        setBanqueEncaissement(formattedFiles);
      })
      .catch((err) => console.log(err));

    getPaymentMethod()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.AMR_REF,
          label: file.AMR_LIBELLE,
        }));
        setPaymentMethod(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, [displayDiv]);

  /**
   * @function
   * @author Succi Iris
   * @date 2023
   * @description Call api pour récupérer les missions et les clients en fonction du dossier sélectionné
   */
  useEffect(() => {
    if (selectedFile !== 0) {
      getMissionByDosRef(selectedFile)
        .then((res) => {
          const formattedMissions = res.data.map((mission: any) => {
            let label = mission.MIS_LIBELLE;

            // Retire tout ce qui est entre parenthèses, y compris les parenthèses elles-mêmes
            label = label.replace(/\s*\(.*?\)\s*/g, "");

            if (mission.MIS_REF !== null && mission.MIS_DEBUT) {
              label =
                new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
                " - " +
                label;
            }

            return {
              value: mission.MIS_REF,
              label: label,
            };
          });

          setMissions(formattedMissions);
        })
        .catch((err) => console.log(err));

      getClientsByDOS(selectedFile)
        .then((res) => {
          const formattedFiles = res.data.map((file: any) => ({
            value: file.FIC_REF,
            label: file.DOS_CODE + " - " + file.FIC_TITRE_ETAT,
          }));
          setClients(formattedFiles);
          if (selectedFile !== 0) {
            setSelectedClient(formattedFiles[0]?.value || 0);
            setDataAcompte("FIC_REF", formattedFiles[0]?.value || 0);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [selectedFile]);

  useEffect(() => {
    getAcompteOrigins()
    .then((res) => {
      const formattedOrigins = res.data.map((origin: any) => ({
        value: origin.AOR_REF,
        label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
        libelles: origin.Libelles.map((libelle: any) => ({
          value: libelle.ALI_CODE,
          label: libelle.ALI_LIBELLE,
        })),
      }));

      setOrigins(formattedOrigins);
    })
    .catch((err) => console.log(err));
  }, [selectedMission]);

  /**
   * @function
   * @author Succi Iris
   * @date 2023
   * @description Définit les données de l'acompte
   */
  useEffect(() => {
    setDataAcompte("ANA_DATEREALISATION", startDate);
    setDataAcompte("ABA_REF", selectedBanqueEncaissement);
    setDataAcompte("ATR_PIECE", piece);
    setDataAcompte("ATR_DEBITEUR", libellecpt);
    setDataAcompte("ATR_BANQUE_EMISSION", emissionBanque);
  }, [
    startDate,
    selectedBanqueEncaissement,
    piece,
    libellecpt,
    emissionBanque,
  ]);

  /**
   * @function
   * @name handleCreateAcompte
   * @author Succi Iris
   * @date 2023
   * @description Créer un acompte
   */
  const handleCreateAcompte = async () => {
    setDataAcompte("TVA_CLE", selectedTvaRate);
    if (selectedFile === 0 || selectedOrigin === 0 || ttc === 0 || ht === 0) {
      return errorEntry();
    }
    setIsLoading(true);

    try {
      setDisplayDiv(false);
      const response = await postAcompte(dataAcompte);
      console.log("Succès:", response);
      setSendData((prev) => !prev);
      setCreateAcompte(false);
      successAcompte();
      setIsLoading(false);
      setChoiceTab("Acompte");
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name reinitializeFields
   * @author Succi Iris
   * @date 2023
   * @description Reset tous les champs
   */
  const reinitializeFields = () => {
    setSelectedOrigin(0);
    setSelectedBanqueEncaissement(0);
    setSelectedMission(0);
    setSelectedClient(0);
    setSelectedFile(0);
    setSelectedTvaRate(2);
    setSelectedPaymentMethod(0);
    setNs(0);
    setStartDate(new Date());
    setHt(0);
    setTva(0);
    setTtc(0);
    setEmissionBanque("");
    setLibellecpt("");
    setPiece("");
    setClientsFile([]);
    setMissions([]);
    setClients([]);
    setMontantRestant(0);
    setBanqueEncaissement([]);
    setInvoicesCheckables([]);
    setSelectedInvoicesToAllocate([]);
  };

  // Reinitialise les champs lorsqu'on ferme le composant
  useEffect(() => {
    reinitializeFields();
  }, [displayDiv]);

  // Reinitialise les champs lorsqu'on change de mode de paiement
  useEffect(() => {
    setEmissionBanque("");
    setLibellecpt("");
    setPiece("");
  }, [selectedPaymentMethod]);

  /**
   * @function
   * @name handleInputChange
   * @author Succi Iris
   * @date 2024
   * @description Fonction qui permet de gérer les changements des inputs
   */
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "ht") {
      const fieldValue = value === "" ? "" : parseFloat(value);

      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setTtc(0);
        setDataAcompte("ANA_EMTTVA", 0);
        setDataAcompte("ANA_EMTTTC", 0);
      } else {
        const { newTva, newTtc } = calculateTVAWithoutQuantity(
          fieldValue,
          selectedTvaRate
        );
        setTva(newTva.toFixed(2));
        setTtc(newTtc.toFixed(2));
        setDataAcompte("ANA_EMTTVA", newTva.toFixed(2));
        setDataAcompte("ANA_EMTTTC", newTtc.toFixed(2));
      }
    } else if (name === "ttc") {
      const fieldValue = value === "" ? "" : parseFloat(value);

      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setHt(0);
        setDataAcompte("ANA_EMTHT", 0);
        setDataAcompte("ANA_EMTTVA", 0);
      } else {
        const { newTva, newHt } = calculateHtFromTtcWithoutQuantity(
          fieldValue,
          selectedTvaRate
        );
        setTva(newTva.toFixed(2));
        setHt(newHt.toFixed(2));
        setDataAcompte("ANA_EMTTVA", newTva.toFixed(2));
        setDataAcompte("ANA_EMTHT", newHt.toFixed(2));
      }
    } else if (name === "txTva") {
      const fieldValue = parseFloat(value);
      setSelectedTvaRate(fieldValue);
      setDataAcompte("TVA_CLE", fieldValue);
    }
  };

  useEffect(() => {
    handleInputChange({ target: { name: "ht", value: ht } });
  }, [selectedTvaRate]);

  return (
    <>
      {displayDiv ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-start h-full w-full">
            <button
              className="h-full w-10 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => {
                setSelectedIdsInvoices((_prev) => []);
                setCreateAcompte(false);
                reinitializeFields();
              }}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-center items-start w-[80%] h-full m-auto">
              <p className="font-bold text-lg my-5 text-center w-full">
                {modeAcompte === "create"
                  ? "Création d'un acompte"
                  : "Modification d'un acompte"}
              </p>
              <div className="grid grid-cols-7 w-full m-auto gap-4">
                <div className="col-start-1 col-end-2 mt-1">Dossier :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  {modeAcompte === "edit" ? (
                    <Select
                      options={clientsFile}
                      isClearable
                      className="w-full h-9 z-40"
                      isSearchable
                      value={
                        modeAcompte === "edit"
                          ? clientsFile.find(
                              (option) => option.value === selectedFile
                            )
                          : null
                      }
                      onChange={(selectedOption) => {
                        setSelectedFile(selectedOption?.value || 0);
                        setDataAcompte("DOS_REF", selectedOption?.value || 0);
                      }}
                    />
                  ) : (
                    <Select
                      options={clientsFile}
                      isClearable
                      className="w-full h-9 z-40"
                      isSearchable
                      onChange={(selectedOption) => {
                        setSelectedFile(selectedOption?.value || 0);
                        setDataAcompte("DOS_REF", selectedOption?.value || 0);
                        setDataAcompte(
                          "ATR_DEBITEUR",
                          selectedOption?.label || 0
                        );
                        setLibellecpt(selectedOption?.label || 0);
                      }}
                    />
                  )}
                </div>
                <div className="col-start-5 col-end-6 mt-1">Mission :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="mission"
                    id="mission"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20"
                    value={selectedMission}
                    onChange={(event) => {
                      const selectedValue = Number(event.target.value);
                      setSelectedMission(selectedValue);
                      setDataAcompte("MIS_REF", selectedValue);
                    }}
                  >
                    <option value=""></option>
                    {missions?.map((mission, i) => (
                      <option key={i} value={mission?.value}>
                        {mission?.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-start-1 col-end-2 mt-1 ">Client :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  {modeAcompte === "edit" ? (
                    <Select
                      options={clients}
                      isClearable
                      className="w-full h-9 z-30"
                      isSearchable
                      value={
                        modeAcompte === "edit"
                          ? clients.find(
                              (option) => option.value === selectedClient
                            )
                          : null
                      }
                      onChange={(selectedOption) => {
                        setSelectedClient(selectedOption?.value || 0);
                        setDataAcompte("FIC_REF", selectedOption?.value || 0);
                      }}
                    />
                  ) : (
                    <Select
                      options={clients}
                      isClearable
                      className="w-full h-9 z-30"
                      isSearchable
                      value={
                        dataAcompte.FIC_REF !== null
                          ? clients.find(
                              (option) => option.value === dataAcompte.FIC_REF
                            )
                          : null
                      }
                      onChange={(selectedClient: any | null) => {
                        setSelectedClient(selectedClient?.value || 0);
                        setDataAcompte("FIC_REF", selectedClient?.value || 0);
                      }}
                    />
                  )}
                </div>
                <div className="col-start-5 col-end-6 mt-1">Origine :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="origin"
                    id="origin"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedOrigin}
                    onChange={(event) => {
                      setSelectedOrigin(Number(event.target.value));
                      setDataAcompte("AOR_REF", Number(event.target.value));
                    }}
                  >
                    {" "}
                    <option value=""></option>
                    {origins?.map((origin, i) => (
                      <option key={i} value={origin?.value}>
                        {origin?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
                <div className="col-start-1 col-end-2 ">
                  Banque encaissement:
                </div>
                <div className="flex flex-col col-start-2 col-end-4">
                  <select
                    name="banqueEncaissement"
                    id="banqueEncaissement"
                    className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                    value={selectedBanqueEncaissement}
                    onChange={(event) => {
                      setSelectedBanqueEncaissement(
                        parseInt(event.target.value)
                      );
                    }}
                  >
                    <option value=""></option>
                    {banqueEncaissement?.map((banque, i) => (
                      <option key={i} value={banque?.value}>
                        {banque?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-5 col-end-6 mt-1">Date :</div>
                <div className="flex flex-col col-start-6 col-end-8 ">
                  <Datepicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </div>
              </div>
              <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
              <div className="grid grid-cols-10 gap-2 w-full">
                <div className="col-start-1 col-end-2 mt-1 text-sm ml-4">
                  H.T :
                </div>
                <input
                  name="ht"
                  value={ht}
                  type="number"
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  onChange={(e) => {
                    setHt(e.target.value);
                    setDataAcompte("ANA_EMTHT", parseInt(e.target.value));
                    handleInputChange(e);
                  }}
                />
                <div className="col-start-3 col-end-4 mt-1 text-sm">
                  TX TVA :
                </div>
                <select
                  name="txTva"
                  id="txTva"
                  className="w-full text-sm border-[1px] rounded border-gray-300 h-8 py-1 z-20 "
                  value={selectedTvaRate}
                  onChange={(e) => {
                    setSelectedTvaRate(parseInt(e.target.value));
                    setDataAcompte("TVA_CLE", parseInt(e.target.value));
                  }}
                >
                  <option value=""></option>
                  {tvaRate?.map((tvaRate, i) => (
                    <option key={i} value={tvaRate?.value}>
                      {tvaRate?.label} %
                    </option>
                  ))}
                </select>
                <div className="col-start-5 text-sm col-end-6 mt-1 ml-3 ">
                  TVA :
                </div>
                <input
                  value={tva}
                  type="number"
                  readOnly
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  onChange={(e) => {
                    setTva(e.target.value);
                    setDataAcompte("ANA_EMTTVA", parseInt(e.target.value));
                  }}
                />
                <div className="col-start-7 col-end-8 text-sm mt-1 ml-4">
                  NS :
                </div>
                <input
                  type="number"
                  value={ns}
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  onChange={(e) => {
                    setNs(parseInt(e.target.value));
                  }}
                />
                <div className="col-start-9 col-end-10 text-sm mt-1 ml-3">
                  T.T.C :
                </div>
                <input
                  name="ttc"
                  value={ttc}
                  type="number"
                  className="w-full border-[1px] rounded border-gray-300 h-8 p-1"
                  onChange={(e) => {
                    setTtc(e.target.value);
                    setDataAcompte("ANA_EMTTTC", parseFloat(e.target.value));
                    handleInputChange(e);
                    setMontantRestant(parseInt(e.target.value));
                  }}
                />
              </div>
              <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
              <div className="grid grid-cols-7 w-full m-auto gap-4">
                <div className="col-start-1 col-end-2">Mode de paiement :</div>
                <div className="flex col-start-2 col-end-4">
                  <select
                    name="paymentMethod"
                    id="paymentMethod"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20"
                    value={selectedPaymentMethod}
                    onChange={(event) => {
                      const selected = paymentMethod.find(
                        (payment) =>
                          payment.value === parseInt(event.target.value)
                      );
                      setSelectedPaymentMethod(selected?.value || 0);
                      setDataAcompte(
                        "ANA_MODE_REGLEMENT",
                        selected?.value || 0
                      );
                    }}
                  >
                    <option value=""></option>
                    {paymentMethod?.map((payment, i) => (
                      <option key={i} value={payment?.value}>
                        {payment?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-5 col-end-6 mt-1">
                  Banque émission{" "}
                </div>
                <input
                  type="text"
                  name="banqueemision"
                  id="banqueemision"
                  value={emissionBanque}
                  onChange={(e) => {
                    setEmissionBanque(e.target.value);
                    setDataAcompte("ABA_REF", parseInt(e.target.value));
                  }}
                  disabled={selectedPaymentMethod !== 0}
                  className="col-start-6 col-end-8 border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                />
                <div className="col-start-1 col-end-2 mt-1">Libellé cpt :</div>
                <input
                  type="text"
                  name="libelle"
                  id="libelle"
                  value={libellecpt}
                  onChange={(e) => setLibellecpt(e.target.value)}
                  disabled={selectedPaymentMethod !== 0}
                  className="col-start-2 col-end-4 border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                />
                <div className="col-start-5 col-end-6 mt-1">Pièce : </div>
                <input
                  type="text"
                  name="Pièce"
                  id="Pièce"
                  value={piece}
                  onChange={(e) => setPiece(e.target.value)}
                  disabled={selectedPaymentMethod !== 0}
                  className="col-start-6 col-end-8 border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                />
                <button
                  className="w-full h-8 col-start-6 col-end-7 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md"
                  onClick={() => {
                    setChoiceTab("Acompte");
                    setCreateAcompte(false);
                    setDisplayDiv(false);
                    setModeAcompte("create");
                    setPrepaymentData({
                      DOS_REF: null,
                      MIS_REF: null,
                      AOR_REF: null,
                      ANA_EMTHT: null,
                      ANA_EMTTVA: null,
                      ABA_REF: null,
                      ANA_MODE_REGLEMENT: null,
                      ANA_EMTTTC: null,
                      FIC_REF: null,
                      ANA_DATEREALISATION: null,
                      TVA_CLE: null,
                      ATR_PIECE: null,
                      ATR_BANQUE_EMISSION: null,
                      ATR_DEBITEUR: null,
                      ANA_EMTNS: null,
                    });
                    setSelectedIdsInvoices((_prev) => []);
                    reinitializeFields();
                  }}
                >
                  Annuler{" "}
                </button>
                <button
                  className="w-full col-start-7 col-end-8 h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                  onClick={() => {
                    if (modeAcompte === "create") handleCreateAcompte();
                    else {
                      handleUpdatePrepayment();
                      setModeAcompte("create");
                    }
                  }}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateAcompte;
