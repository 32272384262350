import chart2 from "../../../assets/exemple/graph2.png";

const Charts2 = () => {
  return (
    <div className="border-2 border-bc-orange rounded-md w-full m-auto h-96 flex flex-col justify-center items-center">
      <p className="text-black text-center">DASHBOARD</p>
      <img src={chart2} alt="chart exemple" />
    </div>
  );
};

export default Charts2;
