import { useEffect, useRef } from "react";
import { usePagination } from "../../../hooks/usePagination";
import { useSelectAll } from "../../../hooks/useSelectAll";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useLoadingStore } from "../../../store/utils/loading";
import CreateAcompte from "../modal/Create/CreateAcompte";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";
import { formatNumber } from "../../../utils/functionService";

/**
 * @component
 * @author Succi Iris
 * @date 2023
 * @description Ce composant affiche un tableau de comptes, permettant aux utilisateurs de les sélectionner et de les valider. Il gère également la pagination et la fonctionnalité de recherche. 
 */
const AcompteTable = () => {
  const { acomptes, acomptesFilter, setAcomptesFilter } =
    dataInvoicesFeelsStore();
  const { search } = useSearchBarStore();
  const { displayDiv } = useCreateInvoiceStore();
  const { isLoading } = useLoadingStore();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (displayDiv && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [displayDiv]);

  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(acomptesFilter, 30);

  const {
    selectAll,
    selectedIdsInvoices,
    handleSelectAllChange,
    handleCheckboxChange,
  } = useSelectAll(filteredData);

  useEffect(() => {
    let data = acomptes?.length > 0 ? [...acomptes] : [...acomptesFilter];
    if (search !== "") {
      data = data.filter(
        (item) =>
          item["DOS_TITRE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["MIS_LIBELLE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["AOR_CODE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["ANA_CODELIB"]?.toLowerCase().includes(search.toLowerCase()) ||
          dateFormattedFr(item["ANA_DATEREALISATION"])
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item["ANA_EMTTTC"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trier par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.ANA_DATEREALISATION).getTime();
      const dateB = new Date(b.ANA_DATEREALISATION).getTime();
      return dateB - dateA; // Triez du plus récent au plus ancien
    });

    setAcomptesFilter(data);
  }, [search, acomptes]);

  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : (
          <div
            ref={scrollDivRef}
            className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll"
          >
            <table className="w-full">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0">
                <tr className="w-[2%] border-r-[1px] flex justify-center items-center">
                  {" "}
                  <input
                    type="checkbox"
                    onChange={handleSelectAllChange}
                    checked={selectAll}
                  />
                </tr>
                <tr className="w-[20%] border-r-[1px] p-2">Dossier</tr>
                <tr className="w-[20%] border-r-[1px] p-2">Mission</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Origine</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Libellé</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Opé.</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Date</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Banque</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Paiement</tr>
                <tr className="w-[5%] border-r-[1px] p-2">H.T</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Tx T.V.A</tr>
                <tr className="w-[5%] border-r-[1px] p-2">T.V.A</tr>
                <tr className="w-[5%] border-r-[1px] p-2">T.T.C</tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs">
                {filteredData?.map((invoice, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex ${
                      invoice?.Lié === false && invoice?.Pointé === false
                        ? "bg-bc-orange-pale"
                        : index % 2 === 0
                        ? "bg-grey-Array"
                        : ""
                    }`}
                  >
                    <td className="w-[2%] border-r-[1px] flex justify-center items-start pt-2">
                      {invoice?.Lié === false && invoice?.Pointé === false ? (
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          value={invoice.id}
                          checked={selectedIdsInvoices.includes(
                            invoice.ANA_REF
                          )}
                          onChange={(e) =>
                            handleCheckboxChange(e, invoice.ANA_REF)
                          }
                        />
                      ) : null}
                    </td>
                    <td className="w-[20%] border-r-[1px] p-2">
                      {invoice?.DOS_TITRE}
                    </td>
                    <td className="w-[20%] border-r-[1px] p-2">
                      {invoice?.MIS_LIBELLE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {invoice?.AOR_CODE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {invoice?.ANA_CODELIB}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {invoice?.ANA_OPERATEUR}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {dateFormattedFr(invoice?.ANA_DATEREALISATION)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.ABA_LIBELLE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.AMR_LIBELLE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(invoice?.ANA_EMTHT)}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {invoice?.TVA_VALEUR} %
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(invoice?.ANA_EMTTVA)}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(invoice?.ANA_EMTTTC)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {displayDiv ? <CreateAcompte /> : null}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default AcompteTable;
