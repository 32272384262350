import React, { useEffect, useState } from "react";
import { addComment } from "../../../interface/modales";
import { useTranslation } from "react-i18next";
import { editStore } from "../../../store/TimeManagement/editStore";

/**
 * @component
 * @name AddComment
 * @author Succi Iris
 * @date 2023
 * @description Un composant qui permet d'ajouter un commentaire a un temps saisi.
 */
const AddComment: React.FC<addComment> = ({
  closeModal,
  setCommentsForSend,
}) => {
  //Translation
  const { t } = useTranslation();

  //Store
  const { dayTimes, setUpdateData } = editStore();

  //State
  const [comments, setComments] = useState<{ [date: string]: string }>({});

  useEffect(() => {
    setCommentsForSend(comments);
  }, [comments]);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-2/4 my-6 mx-auto max-w-3xl min-w-[55%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl text-center">
                {t("Ajouter un commentaire")}{" "}
              </h3>
            </div>
            {/*body*/}

            <div className="w-10/12 m-auto font-light p-5 flex flex-col justify-center items-center">
              {Object.values(dayTimes)?.map(
                (day: any, i: number) =>
                  day.time !== "" && (
                    <div className="grid grid-cols-5 w-full">
                      <div
                        key={i}
                        className="flex justify-start items-start col-start-1 col-end-2"
                      >
                        <p className="pt-2 text-xl">
                          {day.date.toLocaleDateString("fr-FR", {
                            weekday: "long",
                            day: "numeric",
                          })}{" "}
                          :
                        </p>
                      </div>
                      <div className="flex justify-center items-center col-start-2 col-end-6">
                        <textarea
                          className="border border-slate-200 rounded-md p-2 mt-2 w-full font-thin h-20 text-md overflow-y-scroll"
                          placeholder="Commentaire"
                          value={
                            comments[
                              new Date(day.date).toISOString().split("T")[0]
                            ] || ""
                          }
                          maxLength={255}
                          onChange={(e) => {
                            setComments((prevComments: any) => ({
                              ...prevComments,
                              [new Date(day.date).toISOString().split("T")[0]]:
                                e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>

            {/*footer*/}
            <div className="flex flex-col items-center p-4 border-t border-solid border-slate-200 rounded-b">
              <div className="flex items-end justify-end ">
                <button
                  className="text-bc-orange background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {t("Annuler")}
                </button>
                <button
                  className="text-bc-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setCommentsForSend(comments);
                    setUpdateData(true);
                    closeModal();
                  }}
                >
                  {t("Valider")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AddComment;
