import React, { useEffect } from "react";
import { useExplorer } from "../../../../store/Crm/Explorer";
import User from "../../../../assets/icons/user.svg";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";

const SousFicheTree: React.FC<any> = ({
  sousFiches,
  onSelected,
  selectedSousFiche,
}) => {
  const { setFicRefSousFiche, setSelectedParticipant } = useExplorer();
  const { setChoiceCreate } = useIntervenantStore();

  // Permet de remove le bg-orange de l'intervenant sélectionné si on clique sur une sous-fiche
  useEffect(() => {
    if (selectedSousFiche) {
      setSelectedParticipant(null);
    }
  }, [selectedSousFiche]);

  return (
    <div className="w-[97%] m-auto flex flex-col  my-2">
      <div className="relative">
        <div className="flex items-center">
          <button
            className={`flex items-center pl-10 `}
            onClick={() => {
              onSelected(sousFiches?.FIC_REF);
              setFicRefSousFiche(sousFiches?.FIC_REF);
              setChoiceCreate("UpdateSousFiche");
            }}
          >
            <p className="text-gray-200 text-xl font-bold mr-2 ">-</p>
            <div
              className={`flex justify-start items-center cursor-pointer ${
                selectedSousFiche === sousFiches.FIC_REF
                  ? "bg-bc-lightOrange border-2 rounded-md border-bc-lightOrange pr-2"
                  : ""
              }`}
            >
              <img src={User} alt="user" className="w-4 h-4" />
              <div className={`pl-2 font-light text-sm `}>
                {sousFiches?.FIC_TITRE}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SousFicheTree;
