const NextAppointment = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center mr-10">
      <h2 className="w-full border-2 bg-bc-blue border-bc-blue rounded-md text-white text-center">
        PROCHAINS RENDEZ-VOUS
      </h2>
      <div className="w-full grid grid-cols-4 gap-1 mt-5">
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Heure</p>
        </div>
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Lieu</p>
        </div>
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Client</p>
        </div>
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Type</p>
        </div>
      </div>
    </div>
  );
};

export default NextAppointment;
