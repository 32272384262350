import { useExplorer } from "../../../../store/Crm/Explorer";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";
import { useSousFicheStore } from "../../../../store/Crm/SousFiche";
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";
import SousFichesTree from "./SousFicheTree";

const CategoriesTree: React.FC<any> = ({ category, isOpen, onToggle }) => {
  // Store
  const { sousFiches, setSousFiches, selectedSousFiche, setSelectedSousFiche } =
    useExplorer();
  const { setChoiceTab } = useUtilsCrmStore();
  const { setChoiceTypeSousFiche } = useSousFicheStore();
  const { setChoiceCreate } = useIntervenantStore();

  const handleOpen = () => {
    setSousFiches([]);
    setSelectedSousFiche(null);
    setChoiceCreate("");
    if (!isOpen) {
      setSousFiches(category.sousFiches);
      setChoiceTab("SousFiche");
    }
    onToggle();
  };

  return (
    <div className="w-full relative ml-4">
      <div className="flex justify-start items-center cursor-pointer">
        <div className="flex flex-col justify-start items-start">
          <div
            key={category.PENT_ID}
            className="flex justify-start items-start cursor-pointer px-2"
          >
            <div className="pl-4 flex items-center">
              <p className="text-gray-200 text-xl font-bold mr-2 ">-</p>
              <button
                className="text-sm font-semibold "
                onClick={() => {
                  handleOpen();
                  setChoiceTypeSousFiche(category.PENT_NOM);
                }}
              >
                {category.PENT_NOM}
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen &&
        sousFiches &&
        sousFiches.length > 0 &&
        sousFiches?.map((sousFiches: any, index: number) => (
          <div key={index} className="relative">
            <div className="border-l-2 absolute top-0 bottom-0 left-[40px] h-full border-dashed" />
            <SousFichesTree
              key={sousFiches.ficCle}
              sousFiches={sousFiches}
              onSelected={(ficRef: string) => setSelectedSousFiche(ficRef)} // Transmet `ficCle`
              selectedSousFiche={selectedSousFiche} // Passe la valeur actuelle pour comparaison
            />
          </div>
        ))}
    </div>
  );
};

export default CategoriesTree;
