import { useTranslation } from "react-i18next";
import SearchBar from "../../Utils/SearchBar";
import list from "../../../assets/icons/list.svg";
import { itemsListTime } from "../../../data/dataSelectItemDisplayListTime";
import { useState } from "react";
import FilterTime from "../modals/FilterTime";
import ExportButton from "../../Utils/ExportButton";
import useOutsideClick from "../../../hooks/useClickOutside";
import { useViewStore } from "../../../store/TimeManagement/viewStore";
import { useListStore } from "../../../store/TimeManagement/listStore";
import { useDateStore } from "../../../store/TimeManagement/dateStore";
import { getMonthName, getWeekNumber } from "../../../utils/functionService";
import { useSearchBarStore } from "../../../store/utils/searchBar";

/**
 * @component
 * @name WeekOrDayButtons
 * @author Succi Iris
 * @date 2023
 * @description
 * Le composant `WeekOrDayButtons` permet de gérer les boutons de la semaine et de la liste.
 */
const WeekOrDayButtons: React.FC<any> = ({ openModal, closeModal }) => {
  //Translation
  const { t } = useTranslation();

  //Store
  const { view, setView } = useViewStore();
  const { selectedItems, setSelectedItems } = useListStore();
  const { startDate } = useDateStore();
  const { setSearch } = useSearchBarStore();

  //Hook
  const { ref, isVisible, setIsVisible } = useOutsideClick(false, () =>
    setIsOpenExport(false)
  );

  //State
  const [isOpenExport, setIsOpenExport] = useState(false);
  const items = itemsListTime.split(", ");

  const handleSelect = (item: any) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem: any) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleToggle = () => {
    if (isOpenExport) {
      setIsOpenExport(false);
    }
    setIsVisible(!isVisible);
  };

  return (
    <div
      className={view ? "flex justify-between mb-2 w-full" : "flex mb-2 w-full"}
    >
      <div className="flex">
        <button
          className="w-24 h-8 bg-[#cecece] border-[1px] flex justify-center items-center px-4 mr-2 rounded hover:bg-bc-lightGrey transition-colors duration-200"
          onClick={() => {
            setView("week");
            setSearch("");
          }}
        >
          {t("Semaine")}
        </button>
        <button
          className="w-24 h-8 bg-[#cecece]  border-[1px] flex justify-center items-center mr-10 px-4 rounded hover:bg-bc-lightGrey transition-colors duration-200"
          onClick={() => {
            setView("list");
            setSearch("");
          }}
        >
          {t("Liste")}
        </button>

        <div className="xl:flex hidden justify-center items-center">
          <p className=" text-slate-500 font-semibold text-lg">
            {t("Semaine")} : {getWeekNumber(startDate)}
          </p>
          <p className="pl-5 text-slate-500 font-semibold text-lg">
            {t("Mois")} : {getMonthName(startDate)}
          </p>
        </div>
      </div>
      {/* Just for the list view */}
      {view === "list" && (
        <div className="hidden lg:flex">
          <button
            className="w-24 h-8 bg-bc-orange border-[1px] flex justify-center items-center mr-2 px-4 rounded text-white hover:bg-bc-lightOrange transition-colors duration-200"
            onClick={() => {
              if (openModal) {
                openModal(<FilterTime closeModal={closeModal} />);
              }
            }}
          >
            {t("Filtre")}
          </button>
          <SearchBar />
          <div className="relative">
            <button
              className="w-12 h-8 bg-bc-orange border-[1px] flex justify-center items-center mx-2 px-4 rounded text-white hover:bg-bc-lightOrange transition-colors duration-200"
              onClick={handleToggle}
            >
              <img src={list} alt="icon list" className="h-10 w-10 z-50" />
            </button>
            {isVisible && (
              <div className="absolute top-10 w-44 right-2 h-96  border-[1px] bg-slate-200 rounded-md z-50">
                {isVisible && (
                  <div
                    ref={ref}
                    className=" w-full"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {items.map((item, index) => (
                      <div key={index} className="w-full h-full pl-1 ">
                        <input
                          type="checkbox"
                          value={item}
                          checked={selectedItems.includes(item)}
                          className="mr-2"
                          onChange={() => handleSelect(item)}
                        />
                        <label>{item}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          <ExportButton
            isOpenExport={isOpenExport}
            setIsOpenExport={setIsOpenExport}
          />
        </div>
      )}
    </div>
  );
};

export default WeekOrDayButtons;
