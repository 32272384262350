import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import chevron from "../../../../assets/icons/chevron-right.svg";
import { useCreateSchedule } from "../../../../store/Invoice/schedule";
import { useEffect, useState } from "react";
import {
  getCollectingBanks,
  getPaymentMethod,
} from "../../../../utils/Invoice/prepayments";
import { daysSchedule, frequenciesData } from "../../../../data/dataSchedule";
import {
  getDomiciliation,
  getSchedules,
  patchSchedule,
  postSchedule,
} from "../../../../utils/Invoice/invoicing";
import {
  fillAllFields,
  successSchedule,
  successUpdateSchedule,
} from "../../../../utils/toaster";
import { dateFormattedFr } from "../../../../utils/TimeManager/timeUtils";
/**
 * @name CreateSchedule
 * @description
 * Ce composant gère la création d'un échéancier
 * @date 13/10/2024
 * @author Iris Succi
 * @returns {JSX.Element} - The rendered component.
 */
const CreateSchedule = () => {
  const {
    animationClass,
    setAnimationClass,
    displayDivSchedule,
    setDisplayDivSchedule,
    selectedIdsInvoices,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();

  const { createSchedule, setCreateSchedule } = useCreateFeesOrInvoices();
  const {
    setModeSchedule,
    banks,
    setBanks,
    selectedBank,
    setSelectedBank,
    modePaiement,
    setModePaiement,
    selectedModePaiement,
    setSelectedModePaiement,
    nbSchedule,
    setNbSchedule,
    selectedDay,
    setSelectedDay,
    firstDay,
    setFirstDay,
    selectedFrequencies,
    setSelectedFrequencies,
    solde,
    totalSchedule,
    setTotalSchedule,
    schedules,
    setSchedules,
    ANA_REF,
    domiciliations,
    setDomiciliations,
    reset,
    idSchedule,
    setIdSchedule,
    dateSchedule,
    setDateSchedule,
    amountSchedule,
    setAmoutSchedule,
    dateBordereau,
    setDateBordereau,
    update,
    setUpdate,
  } = useCreateSchedule();

  const [loading, setLoading] = useState<boolean>(false);
  /**
   * @function
   * @description Gère laffichage de la création d'un échéancier
   * @author Succi Iris
   * @date 2023
   */
  useEffect(() => {
    if (createSchedule === true) {
      setDisplayDivSchedule(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDivSchedule(false);
      }, 300);
    }
  }, [createSchedule]);

  // Call API pour récupérer les banques de collecte et les modes de paiement ainsi que la domiciliation si le mode de paiement est selectionné
  useEffect(() => {
    getCollectingBanks()
      .then((res) => {
        const formattedBanks = res.data.map((bank: any) => ({
          value: bank.ABA_REF,
          label: bank.ABA_LIBELLE,
        }));
        setBanks(formattedBanks);
      })
      .catch((err) => console.log(err));

    getPaymentMethod()
      .then((res) => {
        const formattedPaymentMethod = res.data.map((mode: any) => ({
          value: mode.AMR_REF.toString(),
          label: mode.AMR_LIBELLE,
        }));
        setModePaiement(formattedPaymentMethod);
      })
      .catch((err) => console.log(err));

    if (ANA_REF !== 0) {
      getDomiciliation(ANA_REF)
        .then((res) => {
          const formattedFiles = res.data.map((mode: any) => ({
            value: mode.ADO_REF,
            label: mode.ADO_INTITULE,
          }));
          setDomiciliations(formattedFiles);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedIdsInvoices]);

  const formatDate = (date: any) => {
    return date.toISOString().split("T")[0];
  };

  // Récupère les échéances
  useEffect(() => {
    getSchedules(ANA_REF)
      .then((res) => {
        setSchedules(res.data);
        calculateTotalSchedule(res.data);
      })
      .catch((err) => console.log(err));
  }, [idSchedule, update]);

    /**
   * @function handleCreateSchedule
   * @description Gère la création d'un échéancier
   * @author Succi Iris
   * @date 2023
   */
  const handleCreateSchedule = () => {
    const data = {
      frequency: selectedFrequencies,
      AMR_REF: Number(selectedModePaiement.value),
      AMR_LIBELLE: selectedModePaiement.label,
      ABA_REF: Number(selectedBank),
      ADO_REF: null,
      numberOfSchedule: nbSchedule,
      dayOfSchedule: selectedDay,
      firstDay: firstDay,
    };

    if (data.AMR_REF) {
      setLoading(true);
      postSchedule(ANA_REF, data)
        .then(() => {
          successSchedule();
          reset();
          setUpdate(!update);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else {
      setLoading(false);
      fillAllFields();
    }
  };

  // Gère le changement de checkbox
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    AEC_DATE: Date,
    AEC_EMTTTC: number,
    AEC_DATE_BORDEREAU: Date
  ) => {
    if (e.target.checked) {
      setIdSchedule(idSchedule === id ? null : id);
      setDateSchedule(AEC_DATE);
      setAmoutSchedule(AEC_EMTTTC);
      setDateBordereau(AEC_DATE_BORDEREAU);
    } else {
      setIdSchedule(0);
      setDateSchedule(null);
      setAmoutSchedule(0);
      setDateBordereau(null);
    }
  };

  // Met a jour les dates en fonction de l'échéancié selectionné
  useEffect(() => {
    if (idSchedule) {
      const item = schedules?.find((item: any) => item.AEC_REF == idSchedule);
      calculateTotalSchedule(schedules);

      const AEC_DATE = new Date(item.AEC_DATE);
      AEC_DATE.setHours(AEC_DATE.getHours() + 2);
      setDateSchedule(AEC_DATE);

      if (item.AEC_DATE_BORDEREAU !== null) {
        const AEC_DATE_BORDEREAU = new Date(item.AEC_DATE_BORDEREAU);
        AEC_DATE_BORDEREAU.setHours(AEC_DATE_BORDEREAU.getHours() + 2);
        setDateBordereau(AEC_DATE_BORDEREAU);
      } else {
        setDateBordereau(null);
      }
    }
  }, [idSchedule]);

   /**
   * @function handlePatchSchedule
   * @description Gère la modification d'un échéancier
   * @author Succi Iris
   * @date 2023
   */
  const handlePatchSchedule = () => {
    const data = {
      AEC_DATE: dateSchedule,
      AEC_DATE_BORDEREAU: dateBordereau,
      AEC_EMTTTC: amountSchedule,
    };
    patchSchedule(ANA_REF, Number(idSchedule), data)
      .then(() => {
        setIdSchedule(0);
        successUpdateSchedule();
      })
      .catch((err) => console.log(err));
  };

  const calculateTotalSchedule = (arr: any[]) => {
    const numbers = arr.map((item) => {
      return item.AEC_EMTTTC;
    });

    const sum = numbers.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    setTotalSchedule(sum.toFixed(2));
  };

  return (
    <>
      {displayDivSchedule ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-start h-full w-full overflow-y-scroll">
            <button
              className="h-full w-10 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => {
                setSelectedIdsInvoices((_prev) => []);
                setCreateSchedule(false);
                setModeSchedule("");
              }}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-start items-start w-[90%] h-full m-auto">
              <p className="font-bold text-lg my-5 text-center w-full">
                Saisie de l'échéancier
              </p>
              <p className="font-bold">Création automatique d'échéances</p>
              <div className="grid grid-cols-7 w-full gap-4 mt-5">
                <div className="flex flex-col justify-start items-start col-start-1 col-end-3">
                  <p>Mode de réglement:</p>
                  <select
                    name="modePaiement"
                    id="modePaiement"
                    className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                    value={selectedModePaiement.value || ""}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedOption = modePaiement.find(
                        (mode: any) => mode.value == selectedValue
                      );
                      if (selectedOption) {
                        console.log(selectedOption);
                        setSelectedModePaiement(selectedOption);
                      }
                    }}
                  >
                    <option value=""></option>
                    {modePaiement?.map((mode: any, i: any) => (
                      <option key={i} value={mode?.value}>
                        {mode?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col justify-start items-start col-start-3 col-end-5">
                  <p>Banque:</p>
                  <select
                    name="bank"
                    id="bank"
                    className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                    value={selectedBank}
                    onChange={(e) => {
                      setSelectedBank(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    {banks?.map((bank: any, i: any) => (
                      <option key={i} value={bank.value}>
                        {bank.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col justify-start items-start col-start-5 col-end-7">
                  <p>Fréquence :</p>
                  <select
                    name="frequence"
                    id="frequence"
                    value={selectedFrequencies}
                    className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                    onChange={(e) => {
                      setSelectedFrequencies(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    {frequenciesData.map((frequency, i) => (
                      <option key={i} value={frequency}>
                        {frequency}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col justify-start items-start col-start-1 col-end-3">
                  <p>Nombre d'échéances :</p>
                  <input
                    type="text"
                    className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                    value={nbSchedule}
                    onChange={(e) => {
                      setNbSchedule(e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col justify-start items-start col-start-3 col-end-5">
                  <p>Jour d'échéance :</p>
                  <select
                    name="day"
                    id="day"
                    className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                    value={selectedDay}
                    onChange={(e) => {
                      setSelectedDay(e.target.value);
                    }}
                  >
                    <option value=""></option>
                    {daysSchedule?.map((day, i) => (
                      <option key={i} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col justify-start items-start col-start-5 col-end-7">
                  <p>Premier jour :</p>
                  <input
                    type="date"
                    className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                    value={formatDate(firstDay)}
                    onChange={(e) => {
                      setFirstDay(new Date(e.target.value));
                    }}
                  />
                </div>
                {selectedModePaiement.value === 2 ? (
                  <div className="flex flex-col justify-start items-start col-start-1 col-end-3">
                    <p>Domiciliation:</p>
                    <select
                      name="modePaiement"
                      id="modePaiement"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                    >
                      <option value=""></option>
                      {domiciliations?.map((mode: any, i: any) => (
                        <option
                          key={i}
                          value={`${mode?.value} - ${mode?.label}`}
                        >
                          {mode?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : null}
                <div className="flex flex-col justify-start items-start col-start-3 col-end-5">
                  <p>Solde facture :</p>
                  <input
                    type="text"
                    name="solde"
                    id="solde"
                    disabled
                    value={solde}
                    className="w-full border-[1px] rounded border-gray-300 bg-gray-100 h-9 p-1 z-20"
                  />
                </div>
                <div className="flex flex-col justify-start items-start col-start-5 col-end-7">
                  <p>Total échéances planifiées:</p>
                  <input
                    type="text"
                    name="total"
                    id="total"
                    disabled
                    value={totalSchedule}
                    className="w-full border-[1px] rounded border-gray-300 bg-gray-100 h-9 p-1 z-20"
                  />
                </div>
                <div className="col-start-5 col-end-7 grid grid-cols-2 gap-2">
                  <button
                    className="w-full h-8 col-start-1 col-end-2 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md"
                    onClick={() => {
                      setCreateSchedule(false);
                      setDisplayDivSchedule(false);
                      setModeSchedule("create");
                      setIdSchedule(0);
                      setSelectedIdsInvoices((_prev) => []);
                      reset();
                    }}
                  >
                    Annuler{" "}
                  </button>
                  <button
                    className="w-full col-start-2 col-end-3 h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                    onClick={handleCreateSchedule}
                  >
                    {!loading ? (
                      <p>Créer</p>
                    ) : (
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#1C64F2"
                        />
                      </svg>
                    )}
                  </button>
                </div>

                {/* TABLEAU D ECHEANCES */}
                <div className="flex flex-col justify-start items-start col-start-1 col-end-7">
                  <div className="w-full border-[1px] border-bc-gray-300 h-[300px] bg-white rounded-md overflow-scroll">
                    <div className="w-full flex justify-start items-center h-10 border-b-[1px] border-bc-gray-300 bg-grey-Array sticky top-0 z-10">
                      <div className="flex justify-center items-center w-[2%] mr-4"></div>
                      <p className="flex justify-start items-center w-[20%]">
                        Date échéance
                      </p>
                      <p className="flex justify-start items-center w-[10%]">
                        Montant
                      </p>
                      <p className="flex justify-start items-center w-[20%]">
                        Mode réglement
                      </p>
                      <p className="flex justify-start items-center w-[20%]">
                        Date réglement
                      </p>
                      <p className="flex justify-start items-center w-[20%]">
                        Domiciliation
                      </p>
                      <p className="flex justify-start items-center w-[20%]">
                        IBAN
                      </p>
                      <p className="flex justify-start items-center w-[10%]">
                        RUM
                      </p>
                    </div>
                    <div className="w-full flex flex-col justify-start items-center bg-white">
                      {schedules?.map((schedule: any) => (
                        <div className="w-full flex justify-start items-center h-10 border-b-[1px] border-bc-gray-300">
                          <div className="flex justify-center items-center w-[2%] mr-4">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              checked={idSchedule === schedule.AEC_REF}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e,
                                  schedule.AEC_REF,
                                  schedule.AEC_DATE,
                                  schedule.AEC_EMTTTC,
                                  schedule.AEC_DATE_BORDEREAU
                                )
                              }
                            />
                          </div>
                          <p className="flex justify-start items-center w-[20%] font-thin">
                            {dateFormattedFr(schedule?.AEC_DATE)}
                          </p>
                          <p className="flex justify-start items-center w-[10%] font-thin">
                            {schedule?.AEC_EMTTTC}
                          </p>
                          <p className="flex justify-start items-center w-[20%] font-thin">
                            {schedule?.AMR_LIBELLE}
                          </p>
                          <p className="flex justify-start items-center w-[20%] font-thin">
                            {schedule?.AEC_DATE_BORDEREAU !== null
                              ? dateFormattedFr(schedule?.AEC_DATE_BORDEREAU)
                              : null}
                          </p>
                          <p className="flex justify-start items-center w-[20%] font-thin">
                            {schedule?.ADO_INTITULE}
                          </p>
                          <p className="flex justify-start items-center w-[20%] font-thin">
                            {schedule?.AEC_RIB}
                          </p>
                          <p className="flex justify-start items-center w-[10%] font-thin">
                            {schedule?.AEC_RUM}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-start items-start col-start-1 col-end-2">
                  <p>Échéance:</p>
                  <input
                    type="date"
                    className="w-full border-[1px] rounded border-gray-300 bg-white h-9 p-1 z-20"
                    value={
                      dateSchedule instanceof Date &&
                      !isNaN(dateSchedule.getTime())
                        ? dateSchedule.toISOString().split("T")[0]
                        : ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue === "") {
                        setDateSchedule(null); // Ou une autre valeur par défaut
                      } else {
                        setDateSchedule(new Date(newValue));
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col justify-start items-start col-start-3 col-end-4">
                  <p>Montant:</p>
                  <input
                    type="number"
                    id="amount"
                    name="amount"
                    className="w-full border-[1px] rounded border-gray-300 bg-white h-9 p-1 z-20"
                    value={amountSchedule}
                    onChange={(e) => setAmoutSchedule(Number(e.target.value))}
                  />
                </div>
                <div className="flex flex-col justify-start items-start col-start-5 col-end-6">
                  <p>Réglé le:</p>
                  <input
                    type="date"
                    className="w-full border-[1px] rounded border-gray-300 bg-white h-9 p-1 z-20"
                    value={
                      dateBordereau instanceof Date &&
                      !isNaN(dateBordereau.getTime())
                        ? dateBordereau.toISOString().split("T")[0]
                        : ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (newValue === "") {
                        setDateBordereau(null); // Ou une autre valeur par défaut
                      } else {
                        setDateBordereau(new Date(newValue));
                      }
                    }}
                  />
                </div>
                <div className=" col-start-6 col-end-7 grid grid-cols-2 gap-2">
                  <button
                    className="w-full h-9 col-start-1 col-end-2 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md mt-6"
                    onClick={() => {
                      setIdSchedule(0);
                      reset();
                    }}
                  >
                    X
                  </button>
                  <button
                    className="w-full col-start-2 col-end-3 h-9  border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md mt-6"
                    onClick={() => handlePatchSchedule()}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateSchedule;
