import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useCreateFeesOrInvoices } from "../../../../../store/Invoice/buttonsStore";
import { useCreateFee } from "../../../../../store/Invoice/createFeeStore";
import { createBillableItemsStore } from "../../../../../store/Invoice/createBillableItemsStore";
import { useRightInvoiceContext } from "../../../../../context/RightInvoiceContext";
import {
  getBillableDisbursements,
  getBillableFormDatas,
  getTarifs,
} from "../../../../../utils/Invoice/fees";
import { getAllUsersWithInactive } from "../../../../../utils/user";
import { useUserContext } from "../../../../../context/UserContext";
import { amountsStore } from "../../../../../store/Invoice/amountsStore";
import Amounts from "../Amounts";

/**
 * @component
 * @name CreateFeeOrDeboursInt
 * @author Succi Iris
 * @date 2023
 * @description Création des honoraires et des débours internes
 */
const CreateFeeOrDeboursInt = () => {
  const { createFees, billableItem } = useCreateFeesOrInvoices();
  const { selectedFile, selectedProcessus, updateData } =
    createBillableItemsStore();
  const { id } = useUserContext();
  const {
    missions,
    setMissions,
    selectedMission,
    setSelectedMission,
    origins,
    setOrigins,
    selectedOrigin,
    setSelectedOrigin,
    libelles,
    setLibelles,
    selectedLibelle,
    setSelectedLibelle,
    subscriptions,
    setSubscriptions,
    selectedSubscription,
    setSelectedSubscription,
    comment,
    setComment,
    actors,
    setActors,
    selectedActor,
    setSelectedActor,
    setProvisionRequest,
    prices,
    setPrices,
    resetFee,
    modeFee,
  } = useCreateFee();
  const { withoutMissionRight } = useRightInvoiceContext();
  const {
    quantity,
    setQuantity,
    setUnitPrice,
    setHt,
    setTva,
    setTtc,
    resetAmounts,
  } = amountsStore();

  // States
  const [compteur, setCompteur] = useState(230);
  const textAreaRef = useRef(null);

  // Recupère les données pour les honoraires et les débours internes
  const fetchBillableFormDatas = () => {
    if (
      billableItem === "Honoraire" &&
      selectedFile !== 0 &&
      selectedProcessus !== 0
    ) {
      getBillableFormDatas(selectedFile, selectedProcessus)
        .then((res) => {
          // MISSIONS
          const formattedMissions = res.data.Missions.map((mission: any) => ({
            label:
              new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
              " - " +
              mission.MIS_LIBELLE,
            value: mission.MIS_REF,
            origins: mission.Origines,
            abonnementId: mission.ABN_REF,
            abonnementLibelle: mission.ABN_LIBELLES,
          }));
          setMissions(formattedMissions);
          // ORIGINS
          const formattedOrigins = res?.data?.Origines.map((origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
            libelles: origin.Libelles.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
              ePrixUnitaire: libelle.ALI_EPRIX_UNITAIRE,
              quantite: libelle.ALI_QUANTITE,
            })),
          }));
          setOrigins(formattedOrigins);

          if (origins.length === 1) {
            setSelectedOrigin(formattedOrigins[0].value);
            setLibelles(formattedOrigins[0].libelles);
            setSelectedLibelle(formattedOrigins[0].libelles[0].value);
          }
          // SUBSCRIPTIONS
          const formattedSubscriptions = res.data.Abonnements.map(
            (subscription: any) => ({
              label: subscription.ABN_LIBELLES,
              value: subscription.ABN_REF,
            })
          );
          setSubscriptions(formattedSubscriptions);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    fetchBillableFormDatas();
    getAllUsersWithInactive()
      .then((res) => {
        setSelectedActor(id);
        const formattedFiles = res.data
          .filter((actor: any) => actor.actif === 1)
          .map((actor: any) => ({
            label: actor.firstname + " " + actor.lastname,
            value: actor.identifiant,
          }));
        setActors(
          formattedFiles.sort((a: any, b: any) =>
            a.label.localeCompare(b.label)
          )
        );
      })
      .catch((err) => console.log(err));

    // Reset form if file item changes
    setSelectedMission(0);
    setSelectedOrigin("");
    setSelectedLibelle("");
    setComment("");
    setSelectedSubscription(0);
  }, [createFees, selectedFile, selectedProcessus]);

  useEffect(() => {
    if (billableItem === "Debours interne") {
      getBillableDisbursements(selectedFile).then((res) => {
        // MISSIONS
        const formattedMissions = res.data.Missions.map((mission: any) => ({
          label:
            new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
            " - " +
            mission.MIS_LIBELLE,
          value: mission.MIS_REF,
          origins: mission?.Origines,
          MIS_ALI_CODE_DBIFK: mission.MIS_ALI_CODE_DBIFK,
          MIS_EMTUNIT_FK: mission.MIS_EMTUNIT_FK,
        }));
        setMissions(formattedMissions);
        // ORIGINS
        const formattedOrigins = res?.data?.Origines.map((origin: any) => ({
          value: origin.AOR_REF,
          label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
          libelles: origin.Libelles.map((libelle: any) => ({
            value: libelle.ALI_CODE,
            label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
            ePrixUnitaire: libelle.ALI_EPRIX_UNITAIRE,
            quantite: libelle.ALI_QUANTITE,
          })),
        }));
        setOrigins(formattedOrigins);
        if (origins.length === 1) {
          setSelectedOrigin(formattedOrigins[0].value);
          setLibelles(formattedOrigins[0].libelles);
          setSelectedLibelle(formattedOrigins[0].libelles[0].value);
          setQuantity(formattedOrigins[0].libelles[0].quantite);
        }
        // SUBSCRIPTIONS
        const formattedSubscriptions = res.data.Abonnements.map(
          (subscription: any) => ({
            label: subscription.ABN_LIBELLES,
            value: subscription.ABN_REF,
          })
        );
        setSubscriptions(formattedSubscriptions);
      });
    }
  }, [createFees, selectedFile, selectedProcessus]);

  // Ce useEffect concerne les documentations des honoraires et des débours internes
  // Pour la condition : si la mission est selectionnée et qu'une origine est rattaché à la mission et
  // que le libellé est rattaché à l'origine alors je selectionne l'origine et le libellé de la mission
  useEffect(() => {
    if (
      selectedMission &&
      billableItem === "Honoraire" &&
      selectedMission !== 0
    ) {
      const selectedMissionData = missions?.find(
        (mission) => mission.value === selectedMission
      );
      // SET ORIGINS if mission has origins
      if (selectedMissionData?.origins?.length > 0) {
        const formattedOrigins = selectedMissionData?.origins?.map(
          (origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
            libelles: origin?.Libelle?.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
              ePrixUnitaire: libelle.ALI_EPRIX_UNITAIRE,
              quantite: libelle.ALI_QUANTITE,
            })),
          })
        );

        setOrigins(formattedOrigins);
        setSelectedOrigin(formattedOrigins[0].value);
        setLibelles(formattedOrigins[0].libelles);
        setSelectedLibelle(formattedOrigins[0].libelles[0].value);
        setQuantity(formattedOrigins[0].libelles[0].quantite);
        setUnitPrice(formattedOrigins[0].libelles[0].ePrixUnitaire);
      } else {
        setLibelles([]);
        setSelectedLibelle("");
        fetchBillableFormDatas();
      }
      // SET SUBSCRIPTIONS if mission has abonnement
      if (selectedMissionData?.abonnementId !== null) {
        const formattedSubscriptions = [
          {
            label: selectedMissionData.ABN_LIBELLES,
            value: selectedMissionData.ABN_REF,
          },
        ];
        setSubscriptions(formattedSubscriptions);
      }
    } else if (selectedMission && billableItem === "Debours interne") {
      const selectedMissionData = missions?.find(
        (mission) => mission.value === selectedMission
      );
      if (selectedMissionData?.MIS_ALI_CODE_DBIFK !== null) {
        setUnitPrice(selectedMissionData?.MIS_EMTUNIT_FK);
      }
    }
  }, [selectedMission]);

  // Cette fonction permet de gérer le changement de la valeur du texte area et de mettre à jour le compteur
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let nouveauTexte = e.target.value;
    if (nouveauTexte.length > 230) {
      nouveauTexte = comment;
    }
    const nouveauCompteur = 230 - nouveauTexte.length;
    setComment(nouveauTexte);
    setCompteur(nouveauCompteur);
  };

  /**
   * @author Succi Iris
   * @date 2023
   * @description Gere le changement de l'origine et met à jour les libellés
   */
  const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrigin = event.target.value;
    setSelectedOrigin(selectedOrigin);
    const selectedOriginObj = origins?.find(
      (origin) => origin.value.toString() === selectedOrigin
    );

    if (selectedOriginObj) {
      setLibelles(selectedOriginObj.libelles);
    } else {
      setLibelles([]);
    }
  };

  // Cette fonction permet de mettre à jour les données des honoraires et des débours internes
  useEffect(() => {
    if (Object.keys(updateData).length > 0 && modeFee === "edit") {
      if (missions.length > 0) {
        const selectedMission = missions?.find(
          (mission: any) => mission.value === updateData.MIS_REF
        );
        setSelectedMission(selectedMission?.value || 0);
      }
  
      setSelectedActor(updateData?.ANA_OPERATEUR);
  
      const selectedOrigin = origins?.find(
        (origin: any) => origin.value === updateData.AOR_REF
      );
      setSelectedOrigin(selectedOrigin?.value || "");
  
      if (selectedOrigin) {
        const updatedLibelles = selectedOrigin.libelles;
        setLibelles(updatedLibelles);
  
        if (updatedLibelles?.length > 0 && updateData.ANA_CODELIB) {
  
          const selectedLibelle = updatedLibelles.find(
            (libelle: any) => String(libelle.value).trim() === String(updateData.ANA_CODELIB).trim()

          );
          setSelectedLibelle(selectedLibelle?.value || "");
        }
      }
  
      setComment(updateData.ANA_NOTES);
      setSelectedSubscription(updateData.ABN_REF);
      setHt(updateData.ANA_EMTHT);
      setTva(updateData.ANA_EMTTVA);
      setQuantity(updateData.ANA_QUANTITE);
      setUnitPrice(updateData.ANA_EPRIXUNITAIRE);
      setTtc(updateData.ANA_EMTTTC);
      setProvisionRequest(updateData.ANA_DP);
    }
  }, [missions, origins, updateData, modeFee]);
  

  useEffect(() => {
    if (selectedFile === 0 || selectedProcessus === 0) {
      resetFee();
      resetAmounts();
    }
  }, [selectedFile, selectedProcessus]);

  // Ce useEffect concerne les documentations des honoraires et des débours internes en ce qui
  // concerne les tarifs des libellés
  useEffect(() => {
    const fetchTarifs = async () => {
      if (selectedLibelle !== "" && selectedOrigin !== "") {
        try {
          const response = await getTarifs(selectedOrigin, selectedLibelle);
          const tarifs = response.data;
          setPrices(tarifs);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchTarifs();
  }, [selectedLibelle, selectedOrigin, quantity]);

  useEffect(() => {
    if (prices?.length > 0) {
      if (quantity > 0) {
        // Trouver le prix correspondant exact si disponible
        let priceMatch = prices.find(
          (price) => quantity === price.ALIT_QUANTITE
        );

        // Si aucun prix exact trouvé, chercher la quantité inférieure la plus proche
        if (!priceMatch) {
          priceMatch = prices
            .filter((price) => price.ALIT_QUANTITE <= quantity)
            .reduce(
              (closest, price) =>
                price.ALIT_QUANTITE > closest.ALIT_QUANTITE ? price : closest,
              { ALIT_QUANTITE: 0, ALIT_EPRIX_UNITAIRE: 0 }
            );
        }

        setUnitPrice(priceMatch?.ALIT_EPRIX_UNITAIRE);
      }
    }
  }, [prices, quantity]);

  return (
    (billableItem === "Honoraire" || billableItem === "Debours interne") && (
      <div className="w-full flex flex-col justify-start items-center">
        <div className="w-full grid grid-cols-12 2xl:gap-5 gap-2 mt-8 ">
          <p className="col-start-1 col-end-3">
            <label htmlFor="mission">Mission:</label>
          </p>
          <select
            name="mission"
            id="mission"
            className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedMission}
            disabled={withoutMissionRight === 1}
            onChange={(event) => {
              setSelectedMission(Number(event.target.value));
            }}
          >
            <option value={""}></option>
            {missions?.map((mission, i) => (
              <option key={i} value={mission?.value}>
                {mission?.label}
              </option>
            ))}
          </select>
          <p className="col-start-7 col-end-9">
            <label htmlFor="mission">Abonnement:</label>
          </p>
          <select
            name="abonnement"
            id="abonnement"
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedSubscription}
            disabled={subscriptions.length === 0}
            onChange={(event) => {
              setSelectedSubscription(Number(event.target.value));
            }}
          >
            <option value={""}></option>
            {subscriptions?.map((subscription, i) => (
              <option key={i} value={subscription?.value}>
                {subscription?.label}
              </option>
            ))}
          </select>
          <p className="col-start-1 col-end-3">
            <label htmlFor="mission">Origine:</label>
          </p>
          <select
            name="origins"
            id="origins"
            className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedOrigin}
            onChange={handleOriginChange}
            disabled={origins?.length === 1}
          >
            <option value={""}></option>
            {origins?.map((origin, i) => (
              <option key={i} value={origin?.value}>
                {origin?.label}
              </option>
            ))}
          </select>
          <p className="col-start-7 col-end-9">
            <label htmlFor="mission">Libellé:</label>
          </p>
          <select
            name="libelle"
            id="libelle"
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedLibelle}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedLibelle(selectedValue);

              const selectedLibelleObject = libelles.find(
                (libelle) => libelle.value === selectedValue
              );
              if (selectedLibelleObject && billableItem === "Honoraire") {
                setQuantity(selectedLibelleObject.quantite);
                setUnitPrice(selectedLibelleObject.ePrixUnitaire);
              }
            }}
          >
            <option value={""}></option>
            {libelles?.map((libelle, i) => (
              <option key={i} value={libelle?.value}>
                {libelle?.label}
              </option>
            ))}
          </select>
          <p className="col-start-1 col-end-3">
            <label htmlFor="mission">Commentaire:</label>
          </p>
          <div className="col-start-3 col-end-7 mt-1 flex flex-col">
            <textarea
              name="Comm"
              id="Comm"
              value={comment}
              ref={textAreaRef}
              onChange={handleChange}
              className="w-full border-[1px] rounded border-gray-300 h-20 py-1 z-20 "
            />
            <div className="w-full flex justify-end items-end pt-1 pr-2">
              <p className="text-gray-400 text-xs">
                Il vous reste {compteur} caractère(s)
              </p>
            </div>
          </div>
          <p className="col-start-7 col-end-9">
            <label htmlFor="mission">Acteur:</label>
          </p>
          <select
            name="actor"
            id="actor"
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedActor}
            onChange={(event) => {
              setSelectedActor(event.target.value);
            }}
          >
            <option value={""}></option>
            {actors?.map((actor, i) => (
              <option key={i} value={actor?.value}>
                {actor?.label}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full mt-5">
          <Amounts />
          {/* <div className="flex justify-start items-center">
            <input
              type="checkbox"
              name="provision"
              id="provisionr"
              className="mr-4"
              checked={provisionRequest === 1}
              onChange={(event) =>
                setProvisionRequest(event.target.checked ? 1 : 0)
              }
            />
            <p className="text-sm">Demande de provision</p>
          </div> */}
        </div>
      </div>
    )
  );
};

export default CreateFeeOrDeboursInt;
