import "/src/css/loader.css";

/**
 * @name CircleLoarder
 * @description
 * Loader en forme de cercle
 * @date 13/02/2024
 * @author Iris Succi
 */
const CircleLoarder = () => {
  return (
    <div className="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default CircleLoarder;
