import { useEffect, useState } from "react";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { getParticipantsCategories } from "../../../../utils/participants";
import ParticipantsTree from "./ParticipantsTree";
import CreateIntervenant from "../../buttons/CreateIntervenant";
import CreateSousFiche from "../../buttons/CreateSousFiche";
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";

/**
 * @component
 * @name ExplorerParticipantsCategories
 * @description Composant qui affiche la liste des participants et permet de les ouvrir pour afficher les sous-fiches associées.
 * @author Iris Succi
 * @date 2023
 */
const ExplorerParticipantsCategories: React.FC<any> = () => {
  const {
    PDOS_ID,
    participantsCategories,
    setParticipantsCategories,
    clientFileName,
  } = useExplorer();
  const { choiceTab } = useUtilsCrmStore();

  // L'état pour garder la trace de l'onglet ouvert
  const [openParticipanCategorytId, setParticipantCategoryId] = useState(null);

  const handleToggleParticipant = (participantCategoryId: any) => {
    if (openParticipanCategorytId === participantCategoryId) {
      // Si le même onglet est cliqué, fermez-le
      setParticipantCategoryId(null);
    } else {
      // Sinon, ouvrez le nouvel onglet
      setParticipantCategoryId(participantCategoryId);
    }
  };

  useEffect(() => {
    getParticipantsCategories(PDOS_ID)
      .then((res) => setParticipantsCategories(res))
      .catch((err) => console.log(err));
  }, []);
  
  return (
    <div className="flex flex-col w-[98%] h-full overflow-y-scroll border-[1px] rounded-md mt-2 pt-2 bg-bc-lightGrey items-center">
      {clientFileName !== "" ? clientFileName : null}
      <hr className="my-2 w-6/12 m-auto" />
      <div className="flex justify-start items-center">
        {choiceTab === "Intervenant" ? (
          <CreateIntervenant />
        ) : choiceTab === "SousFiche" ? (
          <CreateSousFiche />
        ) : null}
      </div>
      {participantsCategories?.map((category) => (
        <ParticipantsTree
          key={category?.PENT_ID}
          category={category}
          isOpen={openParticipanCategorytId === category?.PENT_ID}
          onToggle={() => handleToggleParticipant(category?.PENT_ID)}
        />
      ))}
    </div>
  );
};

export default ExplorerParticipantsCategories;
