import { useUtilsAnalyseStore } from "../../store/Analyse/utilsAnalyse";
/**
 * @author Iris Succi
 * @components ChoiceChart
 * @description Composant qui permet de choisir entre Evolution et Répartition
 * @param none
 * @date 23/01/2024
 */
const ChoiceChart = () => {
  const { choiceTabEvoOrRev, setChoiceTabEvoOrRev } = useUtilsAnalyseStore();

  return (
    <div className="border-b-[1px] border-bc-orange w-11/12 m-auto flex justify-start items-center">
      <button className={`border-t-[1px] border-r-[1px] border-l-[1px] border-bc-orange rounded-tl-sm px-3 ${choiceTabEvoOrRev === "Evolution" ? "bg-bc-orange text-white" : ""}`} onClick={() => setChoiceTabEvoOrRev("Evolution")}>Evolution</button>     
      <button className={`border-t-[1px] border-r-[1px] border-bc-orange rounded-tr-sm px-3 ${choiceTabEvoOrRev === "Repartition" ? "bg-bc-orange text-white" : ""}`} onClick={() => setChoiceTabEvoOrRev("Repartition")}>Répartition</button>
    </div>
  )
}

export default ChoiceChart
