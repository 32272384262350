import { useEffect, useRef } from "react";
import {
  getOneParticipant,
  getParticipantsFields,
  updateParticipant,
} from "../../../../utils/participants";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { useSousFicheStore } from "../../../../store/Crm/SousFiche";
import { useLoadingStore } from "../../../../store/utils/loading";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
import RenderSousFicheEC from "./RenderSousFicheEC";
import RenderSousFicheGaranties from "./RenderSousFicheGaranties";
import { errorFetch, sousFicheUpdated } from "../../../../utils/toaster";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";

const UpdateSousFiche = () => {
  const { ficRefSousFiche, PDOS_ID, dosRef, updateExplorer, setUpdateExplorer} = useExplorer();
  const {
    data,
    setData,
    setLocalCity,
    setLocalCp,
    setFields,
    choiceTypeSousFiche,
  } = useSousFicheStore();
  const { isLoading, setIsLoading } = useLoadingStore();
  const scrollableDivRef = useRef<HTMLDivElement>(null);
  const { setChoiceCreate } = useIntervenantStore();

  useEffect(() => {
    setIsLoading(true);
    getOneParticipant(ficRefSousFiche)
      .then((res) => {
        setData(res.data);
        setLocalCity(res.data["Adresse principale (Ville)"]);
        setLocalCp(res.data["Adresse principale (CP)"]);
       
        // Récupère les champs de la sous-fiche
        getParticipantsFields(res.data?.PFIC_ID, res.data?.PENT_ID, PDOS_ID)
          .then((res) => {
            setFields(res.data);
            setIsLoading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ficRefSousFiche]);

  const handleUpdate = () => {
    setIsLoading(true);

    // retire le DOS_CODE
    const { DOS_CODE, ...dataToUpdate } = data;
    const dataUpdate = {
      ...dataToUpdate,
      PDOS_ID,
      PENT_ID: dataToUpdate.PENT_ID,
      PFIC_ID: dataToUpdate.PFIC_ID,
      DOS_REF: dosRef,
    };
    updateParticipant(dataUpdate)
      .then(() => {
        setChoiceCreate("");
        sousFicheUpdated();
        setUpdateExplorer(!updateExplorer);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        errorFetch();
        setIsLoading(false);
      });
  };

  const reset = () => {
    setData({});
    setChoiceCreate("");
  };

  return !isLoading ? (
    <div
      ref={scrollableDivRef}
      className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-t-none rounded-b-md overflow-y-scroll text-sm"
    >
      <h2 className="my-5 text-xl font-bold">
        {choiceTypeSousFiche === "Expert comptable"
          ? "Expert comptable"
          : choiceTypeSousFiche === "Garanties"
          ? "Garanties"
          : null}
      </h2>
      <div className="flex flex-col justify-start items-start w-full">
        {/* Affiche tous les inputs */}
        {choiceTypeSousFiche === "Expert comptable" ? (
          <RenderSousFicheEC />
        ) : choiceTypeSousFiche === "Garanties" ? (
          <RenderSousFicheGaranties />
        ) : null}

        <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
          <hr className="col-start-2 col-end-5 my-2" />
          <div className="col-start-4 col-end-5 w-2/3 mb-5">
            <button
              className="w-full mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
              onClick={() => reset()}
            >
              Fermer
            </button>
          </div>
          <div className="col-start-5 col-end-6 w-2/3 mb-5">
            <button
              className="w-full mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
              onClick={() => handleUpdate()}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="h-full w-full col-start-1 col-end-6 flex justify-center items-center">
      <CircleLoarder />
    </div>
  );
};

export default UpdateSousFiche;
