import { debounce } from "lodash";
import { useCallback } from "react";
import { useClientFileStore } from "../../../../store/Crm/ClientFile";
import { handlePhoneChange } from "../../../../utils/functionService";
import CurrencyInput from "react-currency-input-field";

const RenderClientFile = () => {
  const {
    varFiches,
    setCp,
    setCity,
    fields,
    varFilesDossier,
    implicitGroups,
    selectedGroup,
    setSelectedGroup,
    data,
    setData,
    localCity,
    setLocalCity,
    localCp,
    setLocalCp,
    selectedValues,
    setSelectedValues,
    inputValues,
    setInputValues,
  } = useClientFileStore();

  // Fonction pour générer les champs en fonction du type de champ (text, textarea, date, choice-list, phone)
  const inputType = (
    type: string,
    name: string,
    getter: any,
    setter: any,
    options?: any
  ) => {
    switch (type) {
      case "text":
        if (name === "PM Capital en Euros" || name === "PM CA HT annuel") {
          return (
            <CurrencyInput
              name={name}
              id={name}
              value={getter}
              decimalsLimit={2}
              className="border-[1px] rounded-md w-full p-2"
              suffix=" €"
              onValueChange={(value) => setter(value)}
            />
          );
        }
        return (
          <input
            type="text"
            name={name}
            id={name}
            onChange={(e) => setter(e.target.value)}
            value={getter}
            className="border-[1px] rounded-md w-full p-2"
          />
        );
      case "textarea":
        return (
          <textarea
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full h-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          />
        );
      case "date":
        return (
          <input
            type="date"
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          />
        );
      case "choice-list":
        return (
          <select
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          >
            <option value="" className="text-slate-300 "></option>
            {options?.map((option: any, i: any) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "phone":
        return (
          <input
            type="tel"
            name={name}
            id={name}
            onChange={(e) => handlePhoneChange(e, setter)} // Appelle la fonction handlePhoneChange pour creer un espace
            value={getter}
            className="border-[1px] rounded-md w-full p-2"
          />
        );
      default:
        return "text";
    }
  };

  // Fonction débouncée pour mettre à jour le code postal
  const debounceSetCp = useCallback(
    debounce((value) => setCp(value), 500),
    []
  );

  // Fonction débouncée pour mettre à jour la ville
  const debounceSetCity = useCallback(
    debounce((value) => setCity(value), 500),
    []
  );

  // Fonction pour gérer la sélection d'une option
  const handleSelectChange = (name: string, value: string) => {
    setSelectedValues(name, value);
  };

  // Fonction qui affiche les selects de chaque element avec les valeurs correspondantes
  const handleDisplayVarDos = (): JSX.Element | null => {
    if (varFilesDossier?.length > 0) {
      return (
        <>
          {varFilesDossier?.map((variable: any) => (
            <div key={variable.PVD_ID} className="w-full flex flex-col">
              <p>{variable.PVD_NAME}</p>
              <select
                name={variable.PVD_NAME}
                id={variable.PVD_ID}
                value={selectedValues[variable.PVD_ID] || ""}
                className="border-[1px] rounded-md w- full p-2"
                onChange={(e) =>
                  handleSelectChange(variable.PVD_ID, e.target.value)
                } // Mise à jour du state
              >
                <option value="" className="text-slate-300 "></option>
                {variable.PVDL?.map((item: any) => (
                  <option
                    key={item.PVDL_CODE}
                    value={item.PVDL_CODE + ";" + item?.PVDL_LIBELLE}
                  >
                    {item?.PVDL_LIBELLE}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  const handleChange = (id: any, value: string) => {
    setInputValues(id, value);
  };

  //Fonction qui affiche les valeurs
  const handleDisplayVarFiche = (): JSX.Element | null => {
    if (varFiches?.length > 0) {
      return (
        <>
          {varFiches?.map((variable: any, index: number) => (
            <div className="w-full flex flex-col" key={index}>
              <p>{variable.name}</p>
              {variable.type === "Texte" ? (
                <input
                  type="text"
                  className="border-[1px] rounded-md w-full p-2"
                  value={inputValues[variable.id] || ""}
                  onChange={(e) => handleChange(variable.id, e.target.value)}
                />
              ) : variable.type === "Date" ? (
                <input
                  type="date"
                  className="border-[1px] rounded-sm w-1/2 p-2"
                  value={inputValues[variable.id] || ""}
                  onChange={(e) => handleChange(variable.id, e.target.value)}
                />
              ) : variable.type === "Liste de valeurs" ? (
                <select
                  name={variable.name}
                  id={variable.name}
                  className="border-[1px] rounded-sm w-1/2 p-2"
                  value={inputValues[variable.id] || ""}
                  onChange={(e) => handleChange(variable.id, e.target.value)}
                >
                  {variable.values.map((items: any, i: any) => (
                    <option key={i} value={items}>
                      {items}
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          ))}
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Nom / Dénomination sociale</p>
      </div>
      <div className="col-start-2 col-end-3" id="Nom / Dénomination sociale">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Nom / Dénomination sociale"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>
      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Groupe implicite </p>{" "}
      </div>
      <div className="col-start-5 col-end-6" id="implicitegroup">
        <select
          name="implicitegroup"
          id="implicitegroup"
          value={selectedGroup}
          className="border-[1px] rounded-md w-full p-2"
          onChange={(e) => {
            setSelectedGroup(e.target.value);
          }}
        >
          <option value="" className="text-slate-300 "></option>
          {implicitGroups?.map((item: any, i: any) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <></>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Fonction dirigeant</p>
      </div>
      <div className="col-start-2 col-end-3" id="Fonction dirigeant">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Fonction dirigeant"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Appellation courrier</p>
      </div>
      <div className="col-start-5 col-end-6" id="Appellation courrier">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Appellation courrier"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Téléphone standard</p>
      </div>
      <div className="col-start-5 col-end-6" id="Téléphone standard">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Téléphone standard"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>
      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Adresse</p>
      </div>
      <div
        className="col-start-2 col-end-3 row-start-4 row-end-7 w-full"
        id="Adresse principale (Rue)"
      >
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Adresse principale (Rue)"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Téléphone direct</p>
      </div>
      <div className="col-start-5 col-end-6" id="Téléphone direct">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Téléphone direct"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Téléphone personnel</p>
      </div>
      <div className="col-start-5 col-end-6" id="Téléphone personnel">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Téléphone personnel"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Téléphone portable</p>
      </div>
      <div className="col-start-5 col-end-6" id="Téléphone Portable">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Téléphone Portable"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>CP/VILLE</p>
      </div>
      <div className="col-start-2 col-end-3 grid grid-cols-3 w-full gap-1">
        <div className="w-full col-start-1 col-end-2 mr-1">
          <input
            type="text"
            name="Adresse principale (CP)"
            value={localCp} // On utilise l'état local
            id="Adresse principale (CP)"
            disabled={false}
            onChange={(e) => {
              const value = e.target.value;
              setLocalCp(value); // Mets à jour l'input immédiatement
              debounceSetCp(value); // Mets à jour avec un délai
            }}
            className="border-[1px] rounded-md w-full p-2 "
          />
        </div>
        <div className="w-full col-start-2 col-end-4">
          <input
            type="text"
            name="Adresse principale (Ville)"
            value={localCity} // On utilise l'état local
            id="Adresse principale (Ville)"
            disabled={false}
            onChange={(e) => {
              const value = e.target.value;
              setLocalCity(value); // Mets à jour l'input immédiatement
              debounceSetCity(value); // Mets à jour avec un délai
            }}
            className="border-[1px] rounded-md w-full p-2"
          />
        </div>
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Télécopie</p>
      </div>
      <div className="col-start-5 col-end-6" id="Télécopie">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Télécopie");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Pays</p>
      </div>
      <div className="col-start-2 col-end-3" id="Adresse principale (Pays)">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Adresse principale (Pays)"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Email</p>
      </div>
      <div className="col-start-5 col-end-6" id="Email">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Email");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <hr className="col-start-2 col-end-5 my-2" />

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Forme juridique</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM Forme juridique">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Forme juridique"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Registre de commerce de</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM Lieu d'inscription RCS">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Lieu d'inscription RCS"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Numéro RCS</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM RCS Numéro">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "PM RCS Numéro");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Numéro SIRET</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM SIRET Numéro">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM SIRET Numéro"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Code APE</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM Code APE">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "PM Code APE");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Revenues catégoriels</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM Revenus categoriels">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Revenus categoriels"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Régime fiscal</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM Regime Fiscal">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Regime Fiscal"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-2 col-end-3 flex justify-start items-center">
        <p>Activité principale</p>
      </div>
      <div className="col-start-3 col-end-4" id="PM Activité">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "PM Activité");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <hr className="col-start-2 col-end-5 my-2" />

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Capital</p>
      </div>
      <div className="col-start-2 col-end-3" id="PM Capital en Euros">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Capital en Euros"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Nombre de titres</p>
      </div>
      <div className="col-start-5 col-end-6" id="PM Nombre de titres">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Nombre de titres"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Mois de clôture</p>
      </div>
      <div
        className="col-start-2 col-end-3"
        id="PM Mois de fin d'exercice social"
      >
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Mois de fin d'exercice social"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>CA annuel</p>
      </div>
      <div className="col-start-5 col-end-6" id="PM CA HT annuel">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM CA HT annuel"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Regime TVA</p>
      </div>
      <div className="col-start-2 col-end-3" id="Regime TVA">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Regime TVA");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Nbre de Fact clients annuel</p>
      </div>
      <div
        className="col-start-5 col-end-6"
        id="PM Nbre de factures Clients annuel"
      >
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Nbre de factures Clients annuel"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Nbre de salariés</p>
      </div>
      <div className="col-start-2 col-end-3" id="PM Nbre Salariés">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "PM Nbre Salariés"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-4 col-end-5 flex justify-start items-center">
        <p>Nbre de Fact fournisseurs annuel</p>
      </div>
      <div
        className="col-start-5 col-end-6"
        id="PM Nbre de Factures Fournisseurs Annuel"
      >
        {(() => {
          const obj = fields?.find(
            (item: any) =>
              item.nom === "PM Nbre de Factures Fournisseurs Annuel"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <hr className="col-start-1 col-end-6 my-2" />

      {handleDisplayVarDos()}

      {handleDisplayVarFiche()}

      <hr className="col-start-1 col-end-6 my-2" />
      <></>
      <></>
      <></>
    </div>
  );
};

export default RenderClientFile;
