import { useEffect } from "react";
import { amountsForDEStore } from "../../../../../store/Invoice/amountsForDE";
import {
  calculateHtFromTtc,
  calculateHtFromTtcWithoutQuantity,
  calculateTVA,
} from "../../../../../utils/functionService";
import { getTVA } from "../../../../../utils/Invoice/fees";
import { useDEStore } from "../../../../../store/Invoice/createDEStore";

/**
 * @component
 * @name AmountsForDE
 * @author Succi Iris
 * @date 2023
 * @description Création des montants pour le debours externe
 */
const AmountsForDE = () => {
  const {
    quantityDE,
    unitPriceSell,
    setUnitPriceSell,
    setUnitPriceBuy,
    unitPriceBuy,
    htBuy,
    setHtBuy,
    htSell,
    setHtSell,
    tvaBuy,
    setTvaBuy,
    tvaSell,
    setTvaSell,
    tvaRate,
    setTvaRate,
    ttcBuy,
    setTtcBuy,
    ttcSell,
    setTtcSell,
    selectedTvaRateDE,
    setQuantityDE,
    setSelectedTvaRateDE,
    nsBuy,
    setNsBuy,
    nsSell,
    setNsSell,
    nsBlocked,
  } = amountsForDEStore();

  const { selectedLibelleDE } = useDEStore();

  // Call api recupération des taux de TVA
  useEffect(() => {
    getTVA()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.TVA_CLE,
          label: file.TVA_VALEUR,
        }));
        setTvaRate(formattedFiles);
      })
      .catch((err) => console.log(err));
    setSelectedTvaRateDE(2);
  }, []);

  const handleInputChange = (e: any, type: "achat" | "vente") => {
    const { name, value } = e.target;
    const isBuy = type === "achat";

    const setHt = isBuy ? setHtBuy : setHtSell;
    const setTva = isBuy ? setTvaBuy : setTvaSell;
    const setTtc = isBuy ? setTtcBuy : setTtcSell;
    const setUnitPrice = isBuy ? setUnitPriceBuy : setUnitPriceSell;
    const setNs = isBuy ? setNsBuy : setNsSell;

    if (name === `HT ${type}`) {
      const fieldValue = value === "" ? "" : parseFloat(value);
      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setTtc(0);
      } else {
        const { newTva, newTtc, newUnitPrice } = calculateTVA(
          fieldValue,
          selectedTvaRateDE,
          quantityDE
        );
        setTva(newTva.toFixed(2));
        setTtc(newTtc.toFixed(2));
        setUnitPrice(newUnitPrice.toFixed(2));
      }
    } else if (name === `TTC ${type}`) {
      const fieldValue = value === "" ? "" : parseFloat(value);
      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setHt(0);
        setNs(ttcBuy);
      } else if (nsBlocked !== 1) {
        const { newTva, newHt, newUnitPrice } = calculateHtFromTtc(
          fieldValue,
          selectedTvaRateDE,
          quantityDE
        );
        setTva(newTva.toFixed(2));
        setHt(newHt.toFixed(2));
        setUnitPrice(newUnitPrice.toFixed(2));
      } else if (nsBlocked === 1) {
        setNs(fieldValue);
        setTtc(fieldValue);
      }
    } else if (name === "txTva") {
      const fieldValue = parseFloat(value);
      setSelectedTvaRateDE(fieldValue);
    } else if (name === `NS ${type}`) {
      const fieldValue = value === "" ? 0 : parseFloat(value);
      const currentHt = isNaN(parseInt(isBuy ? htBuy : htSell))
        ? 0
        : parseInt(isBuy ? htBuy : htSell);

      if (selectedTvaRateDE === 3 && fieldValue !== 0) {
        const newNs = currentHt + fieldValue;
        setNs(newNs.toFixed(2));
        setTtc(newNs.toFixed(2));
        setHt(0);
        setTva(0);
      } else if (selectedTvaRateDE === 2) {
        const fieldValueNumber = isNaN(fieldValue) ? 0 : fieldValue;

        if (!isNaN(fieldValueNumber)) {
          const { newTva, newTtc, newUnitPrice } = calculateTVA(
            currentHt,
            selectedTvaRateDE,
            quantityDE
          );
          const ttcNumber = newTtc;
          const nsNumber = fieldValueNumber;

          const newNs = ttcNumber + nsNumber;

          setTtc(newNs.toFixed(2));
          setTva(newTva.toFixed(2));
          setUnitPrice(newUnitPrice.toFixed(2));
        } else {
          console.error("fieldValue is not a valid number");
        }
      }
    }
  };

  // Calculate HT, TVA and TTC for sell
  useEffect(() => {
    if (
      quantityDE > 0 &&
      unitPriceSell > 0 &&
      (nsBlocked === 0 || nsBlocked === null)
    ) {
      const newHt = quantityDE * unitPriceSell;
      const { newTva, newTtc } = calculateTVA(
        newHt,
        selectedTvaRateDE,
        quantityDE
      );
      setHtSell(newHt.toFixed(2));
      setTvaSell(newTva.toFixed(2));
      setTtcSell(newTtc.toFixed(2));
      setNsSell(0);
      if (selectedTvaRateDE === 3) {
        setNsSell(newHt.toFixed(2));
        setHtSell(0);
      }
    } else if (quantityDE > 0 && unitPriceSell > 0 && nsBlocked === 1) {
      const newHt = quantityDE * unitPriceSell;
      const { newTtc } = calculateTVA(newHt, selectedTvaRateDE, quantityDE);
      setTtcSell(newTtc.toFixed(2));
      setNsSell(newTtc.toFixed(2));
    }
  }, [quantityDE, unitPriceSell, selectedTvaRateDE, selectedLibelleDE]);

  // Calculate HT, TVA and TTC for buy
  useEffect(() => {
    if (
      quantityDE > 0 &&
      unitPriceBuy > 0 &&
      (nsBlocked === 0 || nsBlocked === null)
    ) {
      const newHt = quantityDE * unitPriceBuy;
      const { newTva, newTtc } = calculateTVA(
        newHt,
        selectedTvaRateDE,
        quantityDE
      );
      setHtBuy(newHt.toFixed(2));
      setTvaBuy(newTva.toFixed(2));
      setTtcBuy(newTtc.toFixed(2));
      setNsBuy(0);
      if (selectedTvaRateDE === 3) {
        setNsBuy(newHt.toFixed(2));
        setHtBuy(0);
      }
    } else if (quantityDE > 0 && unitPriceBuy > 0 && nsBlocked === 1) {
      const newHt = quantityDE * unitPriceBuy;
      const { newTtc } = calculateTVA(newHt, selectedTvaRateDE, quantityDE);
      setNsBuy(newTtc.toFixed(2));
      setTtcBuy(newTtc.toFixed(2));
    }
  }, [quantityDE, unitPriceBuy, selectedTvaRateDE, selectedLibelleDE]);

  useEffect(() => {
    // ACHAT SANS QUANTITE NI PRIX UNITAIRE
    if (selectedTvaRateDE === 2 && (quantityDE === null || quantityDE == 0)) {
      const { newHt, newTva } = calculateHtFromTtcWithoutQuantity(
        ttcBuy,
        selectedTvaRateDE
      );
      if (!isNaN(newHt) && !isNaN(newTva)) {
        setTvaBuy(newTva.toFixed(2));
        setHtBuy(newHt.toFixed(2));
        setNsBuy(0);
      }
    }

    if (selectedTvaRateDE === 3) {
      setTtcBuy(htBuy);
      setNsBuy(htBuy);
      setTvaBuy(0);
      setHtBuy(0);
      setTtcSell(htSell);
      setNsSell(htSell);
      setTvaSell(0);
      setHtSell(0);
    }

    // VENTE SANS QUANTITE NI PRIX UNITAIRE
    if (selectedTvaRateDE === 2 && (quantityDE === null || quantityDE == 0)) {
      const { newHt, newTva } = calculateHtFromTtcWithoutQuantity(
        ttcSell,
        selectedTvaRateDE
      );
      if (!isNaN(newHt) && !isNaN(newTva)) {
        setTvaSell(newTva.toFixed(2));
        setHtSell(newHt.toFixed(2));
        setNsSell(0);
      }
    }
  }, [selectedTvaRateDE]);

  return (
    <div className="w-full grid grid-cols-6">
      <div className="col-start-1 col-end-2"></div>
      <div className="flex justify-start items-center col-start-2 col-end-4 mb-2">
        <p className="mr-2">Qté:</p>
        <input
          type="number"
          name="quantity"
          id="quantity"
          value={quantityDE}
          className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          onChange={(e) => {
            setQuantityDE(parseInt(e.target.value));
            handleInputChange(e, "achat");
          }}
        />
      </div>
      <div className="flex justify-start items-center col-start-5 col-end-7 mb-2">
        <p className="mr-2 ">Taux:</p>
        <select
          name="txTva"
          id="txTva"
          className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          value={selectedTvaRateDE}
          disabled={nsBlocked === 1}
          onChange={(e) => {
            const selectedValue = e.target.value;
            setSelectedTvaRateDE(Number(selectedValue));
            handleInputChange(e, "achat");
          }}
        >
          <option value={""}></option>
          {tvaRate?.map((tva, i) => (
            <option key={i} value={tva?.value}>
              {tva?.label} %
            </option>
          ))}
        </select>
      </div>
      <p className="col-start-1 col-end-2 text-red-400 pt-1 mb-2">Achat:</p>
      <div className="flex justify-start items-center col-start-2 col-end-3 mb-2">
        <p className="text-red-400 mr-2">P.U</p>
        <input
          type="number"
          name="PU achat"
          id="PU achat"
          value={unitPriceBuy}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          onChange={(e) => {
            setUnitPriceBuy(parseInt(e.target.value));
            handleInputChange(e, "achat");
          }}
        />
      </div>
      <div className="flex justify-start items-center col-start-3 col-end-4 mb-2">
        <p className="text-red-400 mr-2">H.T</p>
        <input
          type="number"
          name="HT achat"
          id="HT achat"
          disabled={nsBlocked === 1 || selectedTvaRateDE === 3}
          value={htBuy}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          onChange={(e) => {
            setHtBuy(e.target.value);
            handleInputChange(e, "achat");
          }}
        />
      </div>
      <div className="flex justify-start items-center col-start-4 col-end-5 mb-2">
        <p className="text-red-400 mr-2">T.V.A</p>
        <input
          type="number"
          name="TVA achat"
          id="TVA achat"
          disabled={nsBlocked === 1 || selectedTvaRateDE === 3}
          value={tvaBuy}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          onChange={(e) => handleInputChange(e, "achat")}
        />
      </div>
      <div className="flex justify-start items-center col-start-5 col-end-6 mb-2">
        <p className="text-red-400 mr-2">N.S</p>
        <input
          type="number"
          name="NS achat"
          id="NS achat"
          value={nsBuy}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          onChange={(e) => {
            setNsBuy(parseInt(e.target.value));
            handleInputChange(e, "achat");
          }}
        />
      </div>
      <div className="flex justify-start items-center col-start-6 col-end-7 mb-2">
        <p className="text-red-400 mr-2">T.T.C</p>
        <input
          type="number"
          name="TTC achat"
          id="TTC achat"
          value={ttcBuy}
          disabled={quantityDE > 0 && unitPriceBuy > 0}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          onChange={(e) => {
            setTtcBuy(parseInt(e.target.value));
            handleInputChange(e, "achat");
          }}
        />
      </div>
      <p className="col-start-1 col-end-2 text-green-700 pt-1">Vente:</p>
      <div className="flex justify-start items-center col-start-2 col-end-3">
        <p className="text-green-700 mr-2">P.U</p>
        <input
          type="number"
          name="PU vente"
          id="PU vente"
          value={unitPriceSell}
          onChange={(e) => {
            setUnitPriceSell(parseInt(e.target.value));
            handleInputChange(e, "vente");
          }}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
        />
      </div>
      <div className="flex justify-start items-center col-start-3 col-end-4 mb-2">
        <p className="text-green-700 mr-2">H.T</p>
        <input
          type="number"
          name="HT vente"
          id="HT vente"
          value={htSell}
          disabled={nsBlocked === 1 || selectedTvaRateDE === 3}
          onChange={(e) => {
            setHtSell(e.target.value);
            handleInputChange(e, "vente");
          }}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
        />
      </div>
      <div className="flex justify-start items-center col-start-4 col-end-5">
        <p className="text-green-700 mr-2">T.V.A</p>
        <input
          type="number"
          name="TVA vente"
          id="TVA vente"
          value={tvaSell}
          disabled={nsBlocked === 1 || selectedTvaRateDE === 3}
          onChange={(e) => handleInputChange(e, "vente")}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
        />
      </div>
      <div className="flex justify-start items-center col-start-5 col-end-6">
        <p className="text-green-700 mr-2">N.S</p>
        <input
          type="number"
          name="NS vente"
          id="NS vente"
          value={nsSell}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          onChange={(e) => {
            setNsSell(parseInt(e.target.value));
            handleInputChange(e, "vente");
          }}
        />
      </div>
      <div className="flex justify-start items-center col-start-6 col-end-7">
        <p className="text-green-700 mr-2">T.T.C</p>
        <input
          type="number"
          name="TTC vente"
          id="TTC vente"
          value={ttcSell}
          disabled={quantityDE > 0 && unitPriceSell > 0}
          onChange={(e) => {
            setTtcSell(parseInt(e.target.value));
            handleInputChange(e, "vente");
          }}
          className="w-20 border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
        />
      </div>
    </div>
  );
};

export default AmountsForDE;
