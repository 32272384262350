import { useTranslation } from "react-i18next";
import DatepickerForFilter from "../Invoice/modal/Filters/DatePickerForFilter";
import Select from "react-select";
import { filterInvoicesStore } from "../../store/Invoice/filterInvoicesStore";
import { useEffect } from "react";
import { getAllDossiers } from "../../utils/folders";
import { getMissionByDosRef } from "../../utils/missions";
import {
  getClientsByDosRef,
  getCosts,
  getOriginsByMisRef,
} from "../../utils/Cost/Cost";
import { errorConfig, nodatamatches } from "../../utils/toaster";
import { useLoadingStore } from "../../store/utils/loading";
import { useUserContext } from "../../context/UserContext";
import { SelectOptionDossier } from "../../interface/selects";
import { useCostStore } from "../../store/Cost/Cost";
/**
 * @component
 * @name FilterCost
 * @description
 * FilterCost est le composant qui permet de filtrer les frais.
 * @author Iris Succi
 * @date 2023
 */
const FilterCost = ({ closeModal }: any) => {
  // Translate
  const { t } = useTranslation();
  const { setIsLoading } = useLoadingStore();
  const { id } = useUserContext();
  // Store
  const {
    missions,
    setMissions,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    selectedMisRef,
    setSelectedMisRef,
    selectedFileOption,
    setSelectedFileOption,
    setSelectedOriginObject,
    selectedFile,
    setSelectedFile,
    selectedMission,
    setSelectedMission,
    selectedOrigin,
    setSelectedOrigin,
    selectedLibelle,
    setSelectedLibelle,
    fileOptions,
    setFileOptions,
    origins,
    setOrigins,
    libelles,
    setLibelles,
    setSelectedLibelleObject,
    clients,
    setClients,
    selectedClient,
    setSelectedClient,
  } = filterInvoicesStore();
  const { setCosts, sendCost, setSendCost } = useCostStore();

  /**
   * @function
   * @name deleteFilter
   * @description Supprime les données des filtres.
   */
  const deleteFilter = () => {
    setSelectedOrigin("");
    setSelectedLibelle("");
    setSelectedFile(0);
    setSelectedMission("");
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSelectedClient(0);
    setSelectedLibelleObject(null);
    setSelectedOriginObject(null);
    setSendCost(!sendCost);
    setSelectedFileOption(null);
    setClients([]);
    setSelectedClient(0);
    closeModal();
  };

  /**
   * @function
   * @name getAllDossiers
   * @description Call API pour recuperer les dossies 
   * @author Iris Succi
   */
  useEffect(() => {
    getAllDossiers()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.DOS_REF,
          label: file.FIC_TITRE_CLIENT,
        }));
        setFileOptions(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, []);
 
  // Recupere les clients en fonction du dossier selectionné
  useEffect(() => {
    if (selectedFile !== 0) {
      getClientsByDosRef(selectedFile)
        .then((res) => {
          const formattedClients = res.data.map((client: any) => ({
            value: client.FIC_REF,
            label: client.FIC_TITRE_ETAT,
            origin: client.FIC_OASPE_TP,
            tvaCle: client.TVA_CLE,
          }));
          setClients(formattedClients);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedFile]);

  // Recupere les missions en fonction du dossier selectionné
  useEffect(() => {
    if (selectedFile !== 0) {
      getMissionByDosRef(selectedFile)
        .then((res) => {
          const filteredMissions = res?.data.reduce(
            (missions: any[], mission: any) => {
              if (mission.MIS_DEBUT !== null) {
                const missionYear = new Date(mission.MIS_DEBUT).getFullYear();

                const existingMission = missions.find(
                  (item) => item.MIS_LIBELLE === mission.MIS_LIBELLE
                );

                if (existingMission) {
                  const existingMissionYear = new Date(
                    existingMission.MIS_DEBUT
                  ).getFullYear();
                  if (startDateFilter && endDateFilter) {
                    if (
                      Math.abs(missionYear - startDateFilter.getFullYear()) <
                      Math.abs(
                        existingMissionYear - startDateFilter.getFullYear()
                      )
                    ) {
                      missions = missions.filter(
                        (item) => item.MIS_LIBELLE !== mission.MIS_LIBELLE
                      );
                      missions.push(mission);
                    }
                  }
                } else {
                  missions.push(mission);
                }
              } else {
                missions.push(mission);
              }

              return missions;
            },
            []
          );

          const formattedMissions = filteredMissions.map((mission: any) => ({
            value: mission.MIS_CODE,
            label: mission.MIS_DEBUT
              ? `${new Date(mission.MIS_DEBUT).getFullYear()} - ${
                  mission.MIS_LIBELLE
                }`
              : mission.MIS_LIBELLE,
            misRef: mission.MIS_REF,
          }));

          setMissions(formattedMissions);
        })
        .catch((err: any) => console.log(err));
    }
  }, [selectedFile, startDateFilter]);

  // Recupere les origines en fonction de la mission selectionnée
  useEffect(() => {
    if (selectedMission !== "") {
      getOriginsByMisRef(Number(selectedMission), id, selectedClient)
        .then((res) => {
          const formattedOrigins = res.data.map((origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_LIBELLE,
            AOR_OPTION_VALORISABLE: origin.AOR_OPTION_VALORISABLE,
            libelles: origin.Libelles.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
              quantity: libelle.ALI_QUANTITE,
              note: libelle.ALI_NOTES,
              unitPrice: libelle.ALI_EPRIX_UNITAIRE,
              ALI_OPTION_SAISIE: libelle.ALI_OPTION_SAISIE,
            })),
          }));
          setOrigins(formattedOrigins);
          if (formattedOrigins.length > 0) {
            setSelectedOrigin(formattedOrigins[0]?.value || 0);
            setLibelles(formattedOrigins[0]?.libelles || []);
          }
        })
        .catch((err) => {
          console.log(err);
          errorConfig();
        });
    } else {
      setOrigins([]);
      setLibelles([]);
      setSelectedLibelle("");
    }
  }, [selectedMission]);

  const handleFilter = () => {
    setIsLoading(true);
    closeModal();
    const promise = getCosts();

    promise
      .then((res) => {
        let filteredData = res.data;
        // Vérifie si startDateFilter est null ou undefined
        if (startDateFilter && endDateFilter) {
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATEREALISATION);
            return date >= startDateFilter && date <= endDateFilter;
          });
        } else if (startDateFilter) {
          // Applique le filtre avec seulement startDateFilter
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATEREALISATION);
            return date >= startDateFilter;
          });
        } else if (endDateFilter) {
          // Applique le filtre avec seulement endDateFilter
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATEREALISATION);
            return date <= endDateFilter;
          });
        }

        // Filter by file
        if (selectedFile !== 0) {
          filteredData = filteredData.filter((item: any) => {
            return item?.DOS_REF === selectedFile;
          });
        }

        // Filter by client
        if (selectedClient !== 0) {
          filteredData = filteredData.filter((item: any) => {
            return item?.FIC_REF === selectedClient;
          });
        }

        // Filter by mission
        if (selectedMission !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.MIS_REF === selectedMisRef
          );
        }

        if (selectedOrigin !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.AOR_REF === selectedOrigin
          );
        }

        if (selectedLibelle !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.ANA_CODELIB === selectedLibelle
          );
        }

        setSelectedMission("");
        setSelectedOrigin("");
        setSelectedLibelle("");
        setSelectedFile(0);
        setSelectedMisRef(0);
        setFileOptions([]);
        setSelectedFileOption(null);
        setMissions([]);
        setOrigins([]);
        setLibelles([]);
        setStartDateFilter(null);
        setEndDateFilter(null);

        setCosts(filteredData);

        if (filteredData.length === 0) {
          nodatamatches();
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[55%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl text-center">{t("Filtre")} frais</h3>
            </div>
            {/*body*/}
            <div className="w-11/12 m-auto font-light mt-2 flex flex-col">
              <div className="flex w-[70%] m-auto justify-between items-center">
                <div className="flex justify-center items-center z-50">
                  <p className="mr-2 mb-2">{t("Du")} :</p>
                  <DatepickerForFilter
                    startDate={startDateFilter}
                    setStartDate={(date: Date | null) => {
                      if (date) setStartDateFilter(date);
                    }}
                  />
                </div>
                <div className="flex justify-center items-center z-50">
                  <p className="mr-2 mb-2">{t("Au")} :</p>
                  <DatepickerForFilter
                    startDate={endDateFilter}
                    setStartDate={(date: Date | null) => {
                      if (date) setEndDateFilter(date);
                    }}
                  />
                </div>
              </div>
              <hr className="w-8/12 m-auto mb-2" />
              <div className="flex flex-col w-10/12 m-auto justify-between items-center">
                <div className="grid grid-cols-5 w-full">
                  <div className="flex flex-col col-start-1 col-end-3">
                    <p className="mr-2">{t("Dossier")}: </p>
                    <Select
                      options={fileOptions}
                      value={selectedFileOption}
                      isClearable
                      className="w-full h-9 z-30 font-thin"
                      isSearchable
                      onChange={(
                        selectedOption: SelectOptionDossier | null
                      ) => {
                        const selectedValue = selectedOption?.value || 0;
                        setSelectedFile(selectedValue);
                        setSelectedFileOption(selectedOption);
                      }}
                    />
                  </div>
                  <div className="flex flex-col col-start-4 col-end-6">
                    <p className="mr-2">{t("Client")}: </p>
                    <Select
                      isClearable
                      className="w-full h-9 z-30"
                      isSearchable
                      value={clients}
                      onChange={(selectedClient: any | null) => {
                        setSelectedClient(selectedClient?.value || 0);
                        setSelectedOrigin(selectedClient?.origin || 0);
                      }}
                    />
                  </div>
                  <div className="flex flex-col col-start-1 col-end-3 mt-2 ">
                    <p className="mr-2">{t("Mission")}: </p>
                    <select
                      name="mission"
                      id="mission"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                      value={selectedMission}
                      onChange={(event) => {
                        const selectedValue = event.target.value || "";
                        const selectedMissionObject = missions.find(
                          (mission) => mission.value === selectedValue
                        );
                        if (selectedMissionObject) {
                          setSelectedMission(selectedMissionObject.value);
                          setSelectedMisRef(selectedMissionObject.misRef);
                          localStorage.setItem(
                            "selectedMission",
                            JSON.stringify(selectedMissionObject.value)
                          );
                        }
                      }}
                    >
                      <option value={""}></option>
                      {missions.map((mission) => (
                        <option value={mission?.value}>{mission?.label}</option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-1 col-end-3 my-2">
                    <p className="mr-3">{t("Origine")}: </p>
                    <select
                      name="Origine"
                      id="Origine"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                      value={selectedOrigin || ""}
                      onChange={(event) => {
                        setSelectedOrigin(event.target.value);
                        localStorage.setItem(
                          "selectedOrigin",
                          event.target.value
                        );
                        const originObject = origins.find(
                          (origin) => origin.label === event.target.value
                        );
                        setSelectedOriginObject(originObject);
                      }}
                    >
                      <option value={""}></option>
                      {origins.map((origin) => (
                        <option key={origin.value} value={origin.label}>
                          {origin.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col col-start-4 col-end-6 my-2">
                    <p className="mr-5">{t("Libellé")}: </p>
                    <select
                      name="Libelle"
                      id="Libelle"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                      onChange={(event) => {
                        setSelectedLibelle(event.target.value);
                        localStorage.setItem(
                          "selectedLibelle",
                          event.target.value
                        );
                        const libelleObjet = libelles.find(
                          (libelle) => libelle.label === event.target.value
                        );
                        setSelectedLibelleObject(libelleObjet);
                      }}
                    >
                      <option value={""}></option>
                      {libelles.map((libelle) => (
                        <option key={libelle.value} value={libelle.label}>
                          {libelle.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-bc-orange background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  deleteFilter();
                  closeModal();
                }}
              >
                {t("Effacer les filtres")}
              </button>
              <button
                className="text-bc-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleFilter}
              >
                {t("Valider")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default FilterCost;
