import axios from "axios";
import { axiosWithToken } from "../axiosService";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / patch / delete credits
 */

export const getUnvalidatedCredits = () => {
    try {
        const response = axiosWithToken().get(`api/credits/unvalidated`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getValidatedCredits = () => {
    try {
        const response = axiosWithToken().get(`api/credits/validated`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const postCredit = (data: any) => {
    try {
        const response = axiosWithToken().post(`api/credits`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const deleteCredit = (ids: any) => {
    try {
        const response = axiosWithToken().delete(`api/credits?ANA_REF=${ids}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getCreditById = (id: any) => {
    try {
        const response = axiosWithToken().get(`api/credits/${id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const updateCredit = (id: any, data: any) => {
    try {
        const response = axiosWithToken().patch(`api/credits/${id}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getAllCredits = () => {
    try {
        const response = axiosWithToken().get(`api/credits`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const validateCredits = (ids: any) => {
    const data = {
        ANA_REFs: ids
    }
    try {
        const response = axiosWithToken().post(`api/credits/validate`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}