import time from '../assets/icons/navbar/temps-passe.png';
import report from '../assets/icons/navbar/report.png';
import calendar from '../assets/icons/navbar/planning.png';
import parapheur from '../assets/icons/navbar/parapheur.png';
import invoice from '../assets/FACTURATION.png';
import frais from '../assets/icons/navbar/frais.png';

const dataMenu = [
{
    id: 1,
    logo: parapheur,
    title: 'CRM',
    path: '/crm',
    right: "EasyFile",
},{
    id: 2,
    logo: time,
    title: "GESTION DU TEMPS",
    path: '/timemanagement',
    right: "BuroTime",
},{
    id: 3,
    logo: calendar,
    title: 'CALENDRIER',
    path: '/calendar',
    right: "BuroCal",
},{
    id: 4,
    logo: invoice,
    title: 'FACTURATION',
    path: '/invoicing',
    right: "BuroFact",
},{
    id: 5,
    logo: frais,
    title: 'FRAIS',
    path: '/costs',
    right: "Notes de frais",
},{
    id: 6,
    logo: report,
    title: "REPORTING",
    path: '/financialstatement',
    right: "Etats",
}] 

export default dataMenu;
