const TasksToBeDone = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <h2 className="w-full border-2 bg-green-500 border-green-500 rounded-md text-white text-center">
        TACHES A REALISER
      </h2>
      <div className="w-full grid grid-cols-4 gap-1 mt-5">
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Dossier</p>
        </div>
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Libellé</p>
        </div>
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Etat</p>
        </div>
        <div className="w-full h-8 bg-black rounded-sm flex justify-center items-center">
          <p className="text-white">Deadline</p>
        </div>
      </div>
    </div>
  );
};

export default TasksToBeDone;
