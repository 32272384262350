import { useEffect, useRef } from "react";
import { usePagination } from "../../../hooks/usePagination";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { useLoadingStore } from "../../../store/utils/loading";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import CreateProformaWithFee from "../modal/Create/CreateProformaWithFee";
import VisuPdfModal from "../modal/VisuPdfModal";
import { useTabsStore } from "../../../store/Invoice/tabsStore";
import PointOutAcompte from "../modal/Pointer/PointOutAcompte";
import PointAcompte from "../modal/Pointer/PointAcompte";
import { formatNumber } from "../../../utils/functionService";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";

/**
 * @Component ProformatTable
 * @author Succi Iris
 * @date 2023
 * @description Ce composant affiche un tableau de proformas, permettant aux utilisateurs de les sélectionner et de les valider. Il gère également la pagination et la fonctionnalité de recherche.
 * @returns {JSX.Element} - The rendered component
 */
const ProformatTable = () => {
  const { proformats, proformatsFilter, setProformatsFilter } =
    dataInvoicesFeelsStore();
  const { search } = useSearchBarStore();
  const { isLoading } = useLoadingStore();
  const { displayDiv, displayDivVisuPdf, displayPointOutAcompteModal } =
    useCreateInvoiceStore();
  const { displayPointOutAcompteQuestion, setDisplayPointOutAcompteQuestion } =
    useTabsStore();
  const {
    selectedIdsInvoices,
    setSelectedIdsInvoices,
    setSelectedDosRef,
    setSelectedIdPrepayment,
  } = useCreateInvoiceStore();

  const scrollDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (displayDiv && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [displayDiv]);

  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(proformatsFilter, 30);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number,
    dosRef: number,
    relatedItems: { ANA_REF: number; AOR_PROCESSUS: number }[]
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, itemId]);
      setSelectedDosRef(dosRef);

      const relatedItemWithProcessus8 = relatedItems.find(
        (item) => item.AOR_PROCESSUS === 8
      );

      if (relatedItemWithProcessus8) {
        setDisplayPointOutAcompteQuestion(true);
        setSelectedIdPrepayment(relatedItemWithProcessus8.ANA_REF);
      }
    } else {
      setSelectedIdsInvoices((prev) => prev.filter((id) => id !== itemId));
      if (!selectedIdsInvoices.includes(itemId)) {
        setSelectedDosRef(null);
      }
    }
  };

  // Gestion de la recherche
  useEffect(() => {
    let data = proformats?.length > 0 ? [...proformats] : [...proformatsFilter];
    if (search !== "") {
      data = data.filter(
        (item) =>
          item["DOS_TITRE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["MIS_LIBELLE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["AOR_CODE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["ANA_CODELIB"]?.toLowerCase().includes(search.toLowerCase()) ||
          dateFormattedFr(item["ANA_DATEREALISATION"])
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item["ANA_EMTTTC"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trie par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.ANA_DATEREALISATION).getTime();
      const dateB = new Date(b.ANA_DATEREALISATION).getTime();
      return dateB - dateA; // Trie du plus récent au plus ancien
    });

    setProformatsFilter(data);
  }, [search, proformats]);

  return (
    <>
      <div
        className={`relative border-[1px] rounded-b-md w-full h-full  overflow-x-hidden`}
      >
        {displayPointOutAcompteQuestion ? <PointOutAcompte /> : null}
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : proformats.length > 0 ? (
          <div
            ref={scrollDivRef}
            className={`border-[1px] rounded-b-md w-full h-full ${
              displayDiv ? "overflow-y-hidden" : "overflow-y-scroll"
            }`}
          >
            <table className="w-full ">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0">
                <tr className="w-[2%] border-r-[1px] flex justify-center items-center"></tr>
                <tr className="w-[15%] border-r-[1px] p-2">Dossier</tr>
                <tr className="w-[15%] border-r-[1px] p-2">Mission</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Origine</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Libellé</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Acteur</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Px Unit.</tr>
                <tr className="w-[5%] border-r-[1px] p-2">Qté</tr>
                <tr className="w-[5%] border-r-[1px] p-2">HT</tr>
                <tr className="w-[5%] border-r-[1px] p-2">TVA</tr>
                <tr className="w-[5%] border-r-[1px] p-2">TTC</tr>
                <tr className="w-[6%] border-r-[1px] p-2">Date Saisie</tr>
                <tr className="w-[14%] border-r-[1px] p-2">Commentaire</tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs">
                {filteredData?.map((invoice, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex  ${
                      index % 2 === 0 ? "bg-grey-Array" : ""
                    }`}
                  >
                    <td className="w-[2%] border-r-[1px] flex justify-center items-start pt-2">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        value={invoice.id}
                        checked={selectedIdsInvoices.includes(invoice.ANA_REF)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            invoice.ANA_REF,
                            invoice.DOS_REF,
                            invoice.RELATED
                          )
                        }
                      />
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {invoice?.DOS_TITRE}
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {invoice?.MIS_LIBELLE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.AOR_CODE}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {invoice?.ANA_CODELIB}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {invoice?.ANA_REDACTEUR}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {invoice?.ANA_EPRIXUNITAIRE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {invoice?.ANA_QUANTITE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(invoice?.ANA_EMTHT)}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(invoice?.ANA_EMTTVA)}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(invoice?.ANA_EMTTTC)}
                    </td>
                    <td className="w-[6%] border-r-[1px] p-2">
                      {dateFormattedFr(invoice?.ANA_DATEREALISATION)}
                    </td>
                    <td className="w-[14%] border-r-[1px] p-2">
                      {invoice?.ANA_NOTES}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center w-full mt-5 italic">Aucune proformas</p>
        )}
        {displayDiv ? <CreateProformaWithFee /> : null}
        {displayDivVisuPdf ? <VisuPdfModal /> : null}
        {displayPointOutAcompteModal ? <PointAcompte /> : null}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default ProformatTable;
