import chart from "../../../assets/exemple/graph.png";

const Charts1 = () => {
  return (
    <div className="border-2 border-bc-orange rounded-md w-full m-auto h-96 flex flex-col justify-center items-center mr-10">
      <p className="text-black text-center">DASHBOARD</p>
      <img src={chart} alt="chart exemple" />
    </div>
  );
};

export default Charts1;
