import { useEffect, useRef } from "react";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { useLoadingStore } from "../../../store/utils/loading";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { usePagination } from "../../../hooks/usePagination";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import "../../../css/animations.css";
import CreateProformaWithFee from "../modal/Create/CreateProformaWithFee";
import { formatNumber } from "../../../utils/functionService";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";
import CreateBillableItems from "../modal/Create/BillableItems/CreateBillableItems";

/**
 * @Component FeesTable
 * @author Succi Iris
 * @date 2023
 * @description Ce composant affiche un tableau d honoraire, permettant aux utilisateurs de les sélectionner et de les valider. Il gère également la pagination et la fonctionnalité de recherche.
 */
const FeesTable = () => {
  // Store
  const { fees, feesFilter, setFeesFilter } = dataInvoicesFeelsStore();
  const { isLoading } = useLoadingStore();
  const { search } = useSearchBarStore();
  const { displayDiv, displayDivFee } = useCreateInvoiceStore();
  const {
    selectedIdsInvoices,
    setSelectedIdsInvoices,
    selectedDosRef,
    setSelectedDosRef,
    selectedMisRef,
    setSelectedMisRef,
    setSelectedMisName,
  } = useCreateInvoiceStore();

  const scrollDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (displayDiv && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [displayDiv]);

  // Hook for pagination
  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(feesFilter, 25);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number,
    dosRef: number,
    misRef: number,
    misName: string
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, itemId]);
      setSelectedDosRef(dosRef);
      setSelectedMisRef(misRef);
      setSelectedMisName(misName);
    } else {
      setSelectedIdsInvoices((prev) => prev.filter((id) => id !== itemId));
      if (!selectedIdsInvoices.includes(itemId)) {
        setSelectedDosRef(null);
      }
    }
  };

  // This is for display data with search bar
  useEffect(() => {
    let data = [...fees];
    if (search !== "") {
      data = data.filter(
        (item) =>
          item["DOS_TITRE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["MIS_LIBELLE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["AOR_CODE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["ANA_CODELIB"]?.toLowerCase().includes(search.toLowerCase()) ||
          dateFormattedFr(item["ANA_DATEREALISATION"])
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item["ANA_EMTTTC"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trie par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.ANA_DATEREALISATION).getTime();
      const dateB = new Date(b.ANA_DATEREALISATION).getTime();
      return dateB - dateA; // Trie du plus récent au plus ancien
    });

    setFeesFilter(data);
  }, [search, fees, displayDivFee]);

  return (
    <>
      <div
        className={`relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden`}
      >
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : fees.length > 0 ? (
          <div
            ref={scrollDivRef}
            className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll"
          >
            <table className="w-full">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0 z-10">
                <tr className="w-[2%] border-r-[1px] "></tr>
                <tr className="w-[10%] border-r-[1px] p-2 font-normal text-start">
                  Processus
                </tr>
                <tr className="w-[10%] border-r-[1px] p-2 font-normal text-start">
                  Dossier
                </tr>
                <tr className="w-[15%] border-r-[1px] p-2 font-normal text-start">
                  Mission
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Origine
                </tr>
                <tr className="w-[8%] border-r-[1px] p-2 font-normal text-start">
                  Libellé
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Date
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Acteur
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Px Unit.
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Qté
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  HT
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  Tx. TVA
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  TVA
                </tr>
                <tr className="w-[5%] border-r-[1px] p-2 font-normal text-start">
                  TTC
                </tr>
                <tr className="w-[10%] border-r-[1px] p-2 font-normal text-start">
                  Commentaire
                </tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs ">
                {/* This part is for display data */}
                {filteredData?.map((fee, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex h-auto ${
                      index % 2 === 0 ? "bg-grey-Array" : ""
                    } ${
                      selectedIdsInvoices.length > 0 &&
                      selectedDosRef &&
                      fee.DOS_REF !== selectedDosRef
                        ? "opacity-50"
                        : ""
                    }`}
                  >
                    <td className="w-[2%] border-r-[1px] flex justify-center items-start pt-2">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        disabled={
                          selectedIdsInvoices.length > 0 &&
                          !selectedIdsInvoices.includes(fee.ANA_REF) &&
                          selectedDosRef !== fee.DOS_REF &&
                          selectedMisRef !== fee.MIS_REF
                        }
                        checked={selectedIdsInvoices.includes(fee.ANA_REF)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            fee.ANA_REF,
                            fee.DOS_REF,
                            fee.MIS_REF,
                            fee.MIS_LIBELLE
                          )
                        }
                      />
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {fee?.AOR_PROCESSUS[0]?.text}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {fee?.DOS_TITRE}
                    </td>
                    <td className="w-[15%] border-r-[1px] p-2">
                      {fee?.MIS_LIBELLE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {fee?.AOR_CODE}
                    </td>
                    <td className="w-[8%] border-r-[1px] p-2">
                      {fee?.ANA_CODELIB} - {fee?.ANA_LIBELLELIB}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {dateFormattedFr(fee?.ANA_DATEREALISATION)}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {fee?.ANA_REDACTEUR}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {fee?.ANA_EPRIXUNITAIRE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {fee?.ANA_QUANTITE}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(fee?.ANA_EMTHT)}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {fee?.TVA_VALEUR} %
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(fee?.ANA_EMTTVA)}
                    </td>
                    <td className="w-[5%] border-r-[1px] p-2">
                      {formatNumber(fee?.ANA_EMTTTC)}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {fee?.ANA_NOTES}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center w-full mt-5 italic">Aucun honoraire</p>
        )}
        {/* This is modale for create invoice */}
        {displayDiv ? <CreateProformaWithFee /> : null}
        {displayDivFee ? <CreateBillableItems /> : null}
      </div>
      <>
        {" "}
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default FeesTable;
