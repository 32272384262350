/* eslint-disable react-hooks/exhaustive-deps */
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";
import User from "../../../../assets/icons/user.svg";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";
import { useState } from "react";
import CategoriesTree from "../SousFiches/CategoriesTree";
/**
 * @component
 * @name InterlocuteursTree
 * @description Component affichant les interlocuteurs dans l'arborescence.
 * @author Iris Succi
 * @date 2023
 */
const InterlocuteursTree = ({
  intervenants,
  onSelected,
  selectedParticipant,
}: any) => {
  const { setChoiceTab } = useUtilsCrmStore();
  const {
    setFicRefIntervenant,
    ficRefIntervenant,
    setSelectedSousFiche,
    setSousFiches,
  } = useExplorer();
  const { setChoiceCreate } = useIntervenantStore();

  // L'état pour garder la trace de l'onglet ouvert
  const [openCategorytId, setCategoryId] = useState(null);

  const handleToggleParticipant = (categoryId: any) => {
    if (openCategorytId === categoryId) {
      // Si le même onglet est cliqué, fermez-le
      setCategoryId(null);
    } else {
      // Sinon, ouvre le nouvel onglet
      setCategoryId(categoryId);
    }
  };

  return (
    <div className="w-[97%] m-auto flex flex-col my-2">
      <div className="relative">
        <div className="flex items-center">
          <button
            className={`flex items-center pl-4`}
            onClick={() => {
              setChoiceTab("Intervenant");
              setChoiceCreate("UpdateIntervenant");
              setFicRefIntervenant(intervenants?.FIC_REF);
              setSelectedSousFiche(null);
              setSousFiches([]);
              onSelected(intervenants?.FIC_REF);
            }}
          >
            <p className="text-gray-200 text-xl font-bold mr-2 ">-</p>
            <div
              className={`flex justify-start items-center cursor-pointer ${
                selectedParticipant === intervenants.FIC_REF
                  ? "bg-bc-lightOrange border-2 rounded-md border-bc-lightOrange   pr-2"
                  : ""
              }`}
            >
              <img src={User} alt="user" className="w-4 h-4" />
              <div className={`pl-2 font-light text-sm `}>
                {intervenants?.FIC_TITRE}
              </div>
            </div>
          </button>
        </div>
      </div>
      {ficRefIntervenant === intervenants.FIC_REF &&
        intervenants?.TypeSousFiche?.map((category: any, index: number) => (
          <div key={index} className="relative">
            <div className="border-l-2 absolute top-0 bottom-0 left-[40px] h-full border-dashed" />
            <CategoriesTree
              category={category}
              isOpen={openCategorytId === category?.PENT_ID}
              onToggle={() => handleToggleParticipant(category?.PENT_ID)}
            />
          </div>
        ))}
    </div>
  );
};

export default InterlocuteursTree;
