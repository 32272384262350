import { create } from 'zustand';

type Intervenant = {
    choiceFicheType: any;
    setChoiceFicheType: (choiceFicheType: any) => void;

    choiceCreate: string;
    setChoiceCreate: (choiceCreate: string) => void;

    typesFiches: any[];
    setTypesFiches: (typesFiches: any[]) => void;

    fields: any[];
    setFields: (fields: any[]) => void;

    cp: string;
    setCp: (cp: string) => void;

    city: string;
    setCity: (city: string) => void;

    localCp: string;
    setLocalCp: (localCp: string) => void;

    localCity: string;
    setLocalCity: (localCity: string) => void;

    data: any;
    setData: (data: any) => void;

    creation: any;
    setCreation: (creation: any) => void;

    implicitGroups: any[];
    setImplicitGroups: (implicitGroups: any[]) => void;

    selectedGroup: any;
    setSelectedGroup: (selectedGroup: any) => void;

    date: any;
    setDate: (date: any) => void;

    DCLE: any;
    setDCLE: (DCLE: any) => void;

    moralOrPhysique: string;
    setMoralOrPhysique: (moralOrPhysique: string) => void;

    resetIntervenant: () => void;
};

export const useIntervenantStore = create<Intervenant>((set) => ({
    choiceFicheType: null,
    setChoiceFicheType: (choiceFicheType) => set({ choiceFicheType}),

    choiceCreate: '',
    setChoiceCreate: (choiceCreate) => set({ choiceCreate }),

    typesFiches: [],
    setTypesFiches: (typesFiches) => set({ typesFiches }),

    fields: [],
    setFields: (fields) => set({ fields }),

    cp: '',
    setCp: (cp) => set({ cp }),

    city: '',
    setCity: (city) => set({ city }),

    localCp: '',
    setLocalCp: (localCp) => set({ localCp }),

    localCity: '',
    setLocalCity: (localCity) => set({ localCity }),

    data: {},
    setData: (data) => set({ data }),

    creation : new Date(),
    setCreation: (creation) => set({ creation }),

    implicitGroups: [],
    setImplicitGroups: (implicitGroups) => set({ implicitGroups }),

    selectedGroup: "",
    setSelectedGroup: (selectedGroup) => set({ selectedGroup }),

    date: new Date(),
    setDate: (date) => set({ date }),

    DCLE: 0,
    setDCLE: (DCLE) => set({ DCLE }),

    moralOrPhysique: 'moral',
    setMoralOrPhysique: (moralOrPhysique) => set({ moralOrPhysique }),

    resetIntervenant: () => set({ 
        typesFiches: [],
        fields: [],
        localCp: '',
        localCity: '',
        selectedGroup: '',
        data: {},
        choiceCreate: ''
    }),
}));