import axios, { AxiosError } from "axios";
import { axiosWithToken, axiosWithoutToken } from "./axiosService";
import { ErrorResponse } from "react-router-dom";

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / patch / delete user
 */
export const login = async (username: string, password: string, alias: any) => {
    try {
      const response = await axiosWithoutToken().post(
        `api/user/login`,
        {
          username,
          password,
          alias,
        }
      );
      return response;
    } catch (error) {
      const axiosError = error as AxiosError;
      const errorResponse = axiosError?.response?.data as ErrorResponse;
     if (typeof errorResponse === "string" && errorResponse === "Invalid username or password.") {
        throw new Error("Invalid username or password."); 
      } else {
        throw new Error;
      }
    }
  };

export const logout = async () => {
  try {
    const response = await axiosWithToken().post('api/user/logout');
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAllUsers = async () => {
  try {
    const response = await axiosWithToken().get('api/user/');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.statusText || "Une erreur est survenue");
    }
    throw error;
  }
};

export const getAllUsersWithInactive = async () => {
  try {
    const response = await axiosWithToken().get('api/user/all');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.statusText || "Une erreur est survenue");
    }
    throw error;
  }
};

export const whoAmI = async () => {
  try {
    const response = await axiosWithToken().get('api/user/whoami');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.statusText || "Une erreur est survenue");
    }
    throw error;
  }
}

export const getRightsTime = async () => {
  try {
    const response = await axiosWithToken().get('api/user/rightstime');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.statusText || "Une erreur est survenue");
    }
    throw error;
  }
}

export const getAlias = async () => {
  try {
    const response = await axiosWithoutToken().get('api/user/aliaslist');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.statusText || "Une erreur est survenue");
    }
    throw error;
  }
}

export const getRightsInvoices = async () => {
  try {
    const response = await axiosWithToken().get('api/user/rightsinvoices');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.statusText || "Une erreur est survenue");
    }
    throw error;
  }
}

export const getRightsState = async () => {
  try {
    const response = await axiosWithToken().get('api/user/rightsstates');
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.response?.statusText || "Une erreur est survenue");
    }
    throw error;
  }
}