import { useViewStore } from "../../../store/TimeManagement/viewStore";
import { useTranslation } from "react-i18next";
import { postTimeValidate } from "../../../utils/TimeManager/time";
import { useListStore } from "../../../store/TimeManagement/listStore";
import { errorSelectTime, successValidateTime } from "../../../utils/toaster";
import { useLoadingStore } from "../../../store/utils/loading";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";

/**
 * @component
 * @name ValidateTime
 * @author Succi Iris
 * @date 2023
 * @description Un composant qui restitue un bouton pour valider les temps.
 */
const ValidateTime = ({ setRenderData }: any) => {
  //Translation
  const { t } = useTranslation();

  //Store
  const { setView } = useViewStore();
  const { setIsLoading } = useLoadingStore();
  const { selectedTimeForValidated, setSelectedTimeForValidated, blockSelectedTimeForValidated, createManualFee, setCreateManualFee} =
    useListStore();
  const { setDisplayDiv } = useCreateInvoiceStore();

  const handleValidate = () => {
    setIsLoading(true);
    postTimeValidate(selectedTimeForValidated)
      .then(() => {
        setIsLoading(false);
        setSelectedTimeForValidated([]);
        setRenderData((prev: any) => !prev);
        successValidateTime();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex justify-center items-center mb-2">
      <button
        className="w-20 lg:h-9 h-6 border-[1px] border-bc-green rounded flex justify-center items-center mr-2 px-2 shadow-md"
        onClick={() => {
          setView("list");
          if (selectedTimeForValidated.length === 0) return errorSelectTime();
          else handleValidate();
        }}
        >
        {t("Valider")}
      </button>
      <button
        className={`w-40 lg:h-9 h-6 border-[1px] ${blockSelectedTimeForValidated ? "bg-gray-400 " : "border-bc-green"} rounded flex justify-center items-center px-2 shadow-md`}
        disabled={blockSelectedTimeForValidated}
        onClick={() => {
          setView("list");
          if (selectedTimeForValidated.length === 0) {
             return errorSelectTime()
            } else {
            setCreateManualFee(!createManualFee)
            setDisplayDiv(true)
          }
        }}
        >
          Validation manuelle
        </button>
    </div>
  );
};

export default ValidateTime;
