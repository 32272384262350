import React, { useEffect } from "react";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { getParticipantsByDos } from "../../../../utils/customerFile";
import InterlocuteursTree from "./InterlocuteursTree";
import users from "../../../../assets/icons/users.svg";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";

/**
 * @component
 * @name ParticipantsTree
 * @description Un composant qui affiche les participants pour un type de fiche.
 * @author Iris Succi
 * @date 2023
 */
const ParticipantsTree: React.FC<any> = ({ category, isOpen, onToggle }) => {
  // Store
  const {
    participants,
    setParticipants,
    dosRef,
    setPENT_ID,
    setFicRefIntervenant,
    selectedParticipant,
    setSelectedParticipant,
    updateExplorer
  } = useExplorer();
  const { setChoiceFicheType, setData, setCp, setCity } = useIntervenantStore();

  const handleOpen = () => {
    setParticipants([]);
    setFicRefIntervenant(0);
    if (!isOpen) {
      setData({});
      setCp("");
      setCity("");
      getParticipantsByDos(dosRef, category.PENT_ID)
        .then((res) => setParticipants(res.data))
        .catch((err) => console.log(err));
    }
    onToggle();
  };

  useEffect(() => {
    setParticipants([]);
    getParticipantsByDos(dosRef, category.PENT_ID)
      .then((res) => setParticipants(res.data))
      .catch((err) => console.log(err));
  }, [updateExplorer]);

  
  return (
    <div className="w-full relative ml-4">
      <div className="flex justify-start items-center cursor-pointer">
        <div className="flex flex-col">
          <div className="flex flex-col justify-start items-start">
            <div
              key={category.PENT_ID}
              className="flex justify-start items-start cursor-pointer px-2"
            >
              <img src={users} alt="users" className="w-5 h-5" />
              <div className="pl-4">
                <button
                  className=""
                  onClick={() => {
                    setPENT_ID(category.PENT_ID);
                    setChoiceFicheType(category.PENT_NOM);
                    handleOpen();
                  }}
                >
                  {category.PENT_NOM}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen &&
        participants &&
        participants.length > 0 &&
        participants?.map((intervenants: any, index: number) => (
          <div key={index} className="relative">
            <div className="border-l-2 absolute top-0 bottom-0 left-[15px] h-full border-dashed" />
            <InterlocuteursTree
              intervenants={intervenants}
              onSelected={(ficRef: string) => setSelectedParticipant(ficRef)} // Transmet `ficRef`
              selectedParticipant={selectedParticipant} // Passe la valeur actuelle pour comparaison
            />
          </div>
        ))}
    </div>
  );
};

export default ParticipantsTree;
