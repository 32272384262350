import { useExplorer } from "../../../../store/Crm/Explorer";
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";

/**
 * @component
 * @name DossiersTree
 * @description composant qui affiche un dossier
 * @author Iris Succi
 * @date 2023
 */
const DossiersTree = ({ dossier, isSelected, onSelect }: any) => {
  const { setFicRef, setDosRef, setClientFileName, setPENT_ID, setNumberDoss } =
    useExplorer();
  const { setChoiceTab } = useUtilsCrmStore();

  const handleSelectDossier = () => {
    setFicRef(dossier?.FIC_REF_CLIENT);
    setChoiceTab("Fiche client");
    setDosRef(dossier?.DOS_REF);
    setClientFileName(dossier?.FIC_TITRE_CLIENT);
    setPENT_ID(dossier?.PENT_ID);
    setNumberDoss(dossier?.DOS_CODE);
    // Appelle la fonction passée depuis le parent pour sélectionner ce dossier
    onSelect();
  };

  return (
    <div className="w-[97%] m-auto flex flex-col space-y-4">
      <div className="relative">
        <div className="border-t-2 absolute top-[14px] left-[-15px] w-3" />
        <div className="flex items-center py-1">
          <button className="flex items-center" onClick={handleSelectDossier}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="#f8c63d"
              stroke="#f8c63d"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-folder"
            >
              <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
            </svg>

            {/* Appliquer une classe conditionnelle si le dossier est sélectionné */}
            <div
              className={`pl-2 font-light text-xs ${
                isSelected ? "bg-bc-lightOrange border-2 rounded-md border-bc-lightOrange pr-2" : "bg-none"
              }`}
            >
              {dossier.DOS_CODE} - {dossier.FIC_TITRE_CLIENT}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DossiersTree;
